import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory, useLocation} from "react-router";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import useCampagneModification from "../../../Hooks/UseCampagneModification";
import {ICampagneModification} from "../../../Models/CampagneModification.model";
import DateHelper from "../../../Helpers/Date.helper";
import CampagneModificationsOfficesTableListComponent
    from "../../../Components/Annuaire/Campagnes-modifications/CampagneModificationsOfficesTableList.component";
import {IApiPaginationLink} from "../../../Services/Api.service";
import {ICampagneModificationOffice} from "../../../Models/CampagneModificationOffice.model";
import {
    CampagnesModificationsOfficesService, ICampagnesModificationsOfficesPaginationGetApiResponse,
    ICampagnesModificationsOfficesPaginationGetFormData
} from "../../../Services/CampagnesModificationsOffices.service";
import PaginationComponent from "../../../Components/Pagination/Pagination.component";
import useListFilters, {IFilterData} from "../../../Hooks/FiltersHandler";
import LoadingComponent from "../../../Components/Loading/Loading.component";
import AccordionSimpleComponent from "../../../Components/Accordions/AccordionSimple.component";
import AsideCampagnesModificationsOfficesComponent
    from "../../../Components/Aside/AsideCampagnesModificationsOffices.component";
import FileHelper from "../../../Helpers/File.helper";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import SelectMultipleFieldComponent from "../../../Components/Fields/SelectMultiple.field.component";
import {CampagneModificationOfficesConstants} from "../../../Constants/CampagneModificationOfficesConstants";
import AdvancedSearchComponent from "../../../Components/Search/AdvancedSearch.component";
import {CampagneModificationCoordonneesService} from "../../../Services/CampagneModificationCoordonneesService";

type CampagneModificationFicheScreenProps = {
    cmid: string
}


/**
 * Affichage des informations de la campagne de modification et
 * un onglet avec la liste des campagnes de modifications des offices liées à la campagne de modification
 * @param props
 * @constructor
 */
export default function CampagneModificationFicheScreen(props: CampagneModificationFicheScreenProps) {
    const [campagneModification, setCampagneModification] = useState<ICampagneModification>(null);
    const [loadedCampagneModification, setLoadedCampagneModification] = useState<boolean>(false);
    const [campagnesModificationsOffices, setCampagnesModificationsOffices] = useState<ICampagneModificationOffice[]>([]);
    const [loadedCampagnesModificationsOfficesList, setLoadedCampagnesModificationsOfficesList] = useState<boolean>(false);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [activeTab, setActiveTab] = useState<string>('fiche');
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters(`/annuaire/campagnes-modifications/${props.cmid}`);

    const location = useLocation();
    const history = useHistory();
    const [getCampagneModificationById] = useCampagneModification();

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la liste
     */
    useEffect(() => {
        const params: any = location.search;
        // Mise à jour des paramètres par défaut
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);

        //À partir des paramètres d'URLs, vérifier s'il y a un paramètre activeTab
        if (defaultFilters.activeTab) {
            setActiveTab(defaultFilters.activeTab as string);
        }

        updateFilters(defaultFilters, false, true);
    }, []);

    useEffect(() => {
        if (!props.cmid) {
            history.push(AppConstants.pathOffices);
        }

        getCampagneModification();
    }, [props.cmid]);

    useEffect(() => {
        let breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Campagnes modifications", link: AppConstants.pathOffices},
        ];
        if (campagneModification != null) {
            breadcrumbLinks = [
                {text: "Annuaire", link: AppConstants.pathAnnuaire},
                {text: "Campagnes modifications", link: AppConstants.pathOffices},
                {text: campagneModification.nom, link: null},
            ];
        }

        setBreadcrumbLinks(breadcrumbLinks);
    }, [campagneModification]);

    useEffect(() => {
        if (!filters) return;//Permet de ne pas retirer les filtres au chargement de la page

        if (activeTab !== 'modifications-offices') return;

        getCampagnesModificationsOfficesList();
    }, [filters, activeTab]);

    /**
     * Récupération de la campagne de modification selon le cmid
     */
    const getCampagneModification = (): void => {
        if (loadedCampagneModification) return;

        setLoadedCampagneModification(false);

        getCampagneModificationById(props.cmid, (campagneModification: ICampagneModification) => {
            setCampagneModification(campagneModification);

            setLoadedCampagneModification(true);
        });
    };

    const getCampagnesModificationsOfficesList = (): void => {
        if (loadedCampagnesModificationsOfficesList) return;

        setLoadedCampagnesModificationsOfficesList(false);

        setPaginationLinks([]);

        //Récupération de la liste des campagnes de modifications des offices de la campagne de modification
        const campagnesModificationsOfficesService: CampagnesModificationsOfficesService = new CampagnesModificationsOfficesService();

        campagnesModificationsOfficesService
            .getCampagnesModificationsOfficesWithPagination({
                ...filters,
                campagneModificationId: parseInt(props.cmid),
            } as ICampagnesModificationsOfficesPaginationGetFormData)
            .then((response: ICampagnesModificationsOfficesPaginationGetApiResponse) => {
                if (response && response.datas.pagination) {
                    const currentCampagnesModificationsOffices = response.datas.pagination.data;
                    setCampagnesModificationsOffices(currentCampagnesModificationsOffices);

                    //Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    setLoadedCampagnesModificationsOfficesList(true);
                }
            });
    }

    const onExportcampagnesModificationsOffices = () => {
        //Export la liste complète des campagnes de modifications des offices de la campagne de modification
        //(office, emailPrincipal et token)
        const campagnesModificationsOfficesService: CampagnesModificationsOfficesService = new CampagnesModificationsOfficesService();

        const formDatas = {
            etat: filters.etat,
        } as ICampagnesModificationsOfficesPaginationGetFormData;

        campagnesModificationsOfficesService.exportCampagnesModificationsOfficesByCampagneModification(props.cmid, formDatas).then((response) => {
            if (response && response.datas.url) {
                FileHelper.handleFileDownload(response.datas.url);
            } else {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'export",
                    type: "danger"
                });
            }
        });
    };

    const onExportcampagnesModificationsCoordonnees = () => {
        //Export la liste complète des campagnes de modifications des coordonées de la campagne de modification
        //(offices, notaires, ancienne valeur, nouvelle valeur, ...)
        const campagneModificationCoordonneesService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();

        campagneModificationCoordonneesService.exportCampagnesModificationsCoordonneesByCampagneModification(props.cmid).then((response) => {
            if (response && response.datas.url) {
                FileHelper.handleFileDownload(response.datas.url);
            } else {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'export",
                    type: "danger"
                });
            }
        });
    };

    return (
        <LayoutComponent showMenu={true}>
            <PageHeaderComponent
                breadcrumbLinks={breadcrumbLinks}
                text={`Campagne de modification : ${loadedCampagneModification && campagneModification != null ? campagneModification.nom : ''}`}
                icon="icon-coordonnees-adresses"
                //todo problème : le champs de recherche perd le focus (rechargement de la page)
                currentKeyword={filters && filters.keyword ? filters.keyword as string : ""}
                onSearch={
                    //Afficher la recherche sur l'onglet modifications-offices
                    activeTab === 'modifications-offices' ?
                        (keyword => updateFilters({
                            ...defaultFilters,
                            keyword,
                            activeTab: activeTab
                        }, false)) : null}/>
            {
                (!loadedCampagneModification && !loadedCampagnesModificationsOfficesList) &&
                <LoadingComponent/>
            }

            {
                (loadedCampagneModification || loadedCampagnesModificationsOfficesList) &&
                <TabsContainerComponent
                    defaultTab={activeTab}
                    onClickTabCallback={(tab) => {
                        updateFilters({activeTab: tab}, true);
                        //setActiveTab(tab);
                    }}>
                    <TabPanelComponent label="Fiche" icon="icon-fiche" slug="fiche">
                        {activeTab === 'fiche' &&
                            <div className="fiche-content -right-gutter border-footer">
                                <AccordionSimpleComponent
                                    title="Informations"
                                    icon={"icon-informations"}
                                    contentShowedOnInit={true}>
                                    <div className="informations-wrapper">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">Nom</p>
                                                    <p className="value">{campagneModification.nom}</p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Date de début</p>
                                                    <p className="value">{DateHelper.getFormatedDate(campagneModification.dateDebut)}</p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Date de fin</p>
                                                    <p className="value">{campagneModification.dateFin != null ? DateHelper.getFormatedDate(campagneModification.dateFin) : "-"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionSimpleComponent>
                            </div>
                        }
                    </TabPanelComponent>


                    <TabPanelComponent
                        label="Modifications Offices"
                        slug="modifications-offices"
                        icon="icon-office">
                        {(activeTab === 'modifications-offices') &&
                            <div className="fiche-content border-footer">
                                <div className="list-content -right-gutter">
                                    <div className="list-filters">
                                        <i className={`icon icon-filtre`}/>
                                        <SelectMultipleFieldComponent
                                            fieldName="etat"
                                            label="Etats"
                                            options={CampagneModificationOfficesConstants.etatsOptions}
                                            hideCurrentValuesOnTop={true}
                                            errors={null}
                                            placeholder="Tous les états"
                                            hideSearch={true}
                                            modificators={`-inline -on-white`}
                                            oldValues={filters.etat ? filters.etat as string[] : []}
                                            onChange={(values: string[]) => updateFilters({etat: values})}
                                        />
                                    </div>

                                    {/* Afficher le récapitulatif de recherche (etat) */}
                                    <AdvancedSearchComponent
                                        title=""
                                        onClose={() => {}}
                                        onSearch={(formData) => updateFilters(formData)}
                                        onRemoveFilter={(filter: IFilterData) => updateFilters(filter)}
                                        showAdvancedSearch={false}
                                        filters={filters}
                                        columns={[
                                            {
                                                elements: [
                                                    {
                                                        type: "selectMultiple",
                                                        fieldName: "etat",
                                                        label: "Etat",
                                                        modificators: "-on-white",
                                                        hideSearchOnMultiple: true,
                                                        options: CampagneModificationOfficesConstants.etatsOptions,
                                                        oldValues: filters.etat as string[] || []
                                                    },
                                                ]
                                            },
                                        ]}
                                        showSummary={true}
                                    />

                                    <CampagneModificationsOfficesTableListComponent
                                        filters={filters}
                                        setOrder={setOrder}
                                        campagnesModificationsOffices={campagnesModificationsOffices}/>

                                    <PaginationComponent links={paginationLinks}
                                                         onPageClick={page => updateFilters({page})}/>

                                    <AsideCampagnesModificationsOfficesComponent
                                        type="list"
                                        exportOfficesCallback={() => {
                                            onExportcampagnesModificationsOffices()
                                        }}
                                        exportCoordonneesCallback={() => {
                                            onExportcampagnesModificationsCoordonnees()
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </TabPanelComponent>
                </TabsContainerComponent>
            }

        </LayoutComponent>
    )
}
