import ApiService, {IApiCustomResponse, IApiPagination, IFileDownloadApiResponse} from "./Api.service";
import {IFilInformations, IFilInformationsAbonne} from "../Models/FilInformations.model";
import {IFilInformationsStats} from "../Components/FilInformations/FilInformationsStats.component";


export type IFilInformationsGetPaginationApi = IApiPagination & {
    data: IFilInformations[],
}
export type IFilInformationsAbonnesGetPaginationApi = IApiPagination & {
    data: IFilInformationsAbonne[],
}
export type IFilInformationsPrevisualisation = IApiCustomResponse & {
    datas: {
        html: string
    }
}
export type IFilInformationStatsApiResponse = IApiCustomResponse & {
    datas: {
        stats: IFilInformationsStats
    }
}
export type IFilInformationsGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IFilInformationsGetPaginationApi
    }
}
export type IFilInformationsGetAllApiResponse = IApiCustomResponse & {
    datas: {
        fils: IFilInformations[]
    }
}
export type IFilInformationsAbonnesGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: IFilInformationsAbonnesGetPaginationApi,
        abonnesWithErrors?: IFilInformationsAbonne[],
    }
}

export type IFilInformationsGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    isArchive: number,
}

export type IFilInformationsInfosFormData = {};
export type IFilInformationsUneFormData = {};
export type IFilInformationsBandeauAccueilFormData = {};
export type IFilInformationsActusFormData = {};
export type IFilInformationsCvsFormData = {};
export type IFilInformationsRealFormData = {};
export type IFilInformationsImagesFormData = {};
export type IFilInformationsPresseFormData = {};
export type IFilInformationsFormationsFormData = {};
export type IFilInformationsPrestationsFormData = {};
export type IFilInformationsAgendaFormData = {};
export type IFilInformationsDocumentsFormData = {};
export type IFilInformationsArretDeliberationFormData = {};
export type IFilInformationsVieCompagnieFormData = {};

export type IFilInformationsAbonneFormData = {
    nom: string,
    email: string,
    etat?: string,
    onlyCheck?: boolean
};
export type IFilInformationsSendMailFormData = {
    sendType: "all"|"test"
};

export type IFilInformationsDetailsGetApiResponse = IApiCustomResponse & {
    datas?: {
        filInformations: IFilInformations
    }
}

export type IRevuePresse = {[key:string]:any};
export type IFilInformationsRevuesPresseGetApiResponse = IApiCustomResponse & {
    datas: IRevuePresse[]
}

export class FilInformationsService extends ApiService{

    public delete(id:number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/fil-infos/' + id + '/delete', 'POST');
    }

    public archive(id:number): Promise<IApiCustomResponse>{
        return this.doApiCall('api/fil-infos/' + id + '/archive', 'POST');
    }

    public getFilInformations(formData: IFilInformationsGetFormData): Promise<IFilInformationsGetApiResponse>{
        return this.doApiCall('api/fil-infos', 'GET', formData);
    }

    public getFilInformationsAll(): Promise<IFilInformationsGetAllApiResponse>{
        return this.doApiCall('api/fil-infos/all', 'GET');
    }
    public getFilInformationsAllExternal(): Promise<IFilInformationsGetAllApiResponse>{
        return this.doApiCall('api/external/fil-infos/all', 'GET');
    }

    public getFilInformationsAbonnes(formData: IFilInformationsGetFormData): Promise<IFilInformationsAbonnesGetApiResponse>{
        return this.doApiCall('api/fil-infos/abonnes', 'GET', formData);
    }

    public get(fid: string): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall('api/fil-infos/'+fid, 'GET');
    }

    public infos(formData: IFilInformationsInfosFormData, fid:number = null): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos${fid ? '/'+fid+'/infos' : '/creation'}`, 'POST',formData);
    }

    public une(formData: IFilInformationsUneFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/une`, 'POST',formData);
    }

    public actus(formData: IFilInformationsActusFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/actualites`, 'POST',formData);
    }

    public cvs(formData: IFilInformationsCvsFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/cvs`, 'POST',formData);
    }

    public real(formData: IFilInformationsRealFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/real`, 'POST',formData);
    }

    public images(formData: IFilInformationsImagesFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/images`, 'POST',formData);
    }

    public presses(formData: IFilInformationsPresseFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/presses`, 'POST',formData);
    }

    public vies(formData: IFilInformationsVieCompagnieFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/vie-compagnie`, 'POST',formData);
    }

    public formations(formData: IFilInformationsFormationsFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/formations`, 'POST',formData);
    }

    public prestations(formData: IFilInformationsPrestationsFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/prestations`, 'POST',formData);
    }
    public arretes(formData: IFilInformationsArretDeliberationFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/arretes`, 'POST',formData);
    }

    public evenements(formData: IFilInformationsAgendaFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/evenements`, 'POST',formData);
    }

    public documents(formData: IFilInformationsDocumentsFormData, fid:number): Promise<IFilInformationsDetailsGetApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/documents`, 'POST',formData);
    }

    public getPrevisualisation(fid:string): Promise<IFilInformationsPrevisualisation>{
        return this.doApiCall(`api/fil-infos/${fid}/previsualisation`, 'GET');
    }

    public getStats(): Promise<IFilInformationStatsApiResponse>{
        return this.doApiCall(`api/fil-infos/stats`, 'GET');
    }

    public deleteAbonne(aid:string): Promise<IFilInformationsPrevisualisation>{
        return this.doApiCall(`api/fil-infos/abonnes/${aid}/delete`, 'GET');
    }

    public abonne(formData: IFilInformationsAbonneFormData): Promise<IApiCustomResponse>{
        return this.doApiCall(`api/fil-infos/abonnes/creation`, 'POST',formData);
    }

    public abonneExternal(formData: IFilInformationsAbonneFormData): Promise<IApiCustomResponse>{
        return this.doApiCall(`api/external/fil-infos/abonnes/creation`, 'POST',formData);
    }

    public abonneEtat(aid: string,etat:string): Promise<IApiCustomResponse>{
        return this.doApiCall(`api/fil-infos/abonnes/${aid}/update`, 'POST',{etat});
    }

    public download(fid: string): Promise<IFileDownloadApiResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/download`, 'GET');
    }

    public sendMail(fid: string,formData: IFilInformationsSendMailFormData): Promise<IApiCustomResponse>{
        return this.doApiCall(`api/fil-infos/${fid}/sendMail`, 'POST',formData);
    }

    public getRevuesPresse(): Promise<IFilInformationsRevuesPresseGetApiResponse>{
        return this.doApiCall(`api/fil-infos/revues-presse`, 'GET');
    }

}
