import ApiService, {IApiCustomResponse, IApiPagination} from "./Api.service"
import {ICampagneModificationOffice} from "../Models/CampagneModificationOffice.model";
import {ICampagneModificationCoordonnee} from "../Models/CampagneModificationCoordonnee.model";
import {IOffice} from "../Models/Office.model";
import {ICampagneModification} from "../Models/CampagneModification.model";
import {IOfficeGetApiResponse} from "./Offices.service";

export type ICampagnesModificationsGetApiResponse = IApiCustomResponse & {
    datas?: {
        office: IOffice,
        campagneData: ICampagneModificationOffice[],
        campagne: ICampagneModificationCoordonnee[]
    }
}

export type ICampagnesModificationsPaginationGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    departement?: string[],
}

export type ICampagnesModificationsGetPaginationApi = IApiPagination & {
    data: ICampagneModification[],
}
export type ICampagnesModificationsPaginationGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICampagnesModificationsGetPaginationApi
    }
}

export type ICampagneModificationGetApiResponse = IApiCustomResponse & {
    datas?: {
        campagneModification?: ICampagneModification
    }
}

export class CampagnesModificationsService extends ApiService {
    private baseRoute = 'api/annuaire/campagnes-modifications';
    private baseRouteExternal = 'api/external/campagnes-modifications';

    /**
     * Permet la récupération d'une liste des campagnes de modifications avec pagination
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getCampagnesModificationsWithPagination(formData: ICampagnesModificationsPaginationGetFormData): Promise<ICampagnesModificationsPaginationGetApiResponse> {
        //Url avec utilisateur connecté
        return this.doApiCall(`${this.baseRoute}`, 'GET', formData);
    }

    /**
     * Permet la récupération d'une campagne de modification à partir de son id
     *
     * @param {string} cmid
     * @returns {Promise<ICampagneModificationGetApiResponse>}
     */
    public getCampagneModification(cmid: string): Promise<ICampagneModificationGetApiResponse> {
        return this.doApiCall(`${this.baseRoute}/${cmid}`, 'GET');
    }

}