import ApiService from "./Api.service";
import { ICampagnesModificationsGetApiResponse } from "./CampagnesModifications.service";
import { IApiCustomResponse } from "./Api.service";
import { ICampagneModificationAbonne } from "src/Models/CampagneModificationAbonne.model";


export type ICampagneModificationAbonnesGetApiResponse = IApiCustomResponse & {
    datas?: {
        campagneModificationAbonnes: ICampagneModificationAbonne[]
    }
}

export type ICampagneModificationAbonneAddFormData = {
    campagneModificationOfficeId: number,
    campagneModificationCoordonneeId?: number,

    email: string,
    etat: string,
}

export type ICampagneModificationAbonneUpdateFormData = {
    email: string,
    etat: string,
}

export class CampagneModificationAbonnesService extends ApiService {
    private baseRoute = 'api/annuaire/campagnes-modifications-abonnes';
    private baseRouteExternal = 'api/external/campagnes-modifications-abonnes';

    public get(id: number)
    {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/get/${id}`, 'GET');
    }

    /**
     * Permet d'ajouter une campagne de modification de l'abonné
     * @param data
     */
    public add(data: ICampagneModificationAbonneAddFormData): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/add`, 'POST', data);
    }

    /**
     * Permet la mise à jour d'une campagne de modification de l'abonné
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public update(id: number, data: ICampagneModificationAbonneUpdateFormData): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/update/${id}`, 'POST', data);
    }

    /**
     * Permet de valider l'abonné (statut: validee-par-chambre)
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public validation(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/validation/${id}`, 'POST');
    }
}