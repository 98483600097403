import ApiService from "./Api.service";
import { ICampagnesModificationsGetApiResponse } from "./CampagnesModifications.service";

export type ICampagneModificationNegociateurFormData = {
    ancien_email?: string,
    nouvel_email: string,
}

export class CampagnesModificationsNegociateursService extends ApiService {
    private baseRoute = 'api/annuaire/campagnes-modifications-negociateurs';
    private baseRouteExternal = 'api/external/campagnes-modifications-negociateurs';

    /**
     * Permet la mise a jour d'une campagne de modification negociateur
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public update(id: number, data: ICampagneModificationNegociateurFormData): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/update/${id}`, 'POST', data);
    }

    /**
     * Permet de passer une campagne de modification de negociateur en statut/etat "a-supprimer-par-chambre"
     */
    public setStateToDelete(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/set-state-to-delete/${id}`, 'POST');
    }

    /**
     * Permet de confirmer le changement de données pour le négociateur
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public verification(token: string): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/confirmation/${token}`, 'POST');
    }

    /**
     * Permet de valider le changement de données pour le négociateur (statut: validee-par-chambre)
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public validation(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/validation/${id}`, 'POST');
    }
}