import {IOption, IOptionsGroup} from "src/Components/Fields/Select.field.component";
import {AppConstants} from "../Constants/AppConstants";
import {NegociateursConstants} from "../Constants/NegociateursConstants";

export default class CommonHelper {
    /**
     * Permet de récupérer le sexe formaté
     *
     * @param {string} sexe
     * @returns {string}
     */
    static getFormatedSexe(sexe: string): string {
        return this.getFormatedElementFromOptions(sexe, AppConstants.sexeOptions);
    }


    /**
     * Permet de récupérer le sexe formaté
     *
     * @param {string} value
     * @returns {string}
     */
    static getFormatedOuiNon(value: string): string {
        return this.getFormatedElementFromOptions(value, AppConstants.ouiNonOptions);
    }


    /**
     * Permet la génération des choix "oui"/"non" pour des boutons radios
     *
     * @param {string} defaultValue
     * @returns {IOption[]}
     */
    static generateOuiNonRadiosOptions(defaultValue: string): IOption[] {
        const optionsList: IOption[] = AppConstants.ouiNonOptions;

        optionsList.map((option: IOption) => {
            option.isChecked = defaultValue === option.value;
        });

        return optionsList;
    }


    /**
     * Permet de récupérer une valeur formatée dans une liste d'options
     *
     * @param {string} value
     * @param {IOption[]} list
     * @returns {string}
     */
    public static getFormatedElementFromOptions(value: string, list: IOption[]): string {
        let formated: string = value;

        const founded = list.find((opt) => opt.value === value);
        if (founded) formated = founded.label;

        return formated;
    }


    /**
     * Permet de récuéprer des valeurs formatée dans une liste d'options
     *
     * @param {string[]} values
     * @param {IOption[]} list
     * @returns {string}
     */
    public static getFormatedElementsFromOptions(values: string[], list: IOption[]): string {
        let formatedValues: string[] = [];

        for (let v of values) {
            const foundedOption: IOption = list.find((opt) => opt.value === v);
            if (foundedOption) formatedValues.push(foundedOption.label);
        }

        const formatedStringValue: string = formatedValues.join(', ');

        return formatedStringValue;
    }


    /**
     * Permet de récuéprer des valeurs formatée dans une liste de groupe d'options
     *
     * @param {string[]} values
     * @param {IOption[]} list
     * @returns {string}
     */
    public static getFormatedElementsFromOptionsGroup(values: string[], list: IOptionsGroup[]): string {
        let formatedValues: string[] = [];

        for (let v of values) {
            for (let group of list) {
                const foundedOption: IOption = group.options.find((opt) => opt.value === v);
                if (foundedOption) formatedValues.push(foundedOption.label);
            }

        }

        const formatedStringValue: string = formatedValues.join(', ');

        return formatedStringValue;
    }

    /**
     * Permet de récupérer une valeur d'un object ) partir de son chemin
     * @param {{[p: string]: any}} obj
     * @param {string} path
     * @returns {any}
     */
    static getObjectValueFromPath(obj: { [key: string]: any }, path: string): any {
        const paths = path.split('.');
        let current = obj;

        for (let i = 0; i < paths.length; ++i) {
            const currentPath = paths[i];

            if (current[currentPath] == undefined) {
                return undefined;
            } else {
                current = current[currentPath];
            }
        }
        return current;
    }

    /**
     * Permet de récupérer la nationalité formatée
     *
     * @param {string} type
     * @returns {string}
     */
    public static getFormatedTypeNationalite(type: string): string {
        return CommonHelper.getFormatedElementFromOptions(type, AppConstants.nationalitesOptions);
    }

    /**
     * Récupère une liste d'année pour les options d'un select
     */
    static getYearsOptions(minYear: number = 2000, maxYear: number = null): IOption[] {
        const options: IOption[] = [];

        if (maxYear == null) {
            maxYear = new Date().getFullYear();
        }

        for (let i = maxYear; i >= minYear; i--) {
            const option: IOption = {
                value: i.toString(),
                label: i.toString(),
            };

            options.push(option);
        }

        return options;
    }

    static validateEmail(email: string): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
}
