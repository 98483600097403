import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import OfficesHelper from "src/Helpers/Offices.helper";
import React from "react";
import {useHistory} from "react-router";
import {IFilterData} from "src/Hooks/FiltersHandler";
import {ICampagneModificationOffice} from "../../../Models/CampagneModificationOffice.model";
import {ICampagneModification} from "../../../Models/CampagneModification.model";
import DateHelper from "../../../Helpers/Date.helper";

type ICampagneModificationsTableListComponentProps = {
    filters: IFilterData,
    setOrder: (value: string) => void,
    campagnesModificationsList: ICampagneModification[],
}

export default function CampagneModificationsTableListComponent(props: ICampagneModificationsTableListComponentProps) {
    const {filters, setOrder, campagnesModificationsList} = props;
    const history = useHistory();


    return (
        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                {[
                    {slug: "nom", label: "nom"},
                    {slug: "dateDebut", label: "Date de début"},
                    {slug: "dateFin", label: "Date de fin"},
                    //TODO Actif/Inactif
                ].map((item, iIndex) => <th className="th" key={iIndex}>
                    <TableHeaderButtonComponent
                        direction={filters.orderType as string || AppConstants.orderType.asc}
                        value={item.slug} selectedValue={filters.orderBy as string || ""}
                        label={item.label} click={(value: string) => setOrder(value)}/>
                </th>)}
            </tr>
            </thead>
            <tbody>
            {
                campagnesModificationsList && campagnesModificationsList.map((campagneModification: ICampagneModification, index: number) =>
                    <tr key={index}
                        onClick={() => history.push(`/annuaire/campagnes-modifications/${campagneModification.id}`)}
                        className="tr">
                        <td className="td -bold">
                            {campagneModification.nom}
                        </td>
                        <td className="td">
                            {DateHelper.getFormatedDate(campagneModification.dateDebut)}
                        </td>
                        <td className="td">
                            {campagneModification.dateFin != null ? DateHelper.getFormatedDate(campagneModification.dateFin) : '-'}
                        </td>
                    </tr>
                )
            }
            </tbody>
        </table>
    )
}
