import {IOption} from "src/Components/Fields/Select.field.component";


export type ICampagneModificationAbonnesConstants = {
    etatsOptions: IOption[],
}
export const CampagneModificationAbonnesConstants: ICampagneModificationAbonnesConstants = {
    //note apres modification modifier également server/config campagnesModificationsAbonnes
    etatsOptions: [
        {
            value: "a-inscrire",
            //label: "Inscrit",
            label: "Oui",
        },
        {
            value: "ne-pas-inscrire",
            //label: "Non inscrit",
            label: "Non",
        },
    ],
};