import FormCreatorComponent, {FormActions, FormColumn, FormElement} from "../../Forms/FormCreator.component";
import React, {useEffect, useState} from "react";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import {useHistory} from "react-router";
import {AppConstants} from "../../../Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import LoadingComponent from "../../Loading/Loading.component";
import AccordionSimpleComponent from "../../Accordions/AccordionSimple.component";
import {ICampagneModificationCoordonnee} from "../../../Models/CampagneModificationCoordonnee.model";
import {
    CampagneModificationCoordonneesService,
    ICampagneModificationCoordonneeGetApiResponse,
    ICampagneModificationCoordonneeVerificationFormData,
    ICampagnesModificationsCoordonneesGetApiResponse
} from "../../../Services/CampagneModificationCoordonneesService";

type ICampagneModificationCoordonneeAccesComponentProps = {
    ctoken: string,
    onDefineTitle: (title: string) => void,
    onSuccessAccess: (campagnesModificationsCoordonnees: ICampagneModificationCoordonnee[]) => void,
}

// Process :
// 0.a. On récupère les campagne(s) de modifications de coordonnées à partir du token (s'il n'est pas valide on affiche une erreur)
// 1. Si les campagne(s) de modifications de coordonnées sont trouvées, deux possibilités :
// 1.a. Si le code d'accès est déjà en session (et correct), on va directement au formulaire de modification de coordonnée => composant source
// 1.b. On envoie un code d'accès par mail (au clic sur un bouton) et on passe à l'étape 2
// 2. On affiche le formulaire avec la demande du code d'accès (à 6 chiffres)
// 3. Si le code est valide, on passe au formulaire de modification de coordonnée => composant source
export default function CampagneModificationCoordonneeAccesComponent(props: ICampagneModificationCoordonneeAccesComponentProps) {
    const sessionCodeSlug: string = "campagneModificationCoordonneeCode";

    const [loaded, setLoaded] = useState<boolean>(false);
    const [campagnesModificationsCoordonnees, setCampagnesModificationsCoordonnees] = useState<ICampagneModificationCoordonnee[]>([]);
    const [codeSent, setCodeSent] = useState<boolean>(null);
    const [codeChecked, setCodeChecked] = useState<boolean>(false);

    const [isSending, setIsSending] = useState<boolean>(false);
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);

    const history = useHistory();

    const campagnesModificationsCoordonneesService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();

    //Récupération des informations de(s) campagne(s) de modifications de coordonnées (au chargement de la page)
    useEffect(() => {
        if (loaded) {
            return;
        }

        if (!props.ctoken) {
            history.push(AppConstants.path403);
        } else {
            setErrorsMessage(null);

            //On récupère les informations de(s) campagnes de modifications de coordonnées
            //Ex avec plusieurs campagnes : un notaire qui a plusieurs coordonnées (principal, convocation, etc.)
            campagnesModificationsCoordonneesService.getCampagnesModificationsCoordonneesByToken(props.ctoken).then((response: ICampagnesModificationsCoordonneesGetApiResponse) => {
                if (response && response.datas) {
                    setCampagnesModificationsCoordonnees(response.datas.campagnesModificationsCoordonnees);

                    if (props.onDefineTitle) {
                        //On définit le titre de la page
                        let title: string = "Mise à jour de l'adresse de messagerie";
                        const notaire = response.datas.campagnesModificationsCoordonnees[0]?.notaire;
                        if (notaire) {
                            title += " : " + notaire.prenom + " " + notaire.nom;
                        }
                        props.onDefineTitle(title);
                    }

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            }, (error: IApiCustomResponse) => {
                setErrorsMessage(error.messages);

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: error.messages,
                    type: "danger"
                });

                if (!loaded) {
                    setLoaded(true);
                }
            });
        }
    }, [props.ctoken]);

    //Une fois que les campagne(s) de modifications de coordonnées ont été récupéré,
    // => Vérifier si le code d'accès est déjà stocké en session (si oui, on vérifie ce code directement pour éviter de le redemander)
    useEffect(() => {
        if(!loaded) {
            return;
        }
        if (sessionStorage.getItem(sessionCodeSlug) != null) {
            onCheckCode({code: sessionStorage.getItem(sessionCodeSlug)});
        }
    }, [sessionStorage, loaded]);

    //Une fois que les campagne(s) de modifications de coordonnées ont été récupéré,
    // => on charge le formulaire pour demander le code (si le code n'est pas en session et celui en session n'est plus correct)
    useEffect(() => {
        if (campagnesModificationsCoordonnees.length === 0 || codeSent == null) {
            return;
        }

        //Préparation des colonnes du formulaire
        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "code",
                label: "Code de vérification",
                maxLength: 6,
                required: true,
                modificators: "-on-white",
                oldValue: "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Accéder",
                modificators: "-primary",
                hasLoading: isSending,
                onAction: (formData) => {
                    onCheckCode(formData as ICampagneModificationCoordonneeVerificationFormData);
                }
            },
            {
                label: "Annuler",
                modificators: "-is-link",
                onAction: () => {
                    onCancelCheckCode();
                }
            }
        ];
        setFormActions(currentFormActions);
    }, [campagnesModificationsCoordonnees, codeSent]);

    //Action sur le bouton d'envoi du code d'accès
    const onSendCode = () => {
        setErrorsMessage(null);
        setErrorFieldsMessages(null);

        //On déclenche l'envoi du code d'accès
        campagnesModificationsCoordonneesService.sendCode(props.ctoken).then((response: ICampagneModificationCoordonneeGetApiResponse) => {
            if (response) {
                setCodeSent(true);
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: response.messages,
                    type: "success"
                });
            }
        }, (error: IApiCustomResponse) => {
            setErrorsMessage(error.messages);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: error.messages,
                type: "danger"
            });
            setCodeSent(false);
        });
    };

    //Vérifier le code pour accéder au formulaire de modification de coordonnée
    const onCheckCode = (formData: ICampagneModificationCoordonneeVerificationFormData): void => {
        //Valider le code d'accès (en front)
        if (formData.code == null || formData.code.length !== 6 || isNaN(parseInt(formData.code))) {
            setErrorsMessage(["Le code d'accès doit être renseigné et composé de 6 chiffres."]);
            return;
        }

        setIsSending(true);
        setErrorFieldsMessages(null);
        setErrorsMessage(null);

        campagnesModificationsCoordonneesService.checkCode(props.ctoken, formData).then((response: ICampagneModificationCoordonneeGetApiResponse) => {
            setIsSending(false);

            if (response != null) {
                setCodeChecked(true);

                //Stocker le code d'accès en session
                sessionStorage.setItem(sessionCodeSlug, formData.code);

                /*
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: 'Vous allez accéder au formulaire de modification de coordonnée.',
                    type: "success"
                });
                 */
                //En cas de succès, on envoie la campagne de modification de coordonnée au composant/ecran qui a appelé ce composant
                //props.onSuccessAccess([response.datas.campagneModificationCoordonnee]);

                //En cas de succès, on envoie le(s) campagnes de modifications de coordonnées au composant/ecran qui a appelé ce composant
                props.onSuccessAccess(campagnesModificationsCoordonnees);
            }
        }).catch((error: IApiCustomResponse) => {
            setIsSending(false);
            setErrorFieldsMessages(error.fieldsMessages);
            setErrorsMessage(error.messages);

            //Retirer le code d'accès de la session
            sessionStorage.removeItem(sessionCodeSlug);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: error.messages,
                type: "danger"
            });
        });
    };


    //Action sur le bouton d'annulation de la vérification du code
    const onCancelCheckCode = (): void => {
        if (isSending) {
            return;
        }

        setCodeSent(null);
        setErrorFieldsMessages(null);
        setErrorsMessage(null);
    };

    return (
        <div>
            {!loaded &&
                <LoadingComponent />
            }

            {/* Message si les campagne(s) de modifications de coordonnées ne sont pas trouvés */}
            {(campagnesModificationsCoordonnees.length === 0 && loaded) &&
                <p className="information-text">
                    {errorMessages ? errorMessages : "La ou les campagnes de modifications de coordonnée n'ont pas été trouvée."}
                </p>
            }

            {/* Message/Bouton pour obtenir le code d'accès */}
            {(campagnesModificationsCoordonnees.length > 0 && codeSent == null) &&
                <AccordionSimpleComponent
                    title="Récupération du code"
                    icon={"icon-informations"}
                    modificators="-no-padding-left-right -no-padding-top"
                    contentShowedOnInit={true}>
                    <div className="informations-text -fw-medium">
                        <p>
                            Pour accéder au formulaire de mise à jour de l'adresse de messagerie, vous devez renseigner un code d'accès.<br/>
                            Lorsque vous allez cliquer sur le bouton ci-dessous, un code d'accès va vous être envoyé par email<br/>
                            <button className="g-button -primary -margin-top" onClick={() => onSendCode()}>
                                Envoyer le code
                            </button>
                        </p>
                    </div>
                </AccordionSimpleComponent>
            }

            {/*Formulaire pour vérifier le code */}
            {(!codeChecked && campagnesModificationsCoordonnees.length > 0 && codeSent != null) &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={!isSending ? formActions : null}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages}/>
            }
        </div>
    );
}
