import {IOption, IOptionsGroup} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type INotairesConstants = {
    typesOptions: IOptionsGroup[],
    typesFullOptions: IOptionsGroup[],
    typesSansHonoraireOptions: IOptionsGroup[],
    typesEnFonction: string[],
    typesAnciens: string[],
    typesAnciensLight: string[],
    typesAnciensFull: string[],
    typesAnciensOptions: IOption[],
    typesAnciensFullOptions: IOption[],
    typesActifsOptions: IOption[],
    fonctionsOptions: IOptionsGroup[],
    labelsOptions: IOptionsGroup[],
    languesParleesOptions: IOption[],
    creationSteps: ICreationSteps[],
    documentsTypesOptions: IOption[],
    documentsOrderOptions: IOption[],
}
export const NotairesConstants: INotairesConstants = {
    typesOptions: [
        {
            label: "Notaires en exercice",
            options: [
                {
                    value: "notaireAssocie",
                    label: "Notaire associé"
                },
                {
                    value: "notaireIndividuel",
                    label: "Notaire individuel"
                },
                {
                    value: "notaireSalarie",
                    label: "Notaire salarié"
                },
            ]
        },
        {
            label: "Anciens notaires",
            options: [
                {
                    value: "honoraire",
                    label: "Notaire honoraire"
                },
                {
                    value: "ancienNotaire",
                    label: "Ancien notaire"
                },
            ]
        }

    ],
    typesFullOptions: [
        {
            label: "Notaires en exercice",
            options: [
                {
                    value: "notaireAssocie",
                    label: "Notaire associé"
                },
                {
                    value: "notaireIndividuel",
                    label: "Notaire individuel"
                },
                {
                    value: "notaireSalarie",
                    label: "Notaire salarié"
                },
            ]
        },
        {
            label: "Anciens notaires",
            options: [
                {
                    value: "honoraire",
                    label: "Notaire honoraire"
                },
                {
                    value: "ancienNotaire",
                    label: "Ancien notaire"
                },
                //Pour l'update/edit : Ajout de la possibilité de mettre Notaire décédé
                {
                    value: "deces",
                    label: "Décédé"
                },
            ]
        }

    ],
    typesSansHonoraireOptions: [
        {
            label: "Notaires en exercice",
            options: [
                {
                    value: "notaireAssocie",
                    label: "Notaire associé"
                },
                {
                    value: "notaireIndividuel",
                    label: "Notaire individuel"
                },
                {
                    value: "notaireSalarie",
                    label: "Notaire salarié"
                },
            ]
        },
        {
            label: "Anciens notaires",
            options: [
                {
                    value: "ancienNotaire",
                    label: "Ancien notaire"
                },
                //Pour l'update/edit : Ajout de la possibilité de mettre Notaire décédé
                {
                    value: "deces",
                    label: "Décédé"
                },
            ]
        }

    ],
    typesAnciensOptions: [
        {
            value: "honoraire",
            label: "Notaire honoraire"
        },
        {
            value: "ancienNotaire",
            label: "Ancien notaire"
        },
    ],
    typesAnciensFullOptions: [
        {
            value: "honoraire",
            label: "Notaire honoraire"
        },
        {
            value: "ancienNotaire",
            label: "Ancien notaire"
        },
        {
            value: "deces",
            label: "Décédé"
        },
    ],
    typesActifsOptions: [
        {
            value: "notaireAssocie",
            label: "Notaire associé"
        },
        {
            value: "notaireIndividuel",
            label: "Notaire individuel"
        },
        {
            value: "notaireSalarie",
            label: "Notaire salarié"
        },
    ],
    typesEnFonction: [
        'notaireAssocie',
        'notaireIndividuel',
        'notaireSalarie'
    ],
    typesAnciens: [
        "honoraire",
        "ancienNotaire",
    ],
    typesAnciensLight: [
        "ancienNotaire",
    ],
    typesAnciensFull: [
        "honoraire",
        "ancienNotaire",
        "deces",
    ],
    fonctionsOptions: [
        {
            label: "Arrondissement",
            options: [
                {
                    value: "presidentArrondissement",
                    label: "Président(e) d’arrondissement"
                },

            ]
        },
        {
            label: "Chambre",
            options: [
                {
                    value: "president",
                    label: "Président(e)"
                },
                {
                    value: "premierVicePresidentPlainteReclamation",
                    //label: "Premier(e) Vice-président(e) chargé(e) de la déontologie et de la confraternité"
                    label: "Premier(e) Vice-Président(e) chargé(e) des plaintes et réclamations"
                },
                {
                    value: "premierVicePresidentDeontologieConfraternite",
                    //label: "Premier(e) Vice-président(e) chargé(e) de la déontologie et de la confraternité"
                    label: "Premier(e) Vice-président(e) chargé(e) de la communication et de la confraternité"
                },
                {
                    value: "premierVicePresidentFinancesSolidarites",
                    //label: "Premier(e) Vice-président(e) chargé(e) des finances et des solidarités"
                    label: "Premier(e) Vice-président(e) chargé(e) des finances et du développement"
                },
                {
                    value: "premierVicePresidentCommunicationFormation",
                    //label: "Premier(e) Vice-président(e) chargé(e) de la communication et de la formation"
                    label: "Premier(e) Vice-président(e) chargé(e) de la formation et de la carrière professionnelle"
                },
                {
                    value: "vicePresidentDeontologieConfraternite",
                    //label: "Vice-président(e) chargé(e) de la déontologie et de la confraternité"
                    label: "Vice-président(e) chargé(e) de la communication et de la confraternité"
                },
                {
                    value: "vicePresidentFinancesSolidarites",
                    //label: "Vice-président(e) chargé(e) des finances et des solidarités"
                    label: "Vice-président(e) chargé(e) des finances et du développement"
                },
                {
                    value: "vicePresidentCommunicationFormation",
                    //label: "Vice-président(e) chargé(e) de la communication et de la formation"
                    label: "Vice-président(e) chargé(e) de la formation et de la carrière professionnelle"
                },
                {
                    value: "premierSyndic",
                    //label: "Vice-Président(e) chargé(e) des réclamations"
                    label: "Vice-Président(e) chargé(e) des plaintes et réclamations"
                },
                {
                    value: "syndicsAdjoints",
                    label: "Syndic adjoint(e)"
                },
                {
                    value: "secretaire",
                    label: "Secrétaire"
                },
                {
                    value: "secretaireAdjoint",
                    label: "Secrétaire adjoint(e)"
                },
                {
                    value: "tresorier",
                    label: "Trésorier(ière)"
                },
                {
                    value: "tresorierAdjoint",
                    label: "Trésorier(ière) adjoint(e)"
                },
                {
                    value: "rapporteur",
                    label: "Rapporteur"
                },
                {
                    value: "taxateurs",
                    label: "Taxateur"
                },
                {
                    value: "membre",
                    label: "Membre"
                },
                {
                    value: "delegueCSN",
                    label: "Délégué(e) de la cour au CSN"
                },
            ]
        },
        {
            label: "Chambre de discipline",
            options: [
                {
                    value: "discPresident",
                    label: "DISC - Président(e)"
                },
                {
                    value: "discPremierSyndic",
                    label: "DISC - Premier syndic"
                },
                {
                    value: "discMembreDroit",
                    label: "DISC - Membre de droit"
                },
                {
                    value: "discMembreTitulaire",
                    label: "DISC - Membre titulaire"
                },
                {
                    value: "discMembreSupplement",
                    label: "DISC - Membre Suppléant"
                },
            ]
        },
        {
            label: "CTRC",
            options: [
                {
                    value: "ctrcPresident",
                    label: "CTRC - Président(e)"
                },
                {
                    value: "ctrcMembre",
                    label: "CTRC - Membre"
                },
                {
                    value: "ctrcRepresentantMembre",
                    label: "CTRC - Représentant(e) Chambre"
                },
            ]
        },
        {
            label: "Caisse Régionale de Garantie",
            options: [
                {
                    value: "crgPresident",
                    label: "CRG - Président(e)"
                },
                {
                    value: "crgVicePresident",
                    label: "CRG - Vice-président(e)"
                },
                {
                    value: "crgTresorier",
                    label: "CRG - Trésorier(ière)"
                },
                {
                    value: "crgSecretaire",
                    label: "CRG - Secrétaire"
                },
                {
                    value: "crgMembre",
                    label: "CRG - Membre"
                },
            ]
        }
    ],
    labelsOptions: [
        {
            label: "Autres diplômes",
            options: [
                {
                    value: "diplomeDroitCollectivitesLocales",
                    label: "Diplôme en Droit des Collectivités Locales"
                },
                {
                    value: "diplomeDroitInternalPrive",
                    label: "Diplôme en Droit International Privé"
                },
                {
                    value: "masterDroitPatrimonial",
                    label: "Master Droit patrimonial, immobilier et notarial"
                },
                {
                    value: "masterEntrepriseAgricole",
                    label: "Master Entreprise Agricole"
                },
            ]
        },
        {
            label: "Labels",
            options: [
                {
                    value: "notaireConseilInternational",
                    label: "Notaire Conseil à l'International"
                },
                {
                    value: "notaireConseilFamilles",
                    label: "Notaire Conseil aux familles"
                },
                {
                    value: "notaireConseilerAmenagementEnvrionnement",
                    label: "Notaire Conseil en Aménagement et Environnement"
                },
                {
                    value: "rev",
                    label: "REV : Recognised European Valuer"
                },
                {
                    value: "SecretariatJuridiqueSocietes",
                    label: "Secrétariat juridique des sociétés"
                },
                {
                    value: "trv",
                    label: "TRV : Tegova Residential Valuer"
                },
            ]
        },
        {
            label: "Autres diplômes",
            options: [
                {
                    value: "diplomeExpertGestionPatrimoine",
                    label: "Diplôme Expert en Gestion de Patrimoine"
                },
                {
                    value: "masterDroitFiscalApprofondi",
                    label: "Master Droit Fiscal Approfondi"
                },
            ]
        },
    ],

    languesParleesOptions: [
        {
            value: "anglais",
            label: "Anglais"
        },
        {
            value: "allemand",
            label: "Allemand"
        },
        {
            value: "italien",
            label: "Italien"
        },
        {
            value: "russe",
            label: "Russe"
        },
        {
            value: "arabe",
            label: "Arabe"
        },
    ],

    creationSteps: [
        {
            url: "/identite",
            title: "Identité",
            icon: "icon-notaires",
        },
        {
            url: "/coordonnees",
            title: "Coordonnées",
            icon: "icon-coordonnees-adresses",
        },
        {
            url: "/adresses",
            title: "Adresses",
            icon: "icon-coordonnees",
        },
        {
            url: "/office",
            title: "Office",
            icon: "icon-office",
        },
        {
            url: "/historique",
            title: "Historique",
            icon: "icon-historique",
        },
        {
            url: "/commentaires",
            title: "Commentaires",
            icon: "icon-commentaires",
        },
        {
            url: "/evenements",
            title: "Historique des fonctions",
            icon: "icon-historique",
        },
        {
            url: "/documents",
            title: "Carrière Professionnelle",
            icon: "icon-documents",
        },
    ],

    documentsTypesOptions: [
        {
            value: "attestation-urssaf",
            label: "URSSAF",
        },
        {
            value: "cv",
            label: "CV",
        },
        {
            value: "prestation-serment",
            label: "Prestation de serment",
        },
        {
            value: "arrete",
            label: "Arrêté",
        },
        {
            value: "autre-notaires",
            label: "Autre",
        },
    ],

    documentsOrderOptions: [
        {
            value: "date",
            label: "Date"
        },
        {
            value: "commentaires",
            label: "Titre"
        },
        {
            value: "type",
            label: "Type"
        },
    ]
};
