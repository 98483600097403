import ApiService, {
    IApiCustomResponse, IApiPagination, IExportApiResponse,
} from "./Api.service";
import {ICampagneModificationOffice} from "../Models/CampagneModificationOffice.model";

export type ICampagneModificationOfficeGetApiResponse = IApiCustomResponse & {
    datas?: {
        campagneModificationOffice: ICampagneModificationOffice
    }
}

export type ICampagneModificationOfficeVerificationFormData = {
    code: string,
}

export type ICampagnesModificationsOfficesPaginationGetFormData = {
    page: number,
    orderBy: string,
    orderType: string,
    campagneModificationId: number,
    etat?: string[],
}

export type ICampagnesModificationsOfficesExportGetFormData = {
    etat?: string[],
}

export type ICampagnesModificationsOfficesGetPaginationApi = IApiPagination & {
    data: ICampagneModificationOffice[],
}
export type ICampagnesModificationsOfficesPaginationGetApiResponse = IApiCustomResponse & {
    datas: {
        pagination: ICampagnesModificationsOfficesGetPaginationApi
    }
}

export class CampagnesModificationsOfficesService extends ApiService {
    private baseRoute = 'api/annuaire/campagnes-modifications-offices';
    private baseRouteExternal = 'api/external/campagnes-modifications-offices';

    /**
     * Permet la récupération d'une liste des campagnes de modifications des offices avec pagination
     * avec un identifiant de campagne de modification
     *
     * @returns {Promise<IGetUserApiResponse>}
     */
    public getCampagnesModificationsOfficesWithPagination(formData: ICampagnesModificationsOfficesPaginationGetFormData): Promise<ICampagnesModificationsOfficesPaginationGetApiResponse>{
        return this.doApiCall(`${this.baseRoute}`, 'GET', formData);
    }

    /**
     * Permet l'export des campagnes de modifications d'offices liées à une campagne de modification (excel)
     * @param {string} cmid
     * @param {ICampagnesModificationsOfficesExportGetFormData} formData
     */
    public exportCampagnesModificationsOfficesByCampagneModification(cmid: string, formData: ICampagnesModificationsOfficesExportGetFormData): Promise<IExportApiResponse>{
        console.log(formData);
        return this.doApiCall(`${this.baseRoute}/export-par-campagne-modification/${cmid}`, 'POST', formData);
    }

    public getCampagneModificationOffice(cmoid: string): Promise<ICampagneModificationOfficeGetApiResponse> {
        return this.doApiCall(`${this.baseRoute}/${cmoid}`, 'GET');
    }

    public updateCampagneModificationOfficeEtatFinal(cmoid: string): Promise<ICampagneModificationOfficeGetApiResponse> {
        return this.doApiCall(`${this.baseRoute}/${cmoid}/define-etat-final`, 'POST');
    }

    public replaceCoordonnees(cmoid: string): Promise<ICampagneModificationOfficeGetApiResponse> {
        return this.doApiCall(`${this.baseRoute}/${cmoid}/replace-coordonnees`, 'POST');
    }

    public rollbackCoordonnees(cmoid: string): Promise<ICampagneModificationOfficeGetApiResponse> {
        return this.doApiCall(`${this.baseRoute}/${cmoid}/rollback-coordonnees`, 'POST');
    }

    public getCampagneModificationOfficeByToken(token: string): Promise<ICampagneModificationOfficeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}`, 'GET');
    }

    public sendCode(token: string): Promise<ICampagneModificationOfficeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}/send-code`, 'POST');
    }

    public checkCode(token: string, formDatas: ICampagneModificationOfficeVerificationFormData): Promise<ICampagneModificationOfficeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}/check-code`, 'POST', formDatas);
    }
}
