import {ICampagneModificationCoordonnee} from "src/Models/CampagneModificationCoordonnee.model";
import CampagneModificationCoordonneeForm
    from "../../Forms/CampagneModificationCoordonnee/CampagneModificationCoordoneeForm";
import {IApiCustomResponse} from "src/Services/Api.service";
import {ICampagneModificationCoordonneeFormData} from "src/Services/CampagneModificationCoordonneesService";
import AccordionSimpleComponent from "../../Accordions/AccordionSimple.component";
import {CampagneModificationCoordonneesService} from "src/Services/CampagneModificationCoordonneesService";
import React, {useContext, useEffect, useState} from "react";
import {IUserContext, UserContext} from "../../../Providers/User.provider";
import {Link} from "react-router-dom";
import {AppConstants} from "../../../Constants/AppConstants";

type ICampagneModificationCoordonneesTypes = ['office', 'notaire'] | ['office'] | ['notaire'] | [];

type ICampagneModificationCoordonneesFormContainerProps = {
    campagnesModificationsCoordonnees: ICampagneModificationCoordonnee[],
    types?: ICampagneModificationCoordonneesTypes,
    successSubmitForm: (response: IApiCustomResponse) => void,
    modificators?: string,
    isDisplayForOnlyOne?: boolean,
}


//"Container"/Groupement de formulaires de campagne de modification de coordonnées
export default function CampagneModificationCoordoneesFormContainer(props: ICampagneModificationCoordonneesFormContainerProps) {
    const {isAuthenticated, isAdmin} = useContext(UserContext) as IUserContext;

    const campagnesModificationsCoordonneeService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();

    const [campagnesModificationsCoordonneesOffices, setCampagnesModificationsCoordonneesOffices] = useState<ICampagneModificationCoordonnee[]>([]);
    const [campagnesModificationsCoordonneesNotaires, setCampagnesModificationsCoordonneesNotaires] = useState<ICampagneModificationCoordonnee[]>([]);

    const types: ICampagneModificationCoordonneesTypes = props.types != null ? props.types : ['office', 'notaire'];

    useEffect(() => {
        //Récupérer les campagnes de modifications des coordonnées des offices (coordonnées dont le notaire est vide)
        setCampagnesModificationsCoordonneesOffices(props.campagnesModificationsCoordonnees.filter((campagne) => campagne.notaire === null));

        //Récupérer les campagnes de modifications des coordonnées des notaires (coordonnées dont le notaire est renseigné)
        setCampagnesModificationsCoordonneesNotaires(props.campagnesModificationsCoordonnees.filter((campagne) => campagne.notaire !== null));
    }, [props.campagnesModificationsCoordonnees]);

    return (
        <div className="campagne-form-wrapper">
            <AccordionSimpleComponent
                title="Explications"
                modificators={(isAuthenticated && isAdmin) ? "" : "-no-padding-left-right"}
                icon={"icon-informations"}
                contentShowedOnInit={true}>
                <div className="informations-text -fw-medium">
                    <p>
                        Veuillez compléter le formulaire ci-dessous afin de mettre à jour vos données.
                    </p>
                    <p className="-c-primary">
                        <strong>Office et Notaires</strong>
                    </p>
                    <p>
                        Lorsque vous allez enregistrer une adresse email, un mail de confirmation sera envoyé à cette
                        adresse.<br/>
                        Une fois que l'adresse email sera confirmée par son « propriétaire », elle pourra être validée
                        par la Chambre et n'est plus modifiable.<br/>
                        Voici la liste des différents états :<br/>
                    </p>
                    <ul>
                        <li>
                            <strong>À traiter</strong> : Vous n'êtes pas encore intervenu sur cette donnée.
                        </li>
                        <li>
                            <strong>À confirmer par son « propriétaire »</strong> : Vous avez renseigné une nouvelle
                            adresse email. Un email de confirmation a été envoyé à cette adresse.
                        </li>
                        <li>
                            <strong>Choix d'affichage et sous-domaine personalisé à préciser</strong> : Vous devez renseigner le sous-domain personalisé et le choix d'affichage.
                        </li>
                        <li>
                            <strong>À valider par la Chambre</strong> : L'adresse email a été confirmée. La Chambre doit
                            maintenant valider cette adresse email.
                        </li>
                        <li>
                            <strong>À supprimer par la Chambre</strong> : L'adresse email doit être supprimée par la
                            Chambre.
                        </li>
                        <li>
                            <strong>Validée par la Chambre</strong> : L'adresse email a été validée par la Chambre.
                        </li>
                        <li>
                            <strong>Coordonnée remplacée</strong> : L'adresse email a été remplacée par une nouvelle
                            adresse email.
                        </li>
                    </ul>
                    {props.isDisplayForOnlyOne == null && (
                        <>
                            <p className="-c-primary">
                                <strong>Il manque un collaborateur, un négociateur ou un adresse mail ?</strong>
                            </p>
                            <p>
                                Si vous avez besoin d'ajouter un collaborateur, un négociateur ou une adresse mail,
                                n'hésitez
                                pas à contacter la Chambre directement
                                par mail : <a
                                href="mailto:contact.ci.caen@notaires.fr">contact.ci.caen@notaires.fr</a> en
                                communiquant les informations nécessaires.
                            </p>
                        </>
                    )}
                </div>
            </AccordionSimpleComponent>

            {types.map((type: 'notaire'|'office', index) => {
                const campagnesModificationsCoordonnees = type === 'office' ? campagnesModificationsCoordonneesOffices : campagnesModificationsCoordonneesNotaires;
                if(campagnesModificationsCoordonnees.length > 0) {
                    return (
                        <AccordionSimpleComponent
                            key={index}
                            modificators={props.modificators != null ? props.modificators : '-no-padding-left'}
                            title={type === 'office' ? "Coordonnées de l'office" : "Coordonnées des notaires"}
                            icon={type === 'office' ? "icon-office" : "icon-notaires"}
                            contentShowedOnInit={true}>
                            {
                                campagnesModificationsCoordonnees.map((campagneModificationCoordonnee, subIndex) => {
                                    return (
                                        <div className="campagne-form-coordinate" key={subIndex}>
                                            <div className="form-wrapper -mb-20">
                                                <div className="columns">
                                                    <div className="column">
                                                        <div className="informations-block">
                                                            <p className="label">Etat</p>
                                                            <p className="value -flex">
                                                             <span
                                                                 className={`state-bullet -before-element icon-bullet -${campagneModificationCoordonnee.etat === 'a-confirmer-par-email' || campagneModificationCoordonnee.etat === 'a-valider-par-chambre' || campagneModificationCoordonnee.etat === 'choix-affichage-a-preciser' ? 'a-faire' : campagneModificationCoordonnee.etat === 'coordonnee-remplacee' || campagneModificationCoordonnee.etat === 'validee-par-chambre' ? 'realisee' : ''}`}>&nbsp;</span>
                                                                {campagneModificationCoordonnee.etatLabel}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    {((isAuthenticated && isAdmin) && campagneModificationCoordonnee.tokenAcces != null) && (
                                                        <div className="column">
                                                            <div className="informations-block">
                                                                <p className="label">
                                                                    Url pour la coordonnée (envoyé par mail)
                                                                </p>
                                                                <p className="value">
                                                                    <Link
                                                                        to={AppConstants.pathCampagneModificationCooordonneeExternal.replace(':ctoken', campagneModificationCoordonnee.tokenAcces)}>
                                                                        {AppConstants.serverUrl.substring(0, AppConstants.serverUrl.length - 1) + AppConstants.pathCampagneModificationCooordonneeExternal.replace(':ctoken', campagneModificationCoordonnee.tokenAcces)}
                                                                    </Link>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <CampagneModificationCoordonneeForm
                                                campagneModificationCoordonnee={campagneModificationCoordonnee}
                                                type={type}
                                                onSuccessSubmit={props.successSubmitForm}
                                                submitMethod={(formData: ICampagneModificationCoordonneeFormData) => campagnesModificationsCoordonneeService.update(campagneModificationCoordonnee.id, formData)}/>
                                        </div>
                                    )
                                })
                            }
                        </AccordionSimpleComponent>
                    );
                }
            })}
        </div>
    )
}