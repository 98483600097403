import React, {useContext, useEffect, useState} from "react";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useHistory} from "react-router";
import {Link} from "react-router-dom";
import {IUserServiceResetPasswordDatas, UsersService} from "src/Services/Users.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {AppConstants} from "src/Constants/AppConstants";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {ReactComponent as Logo} from "src/assets/images/logo-notaire.svg";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "src/Components/Forms/FormCreator.component";


type ResetPasswordScreenProps = {
    token: string
};

const ResetPasswordScreen = (props: ResetPasswordScreenProps) => {
    const usersService = new UsersService(null);
    const {isAuthenticated} = useContext(UserContext) as IUserContext;

    const [token, setToken] = useState<string>(null);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const history = useHistory();

    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);

    useEffect( () => {
        const formElementsColumn1: FormElement[] = [
            {
                type: 'password',
                fieldName: "password",

                label: "Mot de passe",
                labelModificators: "-white",
                placeholder: "Mot de passe",
                required: true,
            },
            {
                type: 'password',
                fieldName: "password_confirmation",

                label: "Confirmation du mot de passe",
                labelModificators: "-white",
                placeholder: "Confirmation du mot de passe",
                required: true,
            },
        ];
        const currentFormColumns: FormColumn[] = [{
            modificators: "-full",
            elements: formElementsColumn1
        }];
        setFormColumns(currentFormColumns);


        const currentFormActions: FormActions[] = [
            {
                label: "Enregistrer le mot de passe",
                modificators: "-primary -secondary-on-tablet",
                onAction: onSubmit
            }
        ];
        setFormActions(currentFormActions);
    }, []);


    //On vérifie si on est connecté ou non
    useEffect(()=>{
        if(isAuthenticated){
            // On redirige vers la home
            history.push(AppConstants.pathHome);
        }

        if(!props.token){
            history.push(AppConstants.pathPasswordLost);
        }

        setToken(props.token);
    },[isAuthenticated, history, props.token]);



    /**
     * Ecoute du click sur le submit du formulaire
     *
     * @param {FormComponentFormData} formData
     */
    const onSubmit = (formData: FormComponentFormData): void => {
        const formDatasFormated: IUserServiceResetPasswordDatas = {
            password: formData.password,
            password_confirmation: formData.password_confirmation,
            token: token ?? props.token
        }

        usersService.resetPassword(formDatasFormated).then((response: IApiCustomResponse) => {
            setShowConfirmation(true);
        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                seterrorFieldsMessages(error.fieldsMessages);
            }

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la réinitialisation de votre mot de passe.",type: "danger"});
        });
    };


    return (
        <div className="page-wrapper -full-screen">
            <div className="content -bg-primary">
                <div className="login-wrapper">
                    <header className="login-header-wrapper">
                        <figure className="logo-wrapper">
                            <Logo className="logo" />
                        </figure>

                        <h1 className="g-title -center -white">Réinitialisation du mot de passe</h1>

                        {
                            showConfirmation &&
                            <>
                                <p className="intro">
                                    Votre mot de passe a bien été mis à jour. Vous pouvez à présent vous connecter.
                                </p>
                                <Link to={'/connexion'} className="g-link -white -margin-top -center">Se connecter</Link>
                            </>
                        }
                    </header>

                    {
                        !showConfirmation &&
                        <FormCreatorComponent
                            formColumns={formColumns}
                            formActions={formActions}
                            errorFieldsMessages={errorFieldsMessages}
                            errorMessages={errorMessages}

                            modificatorsActions="-outside-center"
                        />
                    }
                </div>
            </div>
        </div>
    );
}

export default ResetPasswordScreen;
