import { useState, useEffect } from "react";
import { FormColumn } from "../FormCreator.component";
import { IApiErrorMessage } from "src/Services/Api.service";
import { IApiFieldsErrorMessages } from "src/Services/Api.service";
import { FormActions } from "../FormCreator.component";
import FormCreatorComponent from "../FormCreator.component";
import { FormElement } from "../FormCreator.component";
import { FormComponentFormData } from "../FormCreator.component";
import { ICampagneModificationAbonneAddFormData } from "src/Services/CampagneModificationAbonnesService";
import { CampagneModificationAbonnesService } from "src/Services/CampagneModificationAbonnesService";
import { ICampagnesModificationsGetApiResponse } from "src/Services/CampagnesModifications.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import { IApiCustomResponse } from "src/Services/Api.service";

type ICampagneModificationAbonneLibreFormComponentProps = {
    officeId: number,
    onSuccessSubmit: (response: ICampagnesModificationsGetApiResponse) => void,
}

//Formulaire d'ajout d'UNE campagne de modification d'un abonné
export default function CampagneModificationAbonnesLibreForm(props: ICampagneModificationAbonneLibreFormComponentProps) {

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const campagneModificationsAbonnesService: CampagneModificationAbonnesService = new CampagneModificationAbonnesService();

    useEffect(()=> {
        const formElementsColumn1: FormElement[] = [
            {
                type: 'email',
                fieldName: "email",

                //label: "Email libre",
                placeholder: "Email",
                required: false,
                modificators: "-d-block",

                showFieldErrorDetail: true,

                oldValue: "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Ajouter",
                modificators: "-primary",
                icon: "icon-add",
                onAction: (formData) => {
                    onSaveFreeAbonne(formData)
                }
            },
        ];
        setFormActions(currentFormActions)
    }, [])

    /**
     * Soumission du formulaire (pour l'enregistrement de la nouvelle donnée)
     *
     * @param {FormComponentFormData} formData
     */
    const onSaveFreeAbonne = (formData: FormComponentFormData) => {
        formData.campagneModificationOfficeId = props.officeId

        campagneModificationsAbonnesService.add(formData as ICampagneModificationAbonneAddFormData).then((response: ICampagnesModificationsGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: response.messages[0],
                type: "success"
            });
        },(error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des coordonnées.",
                    type: "danger"
                });
            }
        });
    }

    return(
        <>
            <div className="free-abonne-wrapper">
                <div className="informations-header">
                    <p className="title -smaller">
                        Ajouter un autre email pour s'abonner au fil d'infos
                    </p>
                </div>
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    modificatorsActions="-mt-20"
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages}
                />
            </div>
        </>
    )
}