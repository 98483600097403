import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {
    CampagnesModificationsOfficesService,
    ICampagneModificationOfficeGetApiResponse
} from "../Services/CampagnesModificationsOffices.service";
import {ICampagneModificationOffice} from "../Models/CampagneModificationOffice.model";

export default function useCampagneModificationOffice(){
    const campagnesModificationsOfficesService: CampagnesModificationsOfficesService = new CampagnesModificationsOfficesService();
    const history = useHistory();

    /**
     * Récupération d'une campagne de modification d'office à partir de son id
     *
     * @param {string} cmoid
     * @param {(campagneModificationOffice: ICampagneModificationOffice) => void} callback
     * @param {() => void} callbackError
     */
    const getCampagneModificationOfficeById = (cmoid: string, callback:(campagneModificationOffice: ICampagneModificationOffice) => void, callbackError?: () => void ) => {
        campagnesModificationsOfficesService.getCampagneModificationOffice(cmoid).then((response: ICampagneModificationOfficeGetApiResponse) => {
            if( response && response.datas.campagneModificationOffice){
                if(callback){
                    callback(response.datas.campagneModificationOffice);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathOffices);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la campagne de modification de l'office",type: "danger"});
        });
    };

    /**
     * Mettre à jour l'état de la campagne de modification de l'office (et de ses coordonnées)
     */
    const updateCampagneModificationOfficeEtatFinal = (cmoid: string, callback:(campagneModificationOffice: ICampagneModificationOffice) => void, callbackError?: () => void) => {
        campagnesModificationsOfficesService.updateCampagneModificationOfficeEtatFinal(cmoid).then((response: ICampagneModificationOfficeGetApiResponse) => {
            if( response && response.datas.campagneModificationOffice){
                if(callback){
                    callback(response.datas.campagneModificationOffice);
                }
            }
            notificationSystem.addNotification({...defaultNotificationConfig, message: "L'état de l'office a été mis à jour",type: "success"});
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            notificationSystem.addNotification({...defaultNotificationConfig, message: error.messages[0],type: "danger"});
        });
    };

    return [getCampagneModificationOfficeById, updateCampagneModificationOfficeEtatFinal];
};

