import TableHeaderButtonComponent from "src/Components/Lists/TableHeaderButton.component";
import {AppConstants} from "src/Constants/AppConstants";
import React from "react";
import {useHistory} from "react-router";
import {ICampagneModificationOffice} from "../../../Models/CampagneModificationOffice.model";
import {IFilterData} from "../../../Hooks/FiltersHandler";

type ICampagneModificationsOfficesTableListComponentProps = {
    filters: IFilterData,
    setOrder: (value: string) => void,
    campagnesModificationsOffices: ICampagneModificationOffice[],
}

export default function CampagneModificationsOfficesTableListComponent(props: ICampagneModificationsOfficesTableListComponentProps) {
    const {filters, setOrder, campagnesModificationsOffices} = props;
    const history = useHistory();

    return (
        <>
            <table className={`list-table`}>
                <thead className={"headers"}>
                <tr>
                    {[
                        {slug: "officeCRPCEN", label: "CRPCEN", sortable: true},
                        {slug: "officeNom", label: "Nom", sortable: true},
                        {slug: "etat", label: "Etat *", sortable: true},
                        {slug: "nbCampagnesModificationsCoordonnees", label: "Nb Coordonnées", sortable: false},
                        {
                            slug: "nbCampagnesModificationsCoordonneesParEtat",
                            label: "Nb Coordonnées par Etat",
                            sortable: false
                        },
                        {slug: "nbCampagnesModificationsNegociateurs", label: "Nb Négociateurs", sortable: false},
                        {
                            slug: "nbCampagnesModificationsNegociateursParEtatValidation",
                            label: "Nb Négociateurs par Etat",
                            sortable: false
                        },
                        {slug: "nbCampagnesModificationsAbonnes", label: "Nb Abonnés", sortable: false},
                        {
                            slug: "nbCampagnesModificationsAbonnesParEtatValidation",
                            label: "Nb Abonnés par Etat",
                            sortable: false
                        },
                    ].map((item, iIndex) => <th className="th" key={iIndex}>
                        <TableHeaderButtonComponent
                            direction={filters.orderType as string || AppConstants.orderType.asc}
                            value={item.slug} selectedValue={filters.orderBy as string || ""}
                            noSort={!item.sortable}
                            label={item.label} click={(value: string) => setOrder(value)}/>
                    </th>)}
                </tr>
                </thead>
                <tbody>
                {
                    campagnesModificationsOffices && campagnesModificationsOffices.map((campagneModificationOffice: ICampagneModificationOffice, index: number) =>
                        <tr key={index}
                            onClick={() => history.push(`/annuaire/campagnes-modifications-offices/${campagneModificationOffice.id}`)}
                            className="tr">
                            <td className="td -bold">
                                {campagneModificationOffice.office.CRPCEN}
                            </td>
                            <td className="td -w200px">
                                {campagneModificationOffice.office.nom}
                            </td>
                            <td className="td">
                                {campagneModificationOffice.etatLibelle ? campagneModificationOffice.etatLibelle : ""}
                            </td>
                            <td className="td">
                                <div>
                                    Nombre de coordonnées (total = office + notaires) :&nbsp;
                                    {campagneModificationOffice.nbCampagnesModificationsCoordonnees}
                                </div>
                                <div>
                                    Nombre de coordonnées (office) :&nbsp;
                                    {campagneModificationOffice.nbCampagnesModificationsCoordonneesOffice}
                                </div>
                                <div>
                                    Nombre de coordonnées (notaires) :&nbsp;
                                    {campagneModificationOffice.nbCampagnesModificationsCoordonneesNotaires}
                                </div>
                            </td>
                            <td className="td">
                                {Object.keys(campagneModificationOffice.nbCampagnesModificationsCoordonneesParEtat || {}).map((key, index) =>
                                    <div key={index}>
                                        {key} : {campagneModificationOffice.nbCampagnesModificationsCoordonneesParEtat[key]}
                                    </div>
                                )}
                            </td>

                            <td className="td">
                                {campagneModificationOffice.nbCampagnesModificationsNegociateurs}
                            </td>
                            <td className="td">
                                {Object.keys(campagneModificationOffice.nbCampagnesModificationsNegociateursParEtat || {}).map((key, index) =>
                                    <div key={index}>
                                        {key} : {campagneModificationOffice.nbCampagnesModificationsNegociateursParEtat[key]}
                                    </div>
                                )}
                            </td>
                            <td className="td">
                                {campagneModificationOffice.nbCampagnesModificationsAbonnes}
                            </td>
                            <td className="td">
                                {Object.keys(campagneModificationOffice.nbCampagnesModificationsAbonnesParEtatValidation || {}).map((key, index) =>
                                    <div key={index}>
                                        {key} : {campagneModificationOffice.nbCampagnesModificationsAbonnesParEtatValidation[key]}
                                    </div>
                                )}
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
            <div className="g-content">
                <div className="information-text -small">
                    <p>
                        <strong>Etat *</strong> : Certains états ne sont pas mis à jour automatiquement, il s'agit d'une tâche automatique lancée toutes les heures, à la 15ème minute.
                    </p>
                </div>
            </div>
        </>
    )
}
