import {IOption} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type ICampagneModificationOfficesConstants = {
    etatsOptions: IOption[],
}
export const CampagneModificationOfficesConstants: ICampagneModificationOfficesConstants = {
    //note en cas de changement, penser à changer dans server/config/campagnesModificationsOffices.php
    etatsOptions: [
        {
            value: "initialisee",
            label: "Initialisée",
        },
        {
            value: "envoyee",
            label: "Envoyée à l'office (aucune modification)",
        },
        {
            value: "traitement-partiel-coordonnees-reste-a-traiter",
            label: "Traitement partiel des coordonnées (reste des À traiter)",
        },
        {
            value: "traitement-partiel-coordonnees-reste-confirmations",
            label: "Traitement partiel des coordonnées (reste des Confirmations)",
        },
        {
            value: "a-valider-par-chambre",
            label: "À valider par la Chambre",
        },
        {
            value: "validee-par-chambre",
            label: "Validée par la chambre",
        },
        {
            value: "office-coordonnees-remplacees",
            label: "Coordonnées remplacées pour l'office",
        }
    ],
};
