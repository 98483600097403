import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import LoadingComponent from "src/Components/Loading/Loading.component";
import {useState, useEffect} from "react";
import {IApiCustomResponse} from "src/Services/Api.service";
import CampagneModificationCoordoneesFormContainer
    from "src/Components/CampagneModification/CampagneModificationCoordonnees/CampagneModificationCoordoneesFormContainer";
import {ICampagneModificationCoordonnee} from "../../Models/CampagneModificationCoordonnee.model";
import {
    CampagneModificationCoordonneesService,
    ICampagnesModificationsCoordonneesGetApiResponse
} from "../../Services/CampagneModificationCoordonneesService";
import CampagneModificationCoordonneeAccesComponent
    from "../../Components/CampagneModification/CampagneModificationCoordonnees/CampagneModificationCoordonneeAcces.component";


type ICampagneModificationCoordonneeExternalScreenProps = {
    ctoken: string,
}

//Fenêtre de modification d'une coordonnée
export default function CampagneModificationCoordonneeExternalScreen(props: ICampagneModificationCoordonneeExternalScreenProps) {
    const pageHeaderTitleDefault: string = "Campagne de modification d'une coordonnée";
    const checkAccess: boolean = true;//todo à mettre à true pour activer la vérification de l'accès

    const [loaded, setLoaded] = useState<boolean>(true);
    const [allowAccess, setAllowAccess] = useState<boolean>(false);
    const [pageHeaderTitle, setPageHeaderTitle] = useState<string>(pageHeaderTitleDefault);
    const [campagnesModificationsCoordonnees, setCampagnesModificationsCoordonnees] = useState<ICampagneModificationCoordonnee[]>([]);
    const campagnesModificationsCoordonneesService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();

    //Si on a pas besoin de vérifier l'accès, on charge directement les données (Note: Dev uniquement)
    useEffect(() => {
        if (!checkAccess) {
            setAllowAccess(true);
            getCampagneModification();
        }
    }, []);

    const getCampagneModification = (): void => {
        setLoaded(false);

        campagnesModificationsCoordonneesService.getCampagnesModificationsCoordonneesByToken(props.ctoken).then((response: ICampagnesModificationsCoordonneesGetApiResponse) => {
            setCampagnesModificationsCoordonnees(response.datas.campagnesModificationsCoordonnees);

            let title = pageHeaderTitleDefault;
            const notaire = response.datas.campagnesModificationsCoordonnees[0]?.notaire;
            if (notaire) {
                title += " : " + notaire.prenom + " " + notaire.nom;
            }
            setPageHeaderTitle(title);

            setLoaded(true);
        }).catch((error) => {
            setLoaded(true);
        });
    }

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getCampagneModification();
    };

    const onSuccessAccess = (campagnesModificationsCoordonnees: ICampagneModificationCoordonnee[]): void => {
        setCampagnesModificationsCoordonnees(campagnesModificationsCoordonnees);

        setAllowAccess(true);
    }

    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                         contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent
                text={pageHeaderTitle}
                icon="icon-fil-infos" logo={true}
                modificators={`-in-fullscreen -border-full-screen`}/>

            {
                !loaded &&
                <LoadingComponent/>
            }

            {(loaded && !allowAccess && checkAccess) &&
                <CampagneModificationCoordonneeAccesComponent
                    ctoken={props.ctoken}
                    onDefineTitle={setPageHeaderTitle}
                    onSuccessAccess={onSuccessAccess}/>
            }

            {
                (loaded && allowAccess && campagnesModificationsCoordonnees.length > 0) &&
                <>
                    <CampagneModificationCoordoneesFormContainer
                        campagnesModificationsCoordonnees={campagnesModificationsCoordonnees}
                        isDisplayForOnlyOne={true}
                        successSubmitForm={onSuccessSubmitForm}/>
                </>
            }


        </LayoutComponent>
    )
}