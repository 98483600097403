
type IConfirmationComponentProps = {
    text: string,
    onConfirm: () => void,
    onCancel: () => void,
    hideCancel?: boolean,
    hideConfirm?: boolean,
    confirmText?: string,
}
export default function ConfirmationComponent(props: IConfirmationComponentProps ) {


    return (
        <div className="confirmation-overlay">
            <div className="content">
                <p className="text">{props.text}</p>

                <div className="actions">
                    {
                        !props.hideCancel &&
                            <button className="g-button -white-border-only" onClick={props.onCancel}>Annuler</button>
                    }
                    {
                        !props.hideConfirm &&
                            <button className="g-button -white" onClick={props.onConfirm}>
                                {props.confirmText || "Valider"}
                            </button>
                    }
                </div>
            </div>
        </div>
    )
}
