import {ICampagnesModificationsGetApiResponse} from "src/Services/CampagnesModifications.service"
import {
    CampagnesModificationsNegociateursService,
    ICampagneModificationNegociateurFormData
} from "src/Services/CampagnesModificationsNegociateursService";
import {FormColumn, FormElement, FormActions, FormComponentFormData} from "../FormCreator.component";
import FormCreatorComponent from "../FormCreator.component";
import {IApiErrorMessage, IApiFieldsErrorMessages} from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import React, {useState, useEffect} from "react";
import {ICampagneModificationNegociateur} from "../../../Models/CampagneModificationNegociateur.model";
import {IApiCustomResponse} from "src/Services/Api.service";
import {IUserContext, UserContext} from "src/Providers/User.provider";
import {useContext} from "react";
import ConfirmationComponent from "../../Confirmation/Confirmation.component";
import {ICoordonnee} from "../../../Models/Coordonnee.model";
import CoordonneesHelper from "../../../Helpers/Coordonnees.helper";
import {INegociateur} from "../../../Models/Negociateur.model";
import NegociateursHelper from "../../../Helpers/Negociateurs.helper";

type ICampagneModificationNegociateurFormComponentProps = {
    campagneModificationNegociateur?: ICampagneModificationNegociateur,
    submitMethod: (formData: ICampagneModificationNegociateurFormData) => Promise<ICampagnesModificationsGetApiResponse>,
    onSuccessSubmit: (response: ICampagnesModificationsGetApiResponse) => void,
}

//Formulaire d'édition d'UNE campagne de modification de négociateur
export default function CampagneModificationNegociateurForm(props: ICampagneModificationNegociateurFormComponentProps) {

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formActions, setFormActions] = useState<FormActions[]>([]);

    const [showConfirmation, setShowConfirmation] = useState<boolean>();
    const [onConfirm, setOnConfirm] = useState<() => void>();
    const [confirmationText, setConfirmationText] = useState<string>();

    const {isAdmin, isAuthenticated} = useContext(UserContext) as IUserContext;

    const finishedStatuses = ["negociateur-remplace", "validee-par-chambre", "a-valider-par-chambre", "a-supprimer-par-chambre"];

    const campagnesModificationsNegociateursService: CampagnesModificationsNegociateursService = new CampagnesModificationsNegociateursService();

    /**
     * Initialisation du formulaire à partir des infos de notaires présent pour la campagne
     */
    useEffect(() => {

        // On reset le formAction pour pouvoir faire disparaitre les boutons en cas de campagne finie
        setFormActions([]);

        const formElementsColumn1: FormElement[] = [];
        if (props.campagneModificationNegociateur != null && props.campagneModificationNegociateur.negociateur != null) {
            formElementsColumn1.push({
                type: "informations",
                label: "Nom du négociateur",
                modificators: "-d-block",
                oldValue: props.campagneModificationNegociateur.negociateur.nom ?? "-",
            });
        }

        //Afficher le type de négociateur
        /*
        let typeLabel: string = "";
        let negociateur: INegociateur = null;
        if(props.campagneModificationNegociateur.negociateur != null &&
            props.campagneModificationNegociateur.negociateur.type != null) {
            negociateur = props.campagneModificationNegociateur.negociateur;
            typeLabel = NegociateursHelper.getFormatedType(negociateur.type);
        }
        if(typeLabel !== "") {
            formElementsColumn1.push({
                type: "informations",
                label: "Type",
                modificators: "-d-block",
                oldValue: typeLabel,
            });
        }
         */

        const formElementsColumn2: FormElement[] = [
            {
                type: "informations",
                label: "Ancien email",
                modificators: "-d-block",
                oldValue: props.campagneModificationNegociateur && props.campagneModificationNegociateur.ancienEmail ? props.campagneModificationNegociateur.ancienEmail : "",
            },
        ];
        const formElementsColumn3: FormElement[] = [
            {
                type: "text",
                fieldName: "nouvel_email",

                label: "Nouvel email (par défaut l'ancien email)",
                placeholder: "Nouvel Email",
                required: true,
                showFieldErrorDetail: true,

                disabled: props.campagneModificationNegociateur.nouvelEmail !== null && finishedStatuses.includes(props.campagneModificationNegociateur.etat),

                // Par défault, on affiche l'ancienne valeur par défault (peut-être à changer)
                oldValue: props.campagneModificationNegociateur.ancienEmail && props.campagneModificationNegociateur.nouvelEmail === "" ? props.campagneModificationNegociateur.ancienEmail : props.campagneModificationNegociateur.nouvelEmail,
            },
        ];

        const currentFormColumns: FormColumn[] = [];

        if (formElementsColumn1.length > 0) {
            currentFormColumns.push({
                elements: formElementsColumn1
            });
        }

        currentFormColumns.push({
            elements: formElementsColumn2
        });
        currentFormColumns.push({
            elements: formElementsColumn3
        });
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaire
        const currentFormActions: FormActions[] = [];

        if (!finishedStatuses.includes(props.campagneModificationNegociateur.etat)) {
            //Si on n'est pas à la fin du process,
            // => On affiche l'enregistrement des nouvelles données du négociateur
            currentFormActions.push({
                label: "Enregistrer",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: false,
                onAction: (formData) => {
                    onSaveNouvelDonnee(formData);
                }
            });

            if (props.campagneModificationNegociateur.etat === "a-traiter") {
                //Si on est à l'état "a-traiter"
                // => Permettre de faire une demande de suppression du négociateur (rattachée à la campagne de modification de négociateur)
                currentFormActions.push({
                    label: "Demander la suppression",
                    modificators: "-primary-border-only",
                    icon: "icon-remove-alternative",
                    hasLoading: false,
                    onAction: () => {
                        setShowConfirmation(true);
                        setOnConfirm(() => onSetStateToDelete);
                        setConfirmationText("Souhaitez-vous vraiment demander la suppression de ce négociateur ?");
                    }
                });
            }
        } else {
            // Si la campagne est en attente de validation par la chambre, on affiche le bouton de validation (si l'utilisateur est connecté au progiciel et est admin)
            if (props.campagneModificationNegociateur.etat === "a-valider-par-chambre" && isAuthenticated && isAdmin) {
                currentFormActions.push({
                    label: "Valider",
                    modificators: "-primary",
                    icon: "icon-check",
                    hasLoading: false,
                    onAction: () => {
                        onValidateCampagneModificationNegociateur();
                    }
                });
            }
        }

        setFormActions(currentFormActions);

    }, [props.campagneModificationNegociateur]);

    /**
     * Soumission du formulaire (pour l'enregistrement de la nouvelle donnée)
     *
     * @param {FormComponentFormData} formData
     */
    const onSaveNouvelDonnee = (formData: FormComponentFormData) => {
        if (!props.submitMethod) return;

        if (props.campagneModificationNegociateur && props.campagneModificationNegociateur.id) {
            props.submitMethod(formData as ICampagneModificationNegociateurFormData).then((response: ICampagnesModificationsGetApiResponse) => {
                //On reset les erreurs
                setErrorsMessage(null);
                setErrorFieldsMessages(null);

                if (props.onSuccessSubmit) {
                    props.onSuccessSubmit(response);
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: response.messages[0],
                    type: "success"
                });

            }, (error: IApiCustomResponse) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                if (!error.messages && !error.messages) {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: "Une erreur est survenue lors de la modification des données du négociateur.",
                        type: "danger"
                    });
                }
            });
        } else {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la modification des données du négociateur.",
                type: "danger"
            });
        }
    }

    /**
     * Bouton pour la validation de la campagne de modification du négociateur
     */
    const onValidateCampagneModificationNegociateur = () => {
        //On reset les erreurs
        setErrorsMessage(null);
        setErrorFieldsMessages(null);

        campagnesModificationsNegociateursService.validation(props.campagneModificationNegociateur.id)
            .then((response: ICampagnesModificationsGetApiResponse) => {
                if (response) {
                    if (props.onSuccessSubmit) {
                        props.onSuccessSubmit(response);
                    }

                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "success"
                    });
                } else {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "danger"
                    });
                }
            })
            .catch((error) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la validation de la campagne de modification du négociateur.",
                    type: "danger"
                });
            });
    }

    /**
     * Bouton pour la demande de suppression du négociateur rattachée à la campagne de modification de négociateur
     */
    const onSetStateToDelete = () => {
        //On reset les erreurs
        setErrorsMessage(null);
        setErrorFieldsMessages(null);
        onCancelConfirmation();

        campagnesModificationsNegociateursService.setStateToDelete(props.campagneModificationNegociateur.id)
            .then((response: ICampagnesModificationsGetApiResponse) => {
                if (response) {
                    if (props.onSuccessSubmit) {
                        props.onSuccessSubmit(response);
                    }

                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "success"
                    });
                } else {
                    notificationSystem.addNotification({
                        ...defaultNotificationConfig,
                        message: response.messages[0],
                        type: "danger"
                    });
                }
            })
            .catch((error) => {
                if (error.messages) {
                    setErrorsMessage(error.messages);
                }
                if (error.fieldsMessages) {
                    setErrorFieldsMessages(error.fieldsMessages);
                }

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la demande de suppression du négociateur.",
                    type: "danger"
                });
            });
    }

    /**
     * Annulation de la confirmation
     */
    const onCancelConfirmation = (): void => {
        setShowConfirmation(false);
        setOnConfirm(null);
        setConfirmationText(null);
    }

    return (
        <>
            {
                showConfirmation &&
                <ConfirmationComponent
                    onConfirm={onConfirm}
                    onCancel={onCancelConfirmation}
                    text={confirmationText}
                    confirmText="Supprimer"/>
            }
            <FormCreatorComponent
                formColumns={formColumns}
                formActions={formActions}
                modificatorsActions="-mt-20"
                errorFieldsMessages={errorFieldsMessages}
                errorMessages={errorMessages}
            />
        </>
    )
}