import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {IOfficesGetApiResponse, IOfficesGetFormData, OfficesService} from "src/Services/Offices.service";
import {IOffice} from "src/Models/Office.model";
import {IApiPaginationLink} from "src/Services/Api.service";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import SelectMultipleFieldComponent from "src/Components/Fields/SelectMultiple.field.component";
import {OfficesConstants} from "src/Constants/OfficesConstants";
import PaginationComponent from "src/Components/Pagination/Pagination.component";
import LayoutComponent from "src/Components/Layout/Layout.component";
import useListFilters, {IFilterData} from "src/Hooks/FiltersHandler";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import MapComponent, {MapMarker} from "src/Components/Map/Map.component";
import {IAdresse} from "src/Models/Adresse.model";
import {FormComponentFormData} from "src/Components/Forms/FormCreator.component";
import MapHelper from "src/Helpers/Map.helper";
import AsideOfficeComponent from "src/Components/Aside/AsideOffice.component";
import OfficesExportComponent from "src/Components/Annuaire/Offices/OfficesExport.component";
import OfficesAdvancedSearchComponent from "src/Components/Annuaire/Offices/OfficesAdvancedSearch.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import OfficesTableListComponent from "src/Components/Annuaire/Offices/OfficesTableList.component";
import LoadingComponent from "../../../Components/Loading/Loading.component";
import CampagneModificationsTableListComponent
    from "../../../Components/Annuaire/Campagnes-modifications/CampagneModificationsTableList.component";
import {
    CampagnesModificationsService, ICampagnesModificationsPaginationGetApiResponse,
    ICampagnesModificationsPaginationGetFormData
} from "../../../Services/CampagnesModifications.service";
import {ICampagneModification} from "../../../Models/CampagneModification.model";


type OfficesListScreenProps = {}
export default function OfficesListScreen(props: OfficesListScreenProps) {

    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const [filters, initialFiltersFromUrlQuery, updateFilters, setOrder, defaultFilters] = useListFilters("/annuaire/offices");
    const [officesList, setOfficesList] = useState<IOffice[]>([]);
    const [campagnesModificationsList, setCampagnesModificationsList] = useState<ICampagneModification[]>([]);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [markers, setMarkers] = useState<MapMarker[]>([]);
    const location = useLocation();
    const [showExport, setShowExport] = useState<boolean>(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.LIST);


    /**
     * Initialisation
     */
    useEffect(() => {

        //Mise à jour du breadcrumb
        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Offices"},
        ];
        setBreadcrumbLinks(breadcrumbLinks);


        // Mise à jour des paramètres par défaut à partir des params de l'url
        const params: any = location.search;
        const defaultFilters: IFilterData = initialFiltersFromUrlQuery(new URLSearchParams(params), []);
        updateFilters(defaultFilters, false, true);
    }, []);


    /**
     * Ecoute du changement des filtres
     */
    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);

    /**
     * Récupération des offices
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        //Gestion de la tab
        filters.tab = activeTab;

        //Pour la liste et la carte, récupérer les offices
        if (TabsSlugs.LIST === activeTab || TabsSlugs.MAP === activeTab) {
            setPaginationLinks([]);
            const officesService: OfficesService = new OfficesService();
            officesService.getOfficesPrincipalesWithPagination(filters as IOfficesGetFormData).then((response: IOfficesGetApiResponse) => {
                if (response && response.datas.pagination) {
                    const currentOfficesList = response.datas.pagination.data;
                    setOfficesList(currentOfficesList);

                    //Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    //Mise à jour des markers
                    updateMapMarkers(currentOfficesList);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }

        //Pour les modifications de coordonnées, récupérer les campagnes de modifications
        if (TabsSlugs.CAMPAGNES_MODIFICATIONS === activeTab) {
            setPaginationLinks([]);
            const campagnesModificationsService: CampagnesModificationsService = new CampagnesModificationsService();
            campagnesModificationsService.getCampagnesModificationsWithPagination(filters as ICampagnesModificationsPaginationGetFormData).then((response: ICampagnesModificationsPaginationGetApiResponse) => {
                if (response && response.datas.pagination) {
                    const currentCampagnesModificationsList = response.datas.pagination.data;
                    setCampagnesModificationsList(currentCampagnesModificationsList);

                    //Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }
    };


    /**
     * Mise à jour des markers à partir d'une liste d'office
     * @param {IOffice[]} currentOfficesList
     */
    const updateMapMarkers = (currentOfficesList: IOffice[]): void => {
        const markers: MapMarker[] = [];
        currentOfficesList.forEach((o) => {
            if (o.adressePrincipale && o.adressePrincipale.latitude) {
                const adressePrincipale: IAdresse = o.adressePrincipale;

                const markerText: string = MapHelper.createMapMarkerText(`${AppConstants.pathOffices}/${o.id}`, o.nom, adressePrincipale);
                const marker: MapMarker = MapHelper.createMapMarker(markerText, adressePrincipale);
                markers.push(marker);
            }
        });
        setMarkers(markers);
    }


    /**
     * Ecoute de la recherche avancée
     *
     * @param {FormComponentFormData} formData
     */
    const onAdvancedSearch = (formData: FormComponentFormData): void => {
        updateFilters({...defaultFilters, ...formData}, true);
        setShowAdvancedSearch(false);
    };


    return (
        <div>
            <LayoutComponent showMenu={true}
                             wrapperModificators={`${showExport || showAdvancedSearch ? '-no-scroll' : ''}`}>
                <div className={`list ${loaded ? 'border-footer' : ''}`}>
                    <PageHeaderComponent
                        breadcrumbLinks={breadcrumbLinks}
                        text={"Offices"}
                        icon={"icon-office"}
                        //Masquer la recherche sur l'onglet campagnes modifications
                        onSearch={
                            activeTab !== TabsSlugs.CAMPAGNES_MODIFICATIONS ?
                                (keyword => updateFilters({...defaultFilters, keyword}, true)) :
                                null
                        }
                        onClickAdvancedSearch={() => setShowAdvancedSearch(!showAdvancedSearch)}/>
                    {
                        !loaded &&
                        <LoadingComponent/>
                    }
                    {
                        loaded &&
                        <>
                            <TabsContainerComponent defaultTab={TabsSlugs.LIST} onClickTabCallback={(tab) => {
                                updateFilters({}, false);
                                setActiveTab(tab)
                            }}>
                                <TabPanelComponent label="Liste" icon="icon-menu" slug={TabsSlugs.LIST}>
                                    <div className="list-content -right-gutter">
                                        <div className="list-filters">
                                            <i className={`icon icon-filtre`}/>
                                            <SelectMultipleFieldComponent fieldName="departement"
                                                                          label="Départements"
                                                                          options={OfficesConstants.departementsOptions}
                                                                          hideCurrentValuesOnTop={true}
                                                                          errors={null}
                                                                          placeholder="Tous les départements"
                                                                          hideSearch={true}
                                                                          modificators={`-inline -on-white`}
                                                                          oldValues={filters.departement ? filters.departement as string[] : []}
                                                                          onChange={(values: string[]) => updateFilters({departement: values})}
                                            />
                                        </div>

                                        <OfficesAdvancedSearchComponent
                                            showSummary={true}
                                            showAdvancedSearch={showAdvancedSearch}
                                            filters={filters}
                                            updateFilters={updateFilters}
                                            setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                            onSearch={onAdvancedSearch}
                                        />

                                        <OfficesTableListComponent filters={filters} setOrder={setOrder}
                                                                   officesList={officesList}/>

                                        <PaginationComponent links={paginationLinks}
                                                             onPageClick={page => updateFilters({page})}/>

                                        <AsideOfficeComponent type="list" isPrincipales={true}
                                                              exportCallback={() => setShowExport(true)}
                                                              filters={filters}/>
                                    </div>
                                </TabPanelComponent>


                                <TabPanelComponent label="Carte" icon="icon-map" slug={TabsSlugs.MAP}>
                                    <div className={`list-content -no-gutter`}>

                                        <div className="list-filters">
                                            <i className={`icon icon-filtre`}/>
                                            <SelectMultipleFieldComponent fieldName="departement"
                                                                          label="Départements"
                                                                          options={OfficesConstants.departementsOptions}
                                                                          hideCurrentValuesOnTop={true}
                                                                          errors={null}
                                                                          placeholder="Tous les départements"
                                                                          hideSearch={true}
                                                                          modificators={`-inline -on-white`}
                                                                          oldValues={filters.departement ? filters.departement as string[] : []}
                                                                          onChange={(values: string[]) => updateFilters({departement: values})}
                                            />
                                        </div>

                                        <OfficesAdvancedSearchComponent
                                            showSummary={false}
                                            showAdvancedSearch={showAdvancedSearch}
                                            filters={filters}
                                            updateFilters={updateFilters}
                                            setShowAdvancedSearch={() => setShowAdvancedSearch(false)}
                                            onSearch={onAdvancedSearch}
                                        />

                                        <div className="map-wrapper">
                                            <MapComponent markers={markers} key={Math.random()}/>
                                        </div>

                                        <AsideOfficeComponent type="list" isPrincipales={true} modificators="-on-map"
                                                              exportCallback={() => setShowExport(true)}
                                                              filters={filters}/>
                                    </div>
                                </TabPanelComponent>

                                <TabPanelComponent label="Campagnes Modifications" icon="icon-coordonnees-adresses"
                                                   slug={TabsSlugs.CAMPAGNES_MODIFICATIONS}>
                                    <div className={`list-content -no-gutter`}>

                                        <CampagneModificationsTableListComponent filters={filters} setOrder={setOrder}
                                                                                 campagnesModificationsList={campagnesModificationsList}/>

                                        <PaginationComponent links={paginationLinks}
                                                             onPageClick={page => updateFilters({page})}/>

                                    </div>
                                </TabPanelComponent>
                            </TabsContainerComponent>
                        </>
                    }
                </div>
            </LayoutComponent>

            <OfficesExportComponent filters={filters} setShowExport={() => setShowExport(false)}
                                    showExport={showExport}/>
        </div>
    )
}
