import React, {useEffect, useState} from "react";
import {ICorrespondanceParticipant} from "src/Models/CorrespondanceParticipant.model";
import {ICorrespondance} from "src/Models/Correspondance.model";
import DateHelper from "src/Helpers/Date.helper";
import ConfirmationComponent from "../../Confirmation/Confirmation.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import {IApiCustomResponse} from "src/Services/Api.service";
import {CorrespondancesService} from "src/Services/Correspondances.service";
import CommonHelper from "../../../Helpers/Common.helper";
import RadioColorButtonField from "../../Fields/RadioColorButtons.field.component";
import StringHelper from "../../../Helpers/String.helper";
import FileHelper from "../../../Helpers/File.helper";
import LoaderComponent from "../../Loading/Loader.component";


const CorrespondanceSendList = (props: {
    correspondance: ICorrespondance,
    participants: ICorrespondanceParticipant[],
    type: 'test' | 'normal',
    onCorrespondanceChange: (correspondance: ICorrespondance) => void,
}) => {
    const correspondanceService: CorrespondancesService = new CorrespondancesService();
    const [participants, setParticipants] = useState<ICorrespondanceParticipant[]>([]);
    const [participantSelected, setParticipantSelected] = useState<ICorrespondanceParticipant>(null);
    const [showConfirmationResend, setShowConfirmationResend] = useState<boolean>(false);
    const [showConfirmationResendAll, setShowConfirmationResendAll] = useState<boolean>(false);
    const [showConfirmationSend, setShowConfirmationSend] = useState<boolean>(false);
    const [relanceSansDocuments, setRelanceSansDocuments] = useState<boolean>(false);
    const [canShowRelanceButton, setCanShowRelanceButton] = useState<boolean>(true);
    const [isSendingRelance, setIsSendingRelance] = useState<boolean>(false);


    useEffect(() => {
        setParticipants(props.participants);

        let resendAvailable: boolean = false;
        if(props.participants && props.participants.length){
            props.participants.forEach((participant: ICorrespondanceParticipant) => {
                if(participant.etat === "enAttente"){
                    resendAvailable = true;
                }
            });
        }
        setCanShowRelanceButton(resendAvailable);

    }, [props.participants, props.correspondance]);



    /**
     * Retourne le mail du participant
     * @param {ICorrespondanceParticipant} participant
     * @returns {string}
     */
    const getEmail = (participant: ICorrespondanceParticipant) => {
        return participant.emailDestinataire || '';

        /*
        let email = "";

        if (participant.notaire) {

            if(participant.notaire.emailConvocation){
                email = participant.notaire.emailConvocation
            }
            else{
                participant.notaire.coordonnees.forEach((c) => {
                    if (c.type === "email" && c.destination === "principal") email = c.coordonnees;
                })
            }
        }

        if (participant.personneExterieure && participant.personneExterieure.coordonnees) {
            participant.personneExterieure.coordonnees.forEach((c) => {
                if (c.type === "email") email = c.coordonnees;
            })
        }
        if (participant.office) {
            email = (participant.office.emailsComptables.length) ? participant.office.emailsComptables.join(', ') : '';
            //email = participant.email;
        }


        if (participant.email) {
            email = participant.email;
        }
        return email;
         */
    };


    /**
     *
     * Retourne l'identité du participant
     * @param {ICorrespondanceParticipant} participant
     * @returns {string}
     */
    const getIdentite = (participant: ICorrespondanceParticipant) => {
        let name = "";

        if (participant.notaire && participant.notaire.coordonnees) {
            name = `${participant.notaire.prenom } ${participant.notaire.nom}`;
        }

        if (participant.personneExterieure && participant.personneExterieure.coordonnees) {
            name = `${participant.personneExterieure.prenom} ${participant.personneExterieure.nom}`;
        }


        if (participant.nom) {
            name = participant.nom;
        }

        if (participant.email) {
            name = `${participant.prenom || ""} ${participant.nom}`;
        }

        return name;
    };


    /**
     * Callback à l'envoi de la relance
     */
    const onValidationResend = () => {
        //Préparation des données
        const formDatas = {
            relanceSansDocuments: relanceSansDocuments
        };

        //Définition de la fonction d'envoi
        const submitMethod = props.type === 'test' ? correspondanceService.resendCourrierTest(participantSelected.id, formDatas) : correspondanceService.resendCourrier(participantSelected.id, formDatas)

        //Envoi de la requête
        submitMethod.then((result) => {
            setParticipantSelected(null);
            setShowConfirmationResend(false);
            setRelanceSansDocuments(false);

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "La relance a bien été envoyée",
                type: "success"
            });

            if (result.datas && result.datas.correspondance) {
                props.onCorrespondanceChange(result.datas.correspondance);
            }

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Une erreur est survenue lors de l'envoi de la relance.",
                type: "danger"
            });
        });
    };


    const onValidationResendAll = () => {
        setIsSendingRelance(true);

        //Préparation des données
        const formDatas = {
            relanceSansDocuments: relanceSansDocuments
        };

        //Définition de la fonction d'envoi
        const submitMethod = props.type === 'test' ? correspondanceService.resendAllCourrierTestformDatas(props.correspondance.id, formDatas) : correspondanceService.resendAllCourrier(props.correspondance.id, formDatas);

        //Envoi de la requête
        submitMethod.then((result) => {
            setShowConfirmationResendAll(false);
            setRelanceSansDocuments(false);
            setIsSendingRelance(false);

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "La relance a bien été envoyée",
                type: "success"
            });

            if (result.datas && result.datas.correspondance) {
                props.onCorrespondanceChange(result.datas.correspondance);
            }

        }, (error: IApiCustomResponse) => {
            setShowConfirmationResendAll(false);
            setRelanceSansDocuments(false);
            setIsSendingRelance(false);

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Une erreur est survenue lors de l'envoi de la relance.",
                type: "danger"
            });
        });
    }

    /**
     * Annule la demande de relance
     */
    const onCancelResend = () => {
        setParticipantSelected(null);
        setShowConfirmationResend(false);
        setRelanceSansDocuments(false);
    };


    /**
     * Annule la demande de relance collective
     */
    const onCancelResendAll = () => {
        setShowConfirmationResendAll(false);
        setRelanceSansDocuments(false);
    }



    /**
     * Callback à l'envoi du courrier
     */
    const onValidationSend = () => {
        //Définition de la fonction d'envoi
        const submitMethod = props.type === 'test' ? correspondanceService.sendCourrierSingleTest(participantSelected.id, {}) : correspondanceService.sendCourrierSingle(participantSelected.id, {})

        //Envoi de la requête
        submitMethod.then((result) => {
            setParticipantSelected(null);
            setShowConfirmationSend(false);

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });

            if (result.datas && result.datas.correspondance) {
                props.onCorrespondanceChange(result.datas.correspondance);
            }

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Une erreur est survenue lors de l'envoi du courrier.",
                type: "danger"
            });
        });
    }


    /**
     * Annule la demande d'envoi de courrier
     */
    const onCancelSend = () => {
        setParticipantSelected(null);
        setShowConfirmationSend(false);
    };



    /**
     * Change la présence d'un participant
     *
     * @param {ICorrespondanceParticipant} participant
     * @param {string} present
     * @param {string} key
     */
    const onChangePresence = (participant: ICorrespondanceParticipant, present: string, key: string) => {
        correspondanceService.updateParticipantPresence(participant.id.toString(), {key: key, presence: present}).then(() => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La présence du participant a bien été modifiée",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la mise à jour du participant.",
                type: "danger"
            });
        });
    };



    const headers: {label: string, states: string[]}[] = [
        {label: "Etat", states: []},
        {label: "Identité", states: []},
        {label: "Email", states: []},
        {label: "Action", states: []},
        {label: "Date de relance", states: []},
        {label: "Date d'envoi", states: []},
    ];


    if(props.correspondance){
        if(props.correspondance.reunion && props.correspondance.reunion === "oui"){
            headers.push({label: "Présence Réunion", states: []});
        }
        if(props.correspondance.repas && props.correspondance.repas === "oui"){
            headers.push({label: "Présence Repas", states: []});
        }
        if(props.correspondance.cocktail && props.correspondance.cocktail === "oui"){
            headers.push({label: "Présence Cocktail", states: []});
        }
        if(props.correspondance.hebergement && props.correspondance.hebergement === "oui"){
            headers.push({label: "Présence Hébergement", states: []});
        }
        if(props.correspondance.transport && props.correspondance.transport === "oui"){
            headers.push({label: "Présence Transport", states: []});
        }
        if(props.correspondance.presence && props.correspondance.presence === "oui"){
            headers.push({label: "Présence", states: []});
        }
        if(props.correspondance.collaborateur && props.correspondance.collaborateur === "oui"){
            headers.push({label: "Présence Collaborateur", states: []});
        }
    }



    return <>

        {
            canShowRelanceButton &&
            <div className="list-buttons-wrapper">
                <button className={`g-button -primary -with-icon ${isSendingRelance ? '-loading' : ''}`} onClick={() => {setRelanceSansDocuments(false); setShowConfirmationResendAll(true)}}>
                    {
                        !isSendingRelance && <>
                            <i className="picto icon-email-confirmation" />
                            <span className="text">Relancer tout</span>
                        </>
                    }
                    {isSendingRelance && <LoaderComponent/>}
                </button>
                <button className={`g-button -primary-border-only -with-icon ${isSendingRelance ? '-loading' : ''}`} onClick={() => {setRelanceSansDocuments(true); setShowConfirmationResendAll(true)}}>
                    {
                        !isSendingRelance && <>
                            <i className="picto icon-email-confirmation" />
                            <span className="text">Relancer tout sans PJ</span>
                        </>
                    }
                    {isSendingRelance && <LoaderComponent/>}
                </button>
            </div>
        }


        <table className={`list-table`}>
            <thead className={"headers"}>
            <tr>
                {headers.map((item, iIndex) => <React.Fragment key={iIndex}>
                    {
                        <th className="th">
                            <span className="link">
                                <span className="text">{item.label}</span>
                            </span>
		                </th>
                    }
                </React.Fragment>)}
            </tr>
            </thead>
            <tbody>
            {
                participants && participants.map((participant: ICorrespondanceParticipant, index: number) =>
                    <tr key={index} className="tr">
                        <td className="td -bullet">
                            {
                                participant.etat === 'repondu' ? <i className={`state-bullet icon-check`} /> : <span className={`state-bullet icon-bullet -${participant.etat}`}>&nbsp;</span>
                            }
                        </td>

                        <td className="td -w350">
                            {getIdentite(participant)}
                        </td>
                        <td className="td -w350">
                            {getEmail(participant) || '-'}
                        </td>
                        <td className="td -h75px ">
                            <div className="flex-wrapper">
                                {
                                    !participant.dateEnvoi &&
                                    <button className={`button -primary -with-icon -size-2`}
                                            onClick={() => {
                                                setParticipantSelected(participant);
                                                setShowConfirmationSend(true);
                                                setRelanceSansDocuments(false);
                                            }}
                                    >
                                        <i className="picto icon-courrier" />
                                        <span className="text">Envoyer</span>
                                    </button>
                                }


                                {
                                    participant.dateEnvoi && participant.etat === "enAttente" &&
                                        <button className={`button -primary -with-icon -size-2`}
                                                onClick={() => {
                                                    setParticipantSelected(participant);
                                                    setShowConfirmationResend(true);
                                                    setRelanceSansDocuments(false);
                                                }}
                                                title="Relancer"
                                        >
                                            <i className="picto icon-email-confirmation" />
                                            {/* <span className="text">Relancer</span> */}
                                        </button>
                                }
                                {
                                    participant.dateEnvoi && participant.etat === "enAttente" && props.correspondance.documents.length > 0 &&
                                        <button className={`button -primary-border-only -with-icon -size-2 `}
                                                onClick={() => {
                                                    setParticipantSelected(participant);
                                                    setShowConfirmationResend(true);
                                                    setRelanceSansDocuments(true);
                                                }}
                                                title="Relancer sans PJ"
                                        >
                                            <i className="picto icon-email-confirmation" />
                                            {/* <span className="text">Relancer sans PJ</span> */}
                                        </button>
                                }
                                {
                                    participant.courrierPdf &&
                                        <a className={`button -primary-border-only -with-icon -size-2 `}
                                           href={FileHelper.getFileUrl(participant.courrierPdf)} target='_blank'
                                           title="Télécharger"
                                        >
                                            <i className="picto icon-download" />
                                            {/* <span className="text">Télécharger</span> */}
                                        </a>
                                }
                            </div>
                        </td>
                        <td className="td -w100px">
                            {participant.dateRelance !== null && DateHelper.getFormatedDate(participant.dateRelance)}
                        </td>
                        <td className="td -w100px">
                            {participant.dateEnvoi !== null && DateHelper.getFormatedDate(participant.dateEnvoi)}
                        </td>

                        {
                            participant.dateEnvoi && props.type == "normal" &&
                            <>
                                {
                                    (props.correspondance.reunion && props.correspondance.reunion === "oui") &&
                                    <td className="td -presence">
                                        <RadioColorButtonField 
                                            options={
                                                [
                                                    {value:'oui', label:'', circleColorModificators: '-color-actif', modificators: '-with-circle-icon'},
                                                    {value:'non', label:'', circleColorModificators: '-color-annule', modificators: '-with-circle-icon'},
                                                    {value:'', label:'', circleColorModificators: '-color-default', modificators: '-with-circle-icon'},
                                                ]
                                            }
                                            fieldName='reponseReunion'
                                            modificators="-presence-radios"
                                            label=''
                                            oldValue={participant.reponseReunion}
                                            onChange={(present) => onChangePresence(participant, present, "reponseReunion")}

                                        />
                                    </td>
                                }
                                {
                                    (props.correspondance.repas && props.correspondance.repas === "oui") &&
                                    <td className="td -presence">
                                        <RadioColorButtonField 
                                            options={
                                                [
                                                    {value:'oui', label:'', circleColorModificators: '-color-actif', modificators: '-with-circle-icon'},
                                                    {value:'non', label:'', circleColorModificators: '-color-annule', modificators: '-with-circle-icon'},
                                                    {value:'', label:'', circleColorModificators: '-color-default', modificators: '-with-circle-icon'},
                                                ]
                                            }
                                            modificators="-presence-radios"
                                            fieldName='reponseRepas'
                                            label=''
                                            oldValue={participant.reponseRepas}
                                            onChange={(present) => onChangePresence(participant, present, "reponseRepas")}
                                        />
                                    </td>
                                }
                                {
                                    (props.correspondance.cocktail && props.correspondance.cocktail === "oui") &&
                                    <td className="td -presence">
                                        <RadioColorButtonField 
                                            options={
                                                [
                                                    {value:'oui', label:'', circleColorModificators: '-color-actif', modificators: '-with-circle-icon'},
                                                    {value:'non', label:'', circleColorModificators: '-color-annule', modificators: '-with-circle-icon'},
                                                    {value:'', label:'', circleColorModificators: '-color-default', modificators: '-with-circle-icon'},
                                                ]
                                            }
                                            fieldName='reponseCocktail'
                                            modificators="-presence-radios"
                                            label=''
                                            oldValue={participant.reponseCocktail}
                                            onChange={(present) => onChangePresence(participant, present, "reponseCocktail")}
                                        />
                                    </td>
                                }
                                {
                                    (props.correspondance.hebergement && props.correspondance.hebergement === "oui") &&
                                    <td className="td -presence">
                                        <RadioColorButtonField 
                                            options={
                                                [
                                                    {value:'oui', label:'', circleColorModificators: '-color-actif', modificators: '-with-circle-icon'},
                                                    {value:'non', label:'', circleColorModificators: '-color-annule', modificators: '-with-circle-icon'},
                                                    {value:'', label:'', circleColorModificators: '-color-default', modificators: '-with-circle-icon'},
                                                ]
                                            }
                                            fieldName='reponseHebergement'
                                            modificators="-presence-radios"
                                            label=''
                                            oldValue={participant.reponseHebergement}
                                            onChange={(present) => onChangePresence(participant, present, "reponseHebergement")}
                                        />
                                    </td>
                                }
                                {
                                    (props.correspondance.transport && props.correspondance.transport === "oui") &&
                                    <td className="td -presence">
                                        <RadioColorButtonField 
                                            options={
                                                [
                                                    {value:'oui', label:'', circleColorModificators: '-color-actif', modificators: '-with-circle-icon'},
                                                    {value:'non', label:'', circleColorModificators: '-color-annule', modificators: '-with-circle-icon'},
                                                    {value:'', label:'', circleColorModificators: '-color-default', modificators: '-with-circle-icon'},
                                                ]
                                            }
                                            fieldName='reponseTransport'
                                            modificators="-presence-radios"
                                            label=''
                                            oldValue={participant.reponseTransport}
                                            onChange={(present) => onChangePresence(participant, present, "reponseTransport")}
                                        />
                                    </td>
                                }
                                {
                                    (props.correspondance.presence && props.correspondance.presence === "oui") &&
                                    <td className="td -presence">
                                        <RadioColorButtonField 
                                            options={
                                                [
                                                    {value:'oui', label:'', circleColorModificators: '-color-actif', modificators: '-with-circle-icon'},
                                                    {value:'non', label:'', circleColorModificators: '-color-annule', modificators: '-with-circle-icon'},
                                                    {value:'', label:'', circleColorModificators: '-color-default', modificators: '-with-circle-icon'},
                                                ]
                                            }
                                            fieldName='reponsePresence'
                                            modificators="-presence-radios"
                                            label=''
                                            oldValue={participant.reponsePresence}
                                            onChange={(present) => onChangePresence(participant, present, "reponsePresence")}
                                        />
                                    </td>
                                }
                                {
                                    (props.correspondance.collaborateur && props.correspondance.collaborateur === "oui") &&
                                    <td className="td -presence">
                                        <RadioColorButtonField 
                                            options={
                                                [
                                                    {value:'oui', label:'', circleColorModificators: '-color-actif', modificators: '-with-circle-icon'},
                                                    {value:'non', label:'', circleColorModificators: '-color-annule', modificators: '-with-circle-icon'},
                                                    {value:'', label:'', circleColorModificators: '-color-default', modificators: '-with-circle-icon'},
                                                ]
                                            }
                                            fieldName='reponseCollaborateur'
                                            modificators="-presence-radios"
                                            label=''
                                            oldValue={participant.reponseCollaborateur}
                                            onChange={(present) => onChangePresence(participant, present, "reponseCollaborateur")}
                                        />
                                    </td>
                                }
                            </>
                        }
                        {
                            participant.dateEnvoi && props.type == "test" &&
                            <>
                                {
                                    (props.correspondance.reunion && props.correspondance.reunion === "oui") &&
                                    <td className="td -w95px">
                                        { participant.reponseReunion ? CommonHelper.getFormatedOuiNon(participant.reponseReunion) : "" }
                                    </td>
                                }
                                {
                                    (props.correspondance.repas && props.correspondance.repas === "oui") &&
                                    <td className="td -w95px">
                                        { participant.reponseRepas ? CommonHelper.getFormatedOuiNon(participant.reponseRepas) : "" }
                                    </td>
                                }
                                {
                                    (props.correspondance.cocktail && props.correspondance.cocktail === "oui") &&
                                    <td className="td -w95px">
                                        { participant.reponseCocktail ? CommonHelper.getFormatedOuiNon(participant.reponseCocktail) : "" }
                                    </td>
                                }
                                {
                                    (props.correspondance.hebergement && props.correspondance.hebergement === "oui") &&
                                    <td className="td -w100px">
                                        { participant.reponseHebergement ? CommonHelper.getFormatedOuiNon(participant.reponseHebergement) : "" }
                                    </td>
                                }
                                {
                                    (props.correspondance.transport && props.correspondance.transport === "oui") &&
                                    <td className="td -w100px">
                                        { participant.reponseTransport ? CommonHelper.getFormatedOuiNon(participant.reponseTransport) : "" }
                                    </td>
                                }
                                {
                                    (props.correspondance.presence && props.correspondance.presence === "oui") &&
                                    <td className="td -w95px">
                                        {participant.reponsePresence ? CommonHelper.getFormatedOuiNon(participant.reponsePresence) : ""}
                                    </td>

                                }
                            </>
                        }

                    </tr>
                )
            }
            </tbody>
        </table>


        {showConfirmationResend &&
            <ConfirmationComponent onConfirm={()=> onValidationResend()} onCancel={onCancelResend} text="Souhaitez-vous vraiment relancer ce contact ?"/>}

        {showConfirmationSend &&
            <ConfirmationComponent onConfirm={()=> onValidationSend()} onCancel={onCancelSend} text="Souhaitez-vous vraiment envoyer un courrier à ce contact ?"/>}


        {showConfirmationResendAll &&
            <ConfirmationComponent onConfirm={()=> onValidationResendAll()} onCancel={onCancelResendAll} text="Souhaitez-vous vraiment relancer tous les contacts n'ayant pas encore répondu ?"/>}
    </>
};

export default CorrespondanceSendList;
