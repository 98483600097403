import { useState, useEffect, useContext } from "react";
import { FormColumn } from "../FormCreator.component";
import { IApiErrorMessage } from "src/Services/Api.service";
import { IApiFieldsErrorMessages } from "src/Services/Api.service";
import { FormElement } from "../FormCreator.component";
import { FormActions } from "../FormCreator.component";
import FormCreatorComponent from "../FormCreator.component";
import { ICampagneModificationAbonne } from "src/Models/CampagneModificationAbonne.model";
import { CampagneModificationAbonnesConstants } from "src/Constants/CampagneModificationAbonnesConstants";
import { ICampagnesModificationsGetApiResponse } from "src/Services/CampagnesModifications.service";
import { FormComponentFormData } from "../FormCreator.component";
import { CampagneModificationAbonnesService } from "src/Services/CampagneModificationAbonnesService";
import { ICampagneModificationAbonneUpdateFormData } from "src/Services/CampagneModificationAbonnesService";
import { IApiCustomResponse } from "src/Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import { UserContext } from "src/Providers/User.provider";
import { IUserContext } from "src/Providers/User.provider";

type ICampagneModificationAbonneFormComponentProps = {
    campagneModificationAbonne?: ICampagneModificationAbonne,
    onSuccessSubmit: (response: ICampagnesModificationsGetApiResponse) => void,
}

//Formulaire d'édition d'UNE campagne de modification d'un abonné
export default function CampagneModificationAbonneForm(props: ICampagneModificationAbonneFormComponentProps) {

    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [formActions, setFormActions] = useState<FormActions[]>([]);

    const campagneModificationsAbonnesService: CampagneModificationAbonnesService = new CampagneModificationAbonnesService();
    const {isAdmin, isAuthenticated} = useContext(UserContext) as IUserContext;

    const finishedStatuses = ["validee-par-chambre", "abonne-ajoute-fil-info", "abonne-non-ajoute-fil-info"];

    /**
     * Initialisation du formulaire à partir des infos 
     */
    useEffect(() => {

        let emailFieldEditable: boolean = true;

        //Si la campagne est validée, on ne peut plus modifier l'email
        if(finishedStatuses.includes(props.campagneModificationAbonne.etatValidation)) {
            emailFieldEditable = false;
        }

        //Si la campagne est rattachée à une coordonnée, l'email est modifié automatiquement (en Back) donc ne pas le modifier
        if (props.campagneModificationAbonne.campagneModificationCoordonneeId != null) {
            emailFieldEditable = false;
        }

        const formElementsColumn1: FormElement[] = [
            {
                type: emailFieldEditable ? "email" : "informations",
                label: "Email associé",
                fieldName: "email",
                modificators: "-d-block",
                showFieldErrorDetail: true,

                disabled: !emailFieldEditable,

                oldValue: props.campagneModificationAbonne.email
            }
        ];

        const formElementsColumn2: FormElement[] = [];

        if(!finishedStatuses.includes(props.campagneModificationAbonne.etatValidation)) {
            formElementsColumn2.push({
                type: "select",
                fieldName: "etat",
                label: "Souhaitez-vous abonner cette adresse au fil d’infos ?",
                modificators: "-on-white",
                options: CampagneModificationAbonnesConstants.etatsOptions,
                hideSearchOnMultiple: true,
                oldValue: props.campagneModificationAbonne.etat
            })
        } else {
            formElementsColumn2.push({
                type: "informations",
                label: "Inscription",
                fieldName: "etat",
                modificators: "-on-white -d-block",
                disabled: true,
                oldValue: props.campagneModificationAbonne.etatLibelle
            })
        }

        const currentFormColumns: FormColumn[] = [
            {
                elements: formElementsColumn1
            },
            {
                elements: formElementsColumn2
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaire
        const currentFormActions: FormActions[] = [];

        if(!finishedStatuses.includes(props.campagneModificationAbonne.etatValidation)) {
            currentFormActions.push({
                label: "Enregistrer",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: false,
                onAction: (formData) => {
                    onSaveAbonne(formData)
                }
            });
        }

        if(props.campagneModificationAbonne.etatValidation === "a-valider-par-chambre" && isAuthenticated && isAdmin) {
            currentFormActions.push({
                label: "Valider",
                modificators: "-primary",
                icon: "icon-check",
                hasLoading: false,
                onAction: () => {
                    onValidationAbonne()
                }
            });
        }

        setFormActions(currentFormActions);

    }, [props.campagneModificationAbonne]);

    const onValidationAbonne = () => {
        campagneModificationsAbonnesService.validation(props.campagneModificationAbonne.id).then((response) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: response.messages[0],
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des coordonnées.",
                    type: "danger"
                });
            }
        })
    }

    const onSaveAbonne = (formData: FormComponentFormData) => {
        campagneModificationsAbonnesService.update(props.campagneModificationAbonne.id,formData as ICampagneModificationAbonneUpdateFormData).then((response) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            if (props.onSuccessSubmit) {
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: response.messages[0],
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if (error.messages) {
                setErrorsMessage(error.messages);
            }
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification des coordonnées.",
                    type: "danger"
                });
            }
        });
    }

    return (
        
        <FormCreatorComponent
            formColumns={formColumns}
            formActions={formActions}
            modificatorsActions="-mt-20"
            errorFieldsMessages={errorFieldsMessages}
            errorMessages={errorMessages}
        />
    )
}