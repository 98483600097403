import {AppConstants} from "../Constants/AppConstants";

export default class StringHelper {


    /**
     * Permet de nettoyer une chaine de caractère en supprimant les accents et en convertissant en lowercase
     *
     * @param {string} string
     * @returns {string}
     */
    public cleanString = (string: string): string => {
        if(string == null){
            return '';
        }
        const cleanedString = string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        cleanedString.trim();

        return cleanedString;
    }

    /**
     * Permet de nettoyer/rendre fonctionnel l'HTML utilisé pour React-pdf
     * @param html
     */
    public cleanHtmlReactPdf = (html: string): string => {
        //Les balises figure ne sont pas (encore) prises en compte par React-pdf
        html = html.replaceAll("<figure", "<div");
        html = html.replaceAll("</figure>", "</div>");

        //Remplacer les icones (typos) par des images de ses icones (car ils ne sont pas interprétés par React-pdf-html)
        //html = html.replaceAll(/<i class="icon icon-([a-z-]+)"><\/i>/g, "<img class='picto' src='http://notaires-progiciel.test/assets/images/icone-actus.png'>");
        //html = html.replaceAll(/<i class="picto icon-([a-z-]+)"><\/i>/g, "<img class='picto -big' src='http://notaires-progiciel.test/assets/images/icone-actus.png'>");
        html = html.replace(/<\/?a[^>]*>/g, "");
        html = html.replaceAll(/<i class="icon icon-([a-z-]+)"><\/i>/g, "<img class='picto' src='"+AppConstants.serverUrl+"assets/pdf/pictos/$1.png'/><span>&nbsp;</span>");
        html = html.replaceAll(/<i class="picto icon-([a-z-]+)"><\/i>/g, "<img class='picto -big' src='"+AppConstants.serverUrl+"assets/pdf/pictos/$1.png'/>");

        return html;
    }

    /**
     * Slug une chaine de caractère
     * (Source : https://gist.github.com/codeguy/6684588)
     * @param text
     */
    public slugify = (text: string): string => {
        return text
            .toString()                           // Cast to string (optional)
            .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
            .toLowerCase()                  // Convert the string to lowercase letters
            .trim()                                  // Remove whitespace from both sides of a string (optional)
            .replace(/\s+/g, '-')            // Replace spaces with -
            .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
            .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }

    /**
     * Permet la suppression des accents non autorisées d'un string
     *
     * @param {string} text
     * @returns {string}
     */
    public static removeAccents = (text: string) : string => {
        const accents = [
            "Ă",
            "Ắ",
            "Ặ",
            "Ằ",
            "Ẳ",
            "Ẵ",
            "Ǎ",
            "Ấ",
            "Ậ",
            "Ầ",
            "Ẩ",
            "Ẫ",
            "Ǟ",
            "Ȧ",
            "Ǡ",
            "Ạ",
            "Ȁ",
            "Ả",
            "Ȃ",
            "Ā",
            "Ą",
            "Å",
            "Ǻ",
            "Ḁ",
            "Ⱥ",
            "Ã",
            "Ꜳ",
            "Æ",
            "Ǽ",
            "Ǣ",
            "Ꜵ",
            "Ꜷ",
            "Ꜹ",
            "Ꜻ",
            "Ꜽ",
            "Ḃ",
            "Ḅ",
            "Ɓ",
            "Ḇ",
            "Ƀ",
            "Ƃ",
            "Ć",
            "Č",
            "Ḉ",
            "Ĉ",
            "Ċ",
            "Ƈ",
            "Ȼ",
            "Ď",
            "Ḑ",
            "Ḓ",
            "Ḋ",
            "Ḍ",
            "Ɗ",
            "Ḏ",
            "ǲ",
            "ǅ",
            "Đ",
            "Ƌ",
            "Ǳ",
            "Ǆ",
            "Ĕ",
            "Ě",
            "Ȩ",
            "Ḝ",
            "Ế",
            "Ệ",
            "Ề",
            "Ể",
            "Ễ",
            "Ḙ",
            "Ė",
            "Ẹ",
            "Ȅ",
            "Ẻ",
            "Ȇ",
            "Ē",
            "Ḗ",
            "Ḕ",
            "Ę",
            "Ɇ",
            "Ẽ",
            "Ḛ",
            "Ꝫ",
            "Ḟ",
            "Ƒ",
            "Ǵ",
            "Ğ",
            "Ǧ",
            "Ģ",
            "Ĝ",
            "Ġ",
            "Ɠ",
            "Ḡ",
            "Ǥ",
            "Ḫ",
            "Ȟ",
            "Ḩ",
            "Ĥ",
            "Ⱨ",
            "Ḧ",
            "Ḣ",
            "Ḥ",
            "Ħ",
            "Í",
            "Ĭ",
            "Ǐ",
            "Ḯ",
            "İ",
            "Ị",
            "Ȉ",
            "Ỉ",
            "Ȋ",
            "Ī",
            "Į",
            "Ɨ",
            "Ĩ",
            "Ḭ",
            "Ꝺ",
            "Ꝼ",
            "Ᵹ",
            "Ꞃ",
            "Ꞅ",
            "Ꞇ",
            "Ꝭ",
            "Ĵ",
            "Ɉ",
            "Ḱ",
            "Ǩ",
            "Ķ",
            "Ⱪ",
            "Ꝃ",
            "Ḳ",
            "Ƙ",
            "Ḵ",
            "Ꝁ",
            "Ꝅ",
            "Ĺ",
            "Ƚ",
            "Ľ",
            "Ļ",
            "Ḽ",
            "Ḷ",
            "Ḹ",
            "Ⱡ",
            "Ꝉ",
            "Ḻ",
            "Ŀ",
            "Ɫ",
            "ǈ",
            "Ł",
            "Ǉ",
            "Ḿ",
            "Ṁ",
            "Ṃ",
            "Ɱ",
            "Ń",
            "Ň",
            "Ņ",
            "Ṋ",
            "Ṅ",
            "Ṇ",
            "Ǹ",
            "Ɲ",
            "Ṉ",
            "Ƞ",
            "ǋ",
            "Ñ",
            "Ǌ",
            "Ó",
            "Ŏ",
            "Ǒ",
            "Ố",
            "Ộ",
            "Ồ",
            "Ổ",
            "Ỗ",
            "Ȫ",
            "Ȯ",
            "Ȱ",
            "Ọ",
            "Ő",
            "Ȍ",
            "Ỏ",
            "Ơ",
            "Ớ",
            "Ợ",
            "Ờ",
            "Ở",
            "Ỡ",
            "Ȏ",
            "Ꝋ",
            "Ꝍ",
            "Ō",
            "Ṓ",
            "Ṑ",
            "Ɵ",
            "Ǫ",
            "Ǭ",
            "Ø",
            "Ǿ",
            "Õ",
            "Ṍ",
            "Ṏ",
            "Ȭ",
            "Ƣ",
            "Ꝏ",
            "Ɛ",
            "Ɔ",
            "Ȣ",
            "Ṕ",
            "Ṗ",
            "Ꝓ",
            "Ƥ",
            "Ꝕ",
            "Ᵽ",
            "Ꝑ",
            "Ꝙ",
            "Ꝗ",
            "Ŕ",
            "Ř",
            "Ŗ",
            "Ṙ",
            "Ṛ",
            "Ṝ",
            "Ȑ",
            "Ȓ",
            "Ṟ",
            "Ɍ",
            "Ɽ",
            "Ꜿ",
            "Ǝ",
            "Ś",
            "Ṥ",
            "Š",
            "Ṧ",
            "Ş",
            "Ŝ",
            "Ș",
            "Ṡ",
            "Ṣ",
            "Ṩ",
            "Ť",
            "Ţ",
            "Ṱ",
            "Ț",
            "Ⱦ",
            "Ṫ",
            "Ṭ",
            "Ƭ",
            "Ṯ",
            "Ʈ",
            "Ŧ",
            "Ɐ",
            "Ꞁ",
            "Ɯ",
            "Ʌ",
            "Ꜩ",
            "Ú",
            "Ŭ",
            "Ǔ",
            "Ṷ",
            "Ǘ",
            "Ǚ",
            "Ǜ",
            "Ǖ",
            "Ṳ",
            "Ụ",
            "Ű",
            "Ȕ",
            "Ủ",
            "Ư",
            "Ứ",
            "Ự",
            "Ừ",
            "Ử",
            "Ữ",
            "Ȗ",
            "Ū",
            "Ṻ",
            "Ų",
            "Ů",
            "Ũ",
            "Ṹ",
            "Ṵ",
            "Ꝟ",
            "Ṿ",
            "Ʋ",
            "Ṽ",
            "Ꝡ",
            "Ẃ",
            "Ŵ",
            "Ẅ",
            "Ẇ",
            "Ẉ",
            "Ẁ",
            "Ⱳ",
            "Ẍ",
            "Ẋ",
            "Ý",
            "Ŷ",
            "Ÿ",
            "Ẏ",
            "Ỵ",
            "Ỳ",
            "Ƴ",
            "Ỷ",
            "Ỿ",
            "Ȳ",
            "Ɏ",
            "Ỹ",
            "Ź",
            "Ž",
            "Ẑ",
            "Ⱬ",
            "Ż",
            "Ẓ",
            "Ȥ",
            "Ẕ",
            "Ƶ",
            "Ĳ",
            "Œ",
            "ᴀ",
            "ᴁ",
            "ʙ",
            "ᴃ",
            "ᴄ",
            "ᴅ",
            "ᴇ",
            "ꜰ",
            "ɢ",
            "ʛ",
            "ʜ",
            "ɪ",
            "ʁ",
            "ᴊ",
            "ᴋ",
            "ʟ",
            "ᴌ",
            "ᴍ",
            "ɴ",
            "ᴏ",
            "ɶ",
            "ᴐ",
            "ᴕ",
            "ᴘ",
            "ʀ",
            "ᴎ",
            "ᴙ",
            "ꜱ",
            "ᴛ",
            "ⱻ",
            "ᴚ",
            "ᴜ",
            "ᴠ",
            "ᴡ",
            "ʏ",
            "ᴢ",
            "á",
            "ă",
            "ắ",
            "ặ",
            "ẳ",
            "ẵ",
            "ǎ",
            "ấ",
            "ậ",
            "ầ",
            "ẩ",
            "ẫ",
            "ǟ",
            "ȧ",
            "ǡ",
            "ạ",
            "ȁ",
            "ả",
            "ȃ",
            "ā",
            "ą",
            "ᶏ",
            "ẚ",
            "å",
            "ǻ",
            "ḁ",
            "ⱥ",
            "ã",
            "ꜳ",
            "æ",
            "ǽ",
            "ǣ",
            "ꜵ",
            "ꜷ",
            "ꜹ",
            "ꜻ",
            "ꜽ",
            "ḃ",
            "ḅ",
            "ɓ",
            "ḇ",
            "ᵬ",
            "ᶀ",
            "ƀ",
            "ƃ",
            "ɵ",
            "ć",
            "č",
            "ḉ",
            "ĉ",
            "ɕ",
            "ċ",
            "ƈ",
            "ȼ",
            "ď",
            "ḑ",
            "ḓ",
            "ȡ",
            "ḋ",
            "ḍ",
            "ɗ",
            "ᶑ",
            "ḏ",
            "ᵭ",
            "ᶁ",
            "đ",
            "ɖ",
            "ƌ",
            "ı",
            "ȷ",
            "ɟ",
            "ʄ",
            "ǳ",
            "ǆ",
            "ĕ",
            "ě",
            "ȩ",
            "ḝ",
            "ế",
            "ệ",
            "ề",
            "ể",
            "ễ",
            "ḙ",
            "ė",
            "ẹ",
            "ȅ",
            "ẻ",
            "ȇ",
            "ē",
            "ḗ",
            "ḕ",
            "ⱸ",
            "ę",
            "ᶒ",
            "ɇ",
            "ẽ",
            "ḛ",
            "ꝫ",
            "ḟ",
            "ƒ",
            "ᵮ",
            "ᶂ",
            "ǵ",
            "ğ",
            "ǧ",
            "ģ",
            "ĝ",
            "ġ",
            "ɠ",
            "ḡ",
            "ᶃ",
            "ǥ",
            "ḫ",
            "ȟ",
            "ḩ",
            "ĥ",
            "ⱨ",
            "ḧ",
            "ḣ",
            "ḥ",
            "ɦ",
            "ẖ",
            "ħ",
            "ƕ",
            "í",
            "ĭ",
            "ǐ",
            "ḯ",
            "ị",
            "ȉ",
            "ỉ",
            "ȋ",
            "ī",
            "į",
            "ᶖ",
            "ɨ",
            "ĩ",
            "ḭ",
            "ꝺ",
            "ꝼ",
            "ᵹ",
            "ꞃ",
            "ꞅ",
            "ꞇ",
            "ꝭ",
            "ǰ",
            "ĵ",
            "ʝ",
            "ɉ",
            "ḱ",
            "ǩ",
            "ķ",
            "ⱪ",
            "ꝃ",
            "ḳ",
            "ƙ",
            "ḵ",
            "ᶄ",
            "ꝁ",
            "ꝅ",
            "ĺ",
            "ƚ",
            "ɬ",
            "ľ",
            "ļ",
            "ḽ",
            "ȴ",
            "ḷ",
            "ḹ",
            "ⱡ",
            "ꝉ",
            "ḻ",
            "ŀ",
            "ɫ",
            "ᶅ",
            "ɭ",
            "ł",
            "ǉ",
            "ſ",
            "ẜ",
            "ẛ",
            "ẝ",
            "ḿ",
            "ṁ",
            "ṃ",
            "ɱ",
            "ᵯ",
            "ᶆ",
            "ń",
            "ň",
            "ņ",
            "ṋ",
            "ȵ",
            "ṅ",
            "ṇ",
            "ǹ",
            "ɲ",
            "ṉ",
            "ƞ",
            "ᵰ",
            "ᶇ",
            "ɳ",
            "ñ",
            "ǌ",
            "ó",
            "ŏ",
            "ǒ",
            "ố",
            "ộ",
            "ồ",
            "ổ",
            "ỗ",
            "ȫ",
            "ȯ",
            "ȱ",
            "ọ",
            "ő",
            "ȍ",
            "ỏ",
            "ơ",
            "ớ",
            "ợ",
            "ờ",
            "ở",
            "ỡ",
            "ȏ",
            "ꝋ",
            "ꝍ",
            "ⱺ",
            "ō",
            "ṓ",
            "ṑ",
            "ǫ",
            "ǭ",
            "ø",
            "ǿ",
            "õ",
            "ṍ",
            "ṏ",
            "ȭ",
            "ƣ",
            "ꝏ",
            "ɛ",
            "ᶓ",
            "ɔ",
            "ᶗ",
            "ȣ",
            "ṕ",
            "ṗ",
            "ꝓ",
            "ƥ",
            "ᵱ",
            "ᶈ",
            "ꝕ",
            "ᵽ",
            "ꝑ",
            "ꝙ",
            "ʠ",
            "ɋ",
            "ꝗ",
            "ŕ",
            "ř",
            "ŗ",
            "ṙ",
            "ṛ",
            "ṝ",
            "ȑ",
            "ɾ",
            "ᵳ",
            "ȓ",
            "ṟ",
            "ɼ",
            "ᵲ",
            "ᶉ",
            "ɍ",
            "ɽ",
            "ↄ",
            "ꜿ",
            "ɘ",
            "ɿ",
            "ś",
            "ṥ",
            "š",
            "ṧ",
            "ş",
            "ŝ",
            "ș",
            "ṡ",
            "ṣ",
            "ṩ",
            "ʂ",
            "ᵴ",
            "ᶊ",
            "ȿ",
            "ɡ",
            "ᴑ",
            "ᴓ",
            "ᴝ",
            "ť",
            "ţ",
            "ṱ",
            "ț",
            "ȶ",
            "ẗ",
            "ⱦ",
            "ṫ",
            "ṭ",
            "ƭ",
            "ṯ",
            "ᵵ",
            "ƫ",
            "ʈ",
            "ŧ",
            "ᵺ",
            "ɐ",
            "ǝ",
            "ᵷ",
            "ɥ",
            "ʮ",
            "ʯ",
            "ᴉ",
            "ʞ",
            "ꞁ",
            "ɯ",
            "ɰ",
            "ɹ",
            "ɻ",
            "ɺ",
            "ⱹ",
            "ʇ",
            "ʌ",
            "ʍ",
            "ʎ",
            "ꜩ",
            "ú",
            "ŭ",
            "ǔ",
            "ṷ",
            "ǘ",
            "ǚ",
            "ǜ",
            "ǖ",
            "ṳ",
            "ụ",
            "ű",
            "ȕ",
            "ủ",
            "ư",
            "ứ",
            "ự",
            "ừ",
            "ử",
            "ữ",
            "ȗ",
            "ū",
            "ṻ",
            "ų",
            "ᶙ",
            "ů",
            "ũ",
            "ṹ",
            "ṵ",
            "ᵫ",
            "ꝸ",
            "ⱴ",
            "ꝟ",
            "ṿ",
            "ʋ",
            "ᶌ",
            "ⱱ",
            "ṽ",
            "ꝡ",
            "ẃ",
            "ŵ",
            "ẅ",
            "ẇ",
            "ẉ",
            "ẁ",
            "ⱳ",
            "ẘ",
            "ẍ",
            "ẋ",
            "ᶍ",
            "ý",
            "ŷ",
            "ÿ",
            "ẏ",
            "ỵ",
            "ỳ",
            "ƴ",
            "ỷ",
            "ỿ",
            "ȳ",
            "ẙ",
            "ɏ",
            "ỹ",
            "ź",
            "ž",
            "ẑ",
            "ʑ",
            "ⱬ",
            "ż",
            "ẓ",
            "ȥ",
            "ẕ",
            "ᵶ",
            "ᶎ",
            "ʐ",
            "ƶ",
            "ɀ",
            "ﬀ",
            "ﬃ",
            "ﬄ",
            "ﬁ",
            "ﬂ",
            "ĳ",
            "œ",
            "ﬆ",
            "ₐ",
            "ₑ",
            "ᵢ",
            "ⱼ",
            "ₒ",
            "ᵣ",
            "ᵤ",
            "ᵥ",
            "ₓ",
            "^z",
            "^r",
            "^t",
            "^y",
            "^p",
            "^q",
            "^s",
            "^d",
            "^f",
            "^g",
            "^h",
            "^j",
            "^k",
            "^l",
            "^m",
            "^n",
            "^w",
            "^x",
            "^c",
            "^v",
            "^b",
            "^n",
            "¨z",
            "¨r",
            "¨t",
            "¨y",
            "¨p",
            "¨q",
            "¨s",
            "¨d",
            "¨f",
            "¨g",
            "¨h",
            "¨j",
            "¨k",
            "¨l",
            "¨m",
            "¨n",
            "¨w",
            "¨x",
            "¨c",
            "¨v",
            "¨b",
            "¨n",
            "`z",
            "`r",
            "`t",
            "`y",
            "`p",
            "`q",
            "`s",
            "`d",
            "`f",
            "`g",
            "`h",
            "`j",
            "`k",
            "`l",
            "`m",
            "`n",
            "`w",
            "`x",
            "`c",
            "`v",
            "`b",
            "`n",
            "^Z",
            "^R",
            "^T",
            "^Y",
            "^P",
            "^Q",
            "^S",
            "^D",
            "^F",
            "^G",
            "^H",
            "^J",
            "^K",
            "^L",
            "^M",
            "^N",
            "^W",
            "^X",
            "^C",
            "^V",
            "^B",
            "^N",
            "¨Z",
            "¨R",
            "¨T",
            "¨Y",
            "¨P",
            "¨Q",
            "¨S",
            "¨D",
            "¨F",
            "¨G",
            "¨H",
            "¨J",
            "¨K",
            "¨L",
            "¨M",
            "¨N",
            "¨W",
            "¨X",
            "¨C",
            "¨V",
            "¨B",
            "¨N",
            "`Z",
            "`R",
            "`T",
            "`Y",
            "`P",
            "`Q",
            "`S",
            "`D",
            "`F",
            "`G",
            "`H",
            "`J",
            "`K",
            "`L",
            "`M",
            "`N",
            "`W",
            "`X",
            "`C",
            "`V",
            "`B",
            "`N",
        ];

        for (let i = 0; i < accents.length; i++) {
            text = text.replace(accents[i], '');
        }

        return text;
    }
}
