import {ICampagneModificationNegociateur} from "src/Models/CampagneModificationNegociateur.model";
import {IApiCustomResponse} from "src/Services/Api.service";
import {
    ICampagneModificationNegociateurFormData,
    CampagnesModificationsNegociateursService
} from "src/Services/CampagnesModificationsNegociateursService";
import AccordionSimpleComponent from "../../Accordions/AccordionSimple.component";
import React from "react";
import CampagneModificationNegociateurForm
    from "../../Forms/CampagneModificationNegociateur/CampagneModificationNegociateurForm";

type ICampagneModificationNegociateurFormContainerProps = {
    campagnesModificationsNegociateurs: ICampagneModificationNegociateur[],
    successSubmitForm: (response: IApiCustomResponse) => void,
    modificators?: string,
}


//"Container"/Groupement de formulaires de campagne de modification de négociateurs
export default function CampagneModificationNegociateursFormContainer(props: ICampagneModificationNegociateurFormContainerProps) {
    const campagnesModificationsNegociateurService: CampagnesModificationsNegociateursService = new CampagnesModificationsNegociateursService();

    return (
        <>
            {/*Afficher que s'il y a des négociateurs */}
            {props.campagnesModificationsNegociateurs.length > 0 &&
                <div className="campagne-form-wrapper">
                    <AccordionSimpleComponent
                        modificators={props.modificators != null ? props.modificators : '-no-padding-left'}
                        title={"Coordonnées des négociateurs"}
                        icon={"icon-negociateurs"}
                        contentShowedOnInit={true}>
                        {
                            props.campagnesModificationsNegociateurs.map((campagneModificationNegociateur, index) => {
                                return (
                                    <div className="campagne-form-coordinate" key={index}>
                                        <div className="informations-wrapper">
                                            <div className="columns">
                                                <div className="column">
                                                    <div className="informations-block">
                                                        <p className="label">Etat</p>
                                                        <p className="value -flex">
                                                             <span
                                                                 className={`state-bullet -before-element icon-bullet -${campagneModificationNegociateur.etat === 'a-confirmer-par-email' || campagneModificationNegociateur.etat === 'a-valider-par-chambre' ? 'a-faire' : campagneModificationNegociateur.etat === 'negociateur-remplace' || campagneModificationNegociateur.etat === 'validee-par-chambre' ? 'realisee' : ''}`}>&nbsp;</span>
                                                            {campagneModificationNegociateur.etatLabel}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <CampagneModificationNegociateurForm
                                            campagneModificationNegociateur={campagneModificationNegociateur}
                                            onSuccessSubmit={props.successSubmitForm}
                                            submitMethod={(formData: ICampagneModificationNegociateurFormData) => campagnesModificationsNegociateurService.update(campagneModificationNegociateur.id, formData)}/>
                                    </div>
                                )
                            })
                        }
                    </AccordionSimpleComponent>
                </div>
            }
        </>
    )
}