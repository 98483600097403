import ApiService, {IApiCustomResponse} from "./Api.service";
import {ICommune} from "../Models/Commune.model";

export type ICommuneGetFormData = {
    codePostal: string,
}

export type ICommunesGetApiResponse = IApiCustomResponse & {
    datas: {
        communes: ICommune[],
    }
}


export class CommunesServices extends ApiService {

    /**
     * Permet de récupérer les communes pour un code postal
     *
     * @param {IParametresGetFormData} formDatas
     * @returns {Promise<IParametresGetApiResponse>}
     */
    public getCommunesForCodePostal(formDatas: ICommuneGetFormData): Promise<ICommunesGetApiResponse>{
        const url: string = 'api/communes';

        return this.doApiCall(url, 'GET', formDatas);
    }
}




