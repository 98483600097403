import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {AppConstants, TabsSlugs} from "src/Constants/AppConstants";
import {IApiFieldsErrorMessages, IApiPaginationLink} from "src/Services/Api.service";
import {FormationsService} from "src/Services/Formations.service";
import useFormation from "src/Hooks/UseFormation";
import {IFormation} from "src/Models/Formation.model";
import TabsContainerComponent, {TabPanelComponent} from "../../../Components/Tabs/TabsContainer.component";
import {INotaireGetFormData, INotairesGetApiResponse, NotairesService} from "../../../Services/Notaires.service";
import TableHeaderButtonComponent from "../../../Components/Lists/TableHeaderButton.component";
import {INotaire} from "../../../Models/Notaire.model";
import NotairesHelper from "../../../Helpers/Notaires.helper";
import PaginationComponent from "../../../Components/Pagination/Pagination.component";
import useListFilters from "../../../Hooks/FiltersHandler";
import {IPersonneExterieure} from "../../../Models/PersonneExterieure.model";
import {AutresContactsService,IAutresContactsGetFormData, IPersonnesExterieuresGetApiResponse} from "../../../Services/AutresContacts.service";
import SearchBlockComponent, {IFormSearchData} from "../../../Components/Search/SearchBlock.component";
import ConfirmationComponent from "../../../Components/Confirmation/Confirmation.component";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import {FormColumn, FormComponentFormData} from "../../../Components/Forms/FormCreator.component";
import RepeaterFieldComponent from "../../../Components/Fields/Repeater.field.component";
import {IFormationParticipant} from "../../../Models/FormationParticipant.model";
import {Link} from "react-router-dom";
import {toInteger} from "lodash";

const FormationAjoutInscriptionScreen = (props: {
    fid: number
}) => {
    const [getFormationById] = useFormation();
    const [filters,initialFiltersFromUrlQuery,updateFilters,setOrder,setPath,defaultFilters] = useListFilters(`/formations/${props.fid}/inscription`, false);
    const [notaires, setNotaires] = useState<INotaire[]>([]);
    const [personnes, setPersonnes] = useState<IPersonneExterieure[]>([]);
    const [formation, setFormation] = useState<IFormation>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>(TabsSlugs.NOTAIRES);
    const [paginationLinks, setPaginationLinks] = useState<IApiPaginationLink[]>([]);
    const [keyword,setKeyword] = useState<string>("");
    const [fieldsErrors,setFieldsErrors] = useState<IApiFieldsErrorMessages>(null);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);

    const [showConfirmation,setShowConfirmation] = useState<boolean>(false);

    const [addedNotaires,setAddedNotaires] = useState<number[]>([]);
    const [addedPersonnes,setAddedPersonnes] = useState<number[]>([]);
    const [addedCollaborateurs, setAddedCollaborateurs] = useState<FormComponentFormData>({});

    const history = useHistory();

    const formationsService: FormationsService = new FormationsService();
    const notairesService: NotairesService = new NotairesService();
    const personneExterieuresService: AutresContactsService = new AutresContactsService();

    /**
     * Récupération des paramètres de l'URL
     * Et préparation de la valeur par défault des formDatas pour la récupération de la lsite
     */
    useEffect(() => {
        updateFilters(defaultFilters);
    }, []);

    useEffect(() => {
        if (!filters) return null;
        launchFilteredSearch();
    }, [filters]);


    useEffect(() => {
        if (!props.fid) {
            history.push(AppConstants.path403);
        }
        getFormation();
    }, [props.fid]);


    /**
     * Lancement de la recherche
     */
    const launchFilteredSearch = (): void => {
        if (!filters) return;

        if (activeTab === TabsSlugs.NOTAIRES) {
            notairesService.getNotairesWithPaginate(filters as INotaireGetFormData).then((response: INotairesGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // Notaires
                    const currentNotairesList: INotaire[] = response.datas.pagination.data;
                    setNotaires(currentNotairesList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }

        if (activeTab === TabsSlugs.EXTERIEURS) {
            personneExterieuresService.getPersonnesExterieures(filters as IAutresContactsGetFormData).then((response: IPersonnesExterieuresGetApiResponse) => {
                if (response && response.datas.pagination) {

                    // exterieurs
                    const currentAutresContactsList: IPersonneExterieure[] = response.datas.pagination.data as IPersonneExterieure[];
                    setPersonnes(currentAutresContactsList);

                    // Pagination
                    setPaginationLinks(response.datas.pagination.links);

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            });
        }
    };


    /**
     * Récupération de la formation selon l'uid
     */
    const getFormation = (): void => {
        getFormationById(props.fid, (newFormation: IFormation) => {
            setFormation(newFormation);
            if (!loaded) {
                setLoaded(true);
            }

            generateFormColumns(newFormation);
        });
    };

    const generateFormColumns = (newFormation: IFormation): void => {
        const formColumns1: FormColumn = {
            elements: [
                {
                    type: 'select',
                    fieldName: "civiliteCourte",

                    label: "Civilite",
                    placeholder: "Civilite",
                    required: true,
                    modificators: "-on-white",

                    options: AppConstants.civiliteCourteOptions,
                },
                {
                    type: 'text',
                    fieldName: "nom",
                    label: "Nom",
                    oldValue : "",
                    modificators: "-on-white"
                },
                {
                    type: 'text',
                    fieldName: "prenom",
                    label: "Prénom",
                    oldValue : "",
                    modificators: "-on-white"
                },
            ]
        };
        const formColumns2: FormColumn = {
            elements: [
                {
                    type: 'text',
                    fieldName: "crpcen",
                    label: "CRPCEN",
                    oldValue : "",
                    modificators: "-on-white"
                },
                {
                    type: 'email',
                    fieldName: "email",
                    label: "Email",
                    oldValue : "",
                    modificators: "-on-white",
                    showFieldErrorDetail: true,
                },
            ]
        };
        const formColumns3: FormColumn = {
            elements: []
        }

        const newFormColumns: FormColumn[] = [formColumns1,formColumns2,formColumns3];
        setFormColumns(newFormColumns);
    }


    /**
     * Retourne le mail du notaire ou de la personne extérieure
     *
     * @param {INotaire | IPersonneExterieure} person
     * @returns {string}
     */
    const getEmail = (person: INotaire | IPersonneExterieure) => {
        let email = "";

        if (person.coordonnees) {
            person.coordonnees.every((c) => {
                if (c.type === "email" && c.destination === "convocation") {
                    email = c.coordonnees;
                    return false;
                }
            });
            if(email.trim() === "") {
                person.coordonnees.every((c) => {
                    if (c.type === "email" && c.destination === "principal") {
                        email = c.coordonnees;
                        return false;
                    }
                });
            }
            if(email.trim() === "") {
                person.coordonnees.every((c) => {
                    if (c.type === "email" && c.destination === "perso") {
                        email = c.coordonnees;
                        return false;
                    }
                });
            }
        }

        return email.trim();
    };

    /**
     * Lancement de la recherche par mot clé
     *
     * @param {IFormSearchData} formDatas
     */
    const onSearch = (formDatas: IFormSearchData) => {
        const searchedKeyword = formDatas.keyword as string;
        setKeyword(searchedKeyword);
        updateFilters({keyword: searchedKeyword});
    };


    /**
     * Indique si un notaire ou une personne extérieure est déjà inscrit.e
     *
     * @param {INotaire | IPersonneExterieure} person
     * @param {IFormationParticipant[]} inscrits
     * @param {string} type
     * @returns {boolean}
     */
    const isAlreadySubscribed = (person: INotaire | IPersonneExterieure, inscrits: IFormationParticipant[], type?: string): boolean => {
        return inscrits.findIndex((i)=>{
            let entity = null;

            if(type){
               if(type === "notaire"){
                   entity = i.notaire;
               }
               if(type === "personne"){
                    entity = i.personneExterieure
               }
            }
            else{
                entity = i.notaire || i.personneExterieure || null;
            }

            return entity && entity.id === person.id;
        }) !== -1;
    };


    /**
     * Indique si un notaire ou une personne extérieure est ajouté.e
     *
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */
    const isAdded = (person: INotaire | IPersonneExterieure): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            return addedNotaires.findIndex((n)=>n === person.id) !== -1;
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            return addedPersonnes.findIndex((p)=>p === person.id) !== -1;
        }
        return false;
    };

    /**
     * Ajoute/retire une personne des ajouts
     *
     * @param {INotaire | IPersonneExterieure} person
     * @returns {boolean}
     */
    const toggleAdded = (person: INotaire | IPersonneExterieure): boolean => {
        if(activeTab === TabsSlugs.NOTAIRES ){
            if(isAdded(person)){
                setAddedNotaires(addedNotaires.filter((n)=>n !== person.id));
            }
            else{
                const newNotaires: number[] = [...addedNotaires];
                newNotaires.push(person.id);
                setAddedNotaires(newNotaires);
            }
        }
        else if(activeTab === TabsSlugs.EXTERIEURS ){
            if(isAdded(person)){
                setAddedPersonnes(addedPersonnes.filter((p)=>p !== person.id));
            }
            else{
                const newPersonnes: number[] = [...addedPersonnes];
                newPersonnes.push(person.id);
                setAddedPersonnes(newPersonnes);
            }
        }
        return false;
    };


    /**
     * Soumission des inscriptions
     */
    const onSubmit = () => {
        const datas:{[key:string]:any} = {
            notaires: addedNotaires,
            personnes: addedPersonnes,
        };
        if(addedCollaborateurs){
            for(let [k,v] of Object.entries(addedCollaborateurs)){
                datas[k] = v;
            }
        }

        formationsService.saveInscriptions(props.fid,datas).then((r)=>{
            let message = "Les inscriptions ont bien été enregistrées.";
            if(formation.inscrits.length >= toInteger(formation.nbMaxParticipants)){
                message = "Les inscriptions sur la liste d'attente ont bien été enregistrées."
            }

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: message,
                type: "success"
            });
            goToFormation();
        },(r)=>{
            if(r.fieldsMessages){
                setFieldsErrors(r.fieldsMessages);
            }
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue.",
                type: "danger"
            });
        });
    };

    const onCollaborateursAdded = (values: FormComponentFormData) => {
        setAddedCollaborateurs(values);
    };

    /**
     * Retour à la formation
     */
    const goToFormation = () => {
        history.push(AppConstants.pathFormations+'/'+props.fid);
    };

    const onTabClick = (tab:string) => {
        setActiveTab(tab);
        updateFilters({},true);
        setKeyword("");
        setAddedPersonnes([]);
        setAddedNotaires([]);
        setAddedCollaborateurs(null);
    };

    return (
        <>
            {
                loaded &&
				<LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
				                 contentModificators={`-bg-white -no-flex`}>
					<PageHeaderComponent text="Formation" subText={"Ajouter une inscription"}
					                     icon="icon-add-inscription"
					                     modificators={`-in-fullscreen -border-full-screen`}/>

					<TabsContainerComponent onClickTabCallback={onTabClick}>
						<TabPanelComponent label="Notaires" icon="icon-notaires" slug={TabsSlugs.NOTAIRES}>

							<div className="list-filters -margin-top">
								<SearchBlockComponent placeholder={"Recherche"} keyword={keyword} onSubmit={onSearch} modificators="-searchpage -list-header"/>
							</div>

							<table className={`list-table`}>
								<thead className={"headers"}>
								<tr>
                                    {[
                                        {slug: "crpcen", noSort: false},
                                        {slug: "nom", noSort: false},
                                        {slug: "type", noSort: false},
                                        {slug: "office", noSort: false},
                                        {slug: "ville", noSort: false},
                                        {slug: "email", noSort: true},
                                        {slug: "actions", noSort: true}
                                    ].map((item, iIndex) =>
                                        <th
                                            className="th -center" key={iIndex}>
                                            <TableHeaderButtonComponent
                                                direction={filters.orderType as string || AppConstants.orderType.asc}
                                                value={item.slug} selectedValue={filters.orderBy as string || ""}
                                                label={item.slug} click={(value: string) => setOrder(value)}
                                                noSort={item.noSort}
                                            />
                                        </th>)}
								</tr>
								</thead>
								<tbody>
                                {
                                    notaires && notaires.map((notaire: INotaire, index: number) => {
                                        const subscribed = isAlreadySubscribed(notaire,formation.inscrits, "notaire");
                                        return <tr key={index} className={`tr ${subscribed ? '-disabled' : ''}`}>
                                            <td className="td -bold">
                                                {notaire.office && <>{notaire.office.CRPCEN}</>}
                                            </td>
                                            <td className="td">
                                                <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathNotaires}/${notaire.id}`} onClick={(e) => e.stopPropagation()}>{notaire.nom} {notaire.prenom}</Link>
                                            </td>
                                            <td className="td">
                                                {NotairesHelper.getFormattedType(notaire.type)}
                                            </td>
                                            <td className="td">
                                                {
                                                    notaire.office &&
                                                    <Link className="g-link -primary -underline-on-hover -bold" to={`${AppConstants.pathOffices}/${notaire.office.id}`} onClick={(e) => e.stopPropagation()}>{notaire.office.nom}</Link>
                                                }
                                            </td>
                                            <td className="td -uppercase">{notaire.adressePrincipale && <>{notaire.adressePrincipale.ville}</>}</td>
                                            <td className="td">{getEmail(notaire) || '-'}</td>
                                            <td className="td -center" width={80}>
                                                {!subscribed && isAdded(notaire) && <button className="g-button -delete-button -with-icon" onClick={() => toggleAdded(notaire)}><em className="picto icon-remove"></em><span className="text">Annuler</span> </button>}
                                                {!subscribed && !isAdded(notaire) && <button className="g-button -is-link" onClick={() => toggleAdded(notaire)}>Ajouter</button>}
                                                {subscribed && <span>Déjà inscrit.e</span>}
                                            </td>
                                        </tr>;
                                    })
                                }
								</tbody>
							</table>

							<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>
						</TabPanelComponent>


						<TabPanelComponent label="Collaborateurs" icon="icon-collaborateurs" slug={TabsSlugs.COLLABORATEURS}>
							<div className={`form-wrapper -margin-top`}>
                                <RepeaterFieldComponent fieldName="collaborateurs" errorFieldsMessages={fieldsErrors} formColumns={formColumns} onChange={onCollaborateursAdded} oldValues={[]} />
							</div>
						</TabPanelComponent>


						<TabPanelComponent label="Extérieurs" icon="icon-autres-contacts" slug={TabsSlugs.EXTERIEURS}>

							<div className="list-filters -margin-top">
								<SearchBlockComponent placeholder={"Recherche"} keyword={keyword} onSubmit={onSearch} modificators="-searchpage -list-header"/>
							</div>

							<table className={`list-table`}>
								<thead className={"headers"}>
								<tr>
                                    {[
                                        {slug: "nom", noSort: false},
                                        {slug: "fonction", noSort: false},
                                        {slug: "email", noSort: true},
                                        {slug: "actions", noSort: true}
                                    ].map((item, iIndex) => <th
                                        className="th" key={iIndex}>
                                        <TableHeaderButtonComponent
                                            direction={filters.orderType as string || AppConstants.orderType.asc}
                                            value={item.slug} selectedValue={filters.orderBy as string || ""}
                                            noSort={item.noSort}
                                            label={item.slug} click={(value: string) => setOrder(value)}/>
                                    </th>)}
								</tr>
								</thead>
								<tbody>
                                {
                                    personnes && personnes.map((personne: IPersonneExterieure, index: number) =>{
                                        const subscribed = isAlreadySubscribed(personne,formation.inscrits, "personne");
                                        return <tr key={index} className={`tr ${subscribed ? '-disabled' : ''}`}>
                                            <td className="td">
                                                {personne.nom} {personne.prenom}
                                            </td>
                                            <td className="td">
                                                {personne.fonction}
                                            </td>
                                            <td className="td">{getEmail(personne) || '-'}</td>
                                            <td className="td -center" width={80}>
                                                {!subscribed && isAdded(personne) && <button className="g-button -delete-button -with-icon" onClick={() => toggleAdded(personne)}><em className="picto icon-remove"></em><span className="text">Annuler</span></button>}
                                                {!subscribed && !isAdded(personne) && <button className="g-button -is-link" onClick={() => toggleAdded(personne)}>Ajouter</button>}
                                                {subscribed && <span>Déjà inscrit.e</span>}
                                            </td>
                                        </tr>;
                                    })
                                }
								</tbody>
							</table>

							<PaginationComponent links={paginationLinks} onPageClick={page => updateFilters({page})}/>
						</TabPanelComponent>
					</TabsContainerComponent>
                    <div className="form-actions -outside-right">
                        {showConfirmation && <ConfirmationComponent text={"Quitter sans enregistrer les inscriptions ?"} onConfirm={()=>goToFormation()} onCancel={()=>setShowConfirmation(false)}/>}
	                    <button className="g-button -secondary-border-only" onClick={()=>setShowConfirmation(true)}>
		                    Quitter sans enregistrer
                        </button>
	                    <button className="g-button -secondary" onClick={onSubmit}>
		                    Enregistrer
                        </button>
                    </div>
				</LayoutComponent>
            }
        </>
    )
};

export default FormationAjoutInscriptionScreen;
