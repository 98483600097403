import React, {useEffect, useState} from "react";
import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import InformationHeaderComponent from "src/Components/Informations/InformationHeader.component";
import {
    FilInformationsService, IFilInformationsAbonneFormData, IFilInformationsDetailsGetApiResponse,
} from "src/Services/FilInformations.service";
import FormCreatorComponent, {
    FormActions,
    FormColumn,
    FormComponentFormData,
    FormElement
} from "../../Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "../../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../Shared/config";
import {AppConstants} from "../../Constants/AppConstants";
import {useHistory} from "react-router";
import CommonHelper from "../../Helpers/Common.helper";
import {ReactComponent as Logo} from "src/assets/images/logo-notaires-cours-appel-caen.svg";

type IFilInformationsMiseAJourInscriptionExternalStepsScreenProps = {
    email: string,
};

/**
 * Écran pour les abonnés au fil d'info pour valider/modifier leurs adresses mails du fil d'info
 * v1 : 02/10/2024 : abonne-octobre-2024
 *
 * Enregistrement des nouveaux mails en tant que nouveaux abonnés avec l'état "abonne-octobre-2024"
 *
 * voir specs : https://docs.google.com/document/d/1YLEV2equUTUS32_I9NazZp2kDGxHaLQojOg-5TN4Rjo/edit
 * @param props
 * @constructor
 */
const FilInformationsMiseAJourInscriptionExternalScreen = (props: IFilInformationsMiseAJourInscriptionExternalStepsScreenProps) => {
    const filInformationsService: FilInformationsService = new FilInformationsService();

    const history = useHistory();

    const [status, setStatus] = useState<'not-submitted' | 'submitted' | 'already-submitted'>(null);
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, seterrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);

    const etat = "abonne-octobre-2024";//v1 : 02/10/2024

    useEffect(() => {
        //Vérification de l'email présent en paramètre, sinon redirection
        if (!props.email || props.email.trim() === "" || !CommonHelper.validateEmail(props.email)) {
            history.replace(AppConstants.path403);
        }

        verifierAbonnePresent();

    }, [props.email]);

    useEffect(() => {
        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "ancienEmail",

                label: "Ancien email (informations)",
                //modificators: "-on-white",

                disabled: true,
                //Pré-remplissage de l'email (à partir de l'email passé en paramètre)
                oldValue: props.email,
            },


            {
                type: 'text',
                fieldName: "email",

                label: "Nouvel email (à vérifier/modifier)",
                placeholder: "",
                modificators: "-on-white",

                required: true,
                showFieldErrorDetail: true,
                //Pré-remplissage de l'email (à partir de l'email passé en paramètre)
                oldValue: props.email,

                showMentions: true,
                textMention: "Si votre adresse email est correcte, vous pouvez soumettre le formulaire ci-dessous pour confirmer vos informations.",
            },


            {
                type: 'mention',
                label: "Si votre adresse email est correcte, vous pouvez simplement soumettre le formulaire pour confirmer vos informations.",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            }
        ];
        setFormColumns(currentFormColumns);


        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Enregistrer",
                modificators: "-primary",
                onAction: (formData) => {
                    onSubmit(formData);
                }
            },
        ];
        setFormActions(currentFormActions);


    }, []);

    const verifierAbonnePresent = () => {
        const formDatas: IFilInformationsAbonneFormData = {
            email: props.email,
            etat: etat,
            nom: "",
            onlyCheck: true,
        };

        //Vérification de l'abonné (si l'email en paramètre est déjà présent pour un abonné pour l'état défini)
        //On utilise l'API d'ajout avec le paramètre onlyCheck
        //Exemple de cas où ça peut se produire, si l'utilisateur a soumis le formulaire sans changer l'email
        filInformationsService.abonneExternal(formDatas)
            .then(() => {
                //Si l'abonné n'existe pas : on affiche le formulaire
                setStatus('not-submitted');
            })
            .catch(() => {
                //Si l'abonné existe déjà : on affiche un message/texte sans formulaire
                setStatus('already-submitted');
            });
    };

    /**
     * Soumission du formulaire
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} goNextStep
     */
    const onSubmit = (formData: FormComponentFormData): void => {
        //Ajout de l'état lors de la création de l'abonné
        formData.etat = etat;

        //Retirer l'ancien email
        delete formData.ancienEmail;

        filInformationsService.abonneExternal(formData as IFilInformationsAbonneFormData).then((response: IFilInformationsDetailsGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            seterrorFieldsMessages(null);

            setStatus('submitted');

            //Suite à la soumission du formulaire, on redirige l'utilisateur (en modifiant l'email dans l'url)
            const newPath = history.location.pathname.replace(props.email, formData.email);
            history.replace(newPath);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Votre adresse email a bien été mise à jour !",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            setErrorsMessage(["Vous avez déjà effectué la mise à jour et votre nouvel email est déjà enregistré ou le formulaire contient des erreurs"]);
            if (error.fieldsMessages) {
                seterrorFieldsMessages(error.fieldsMessages);
            }

            if (!error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue.",
                    type: "danger"
                });
            }
        });
    };


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                         contentModificators={`-bg-white -no-flex`}>
            <PageHeaderComponent
                text="Mettez à jour votre adresse email pour rester connecté(e) à notre communauté de notaires"
                icon="icon-fil-infos" logo={true}
                modificators={`-in-fullscreen -border-full-screen`}/>

            {/*Suite à la soumission du formulaire, si tout s'est bien passé (ou que l'utilisateur a déjà soumis le formulaire), afficher un message*/}
            {
                (status === 'submitted' || status === 'already-submitted') &&
                <>
                    <InformationHeaderComponent icon={"icon-informations"}
                                                title={"Votre adresse email a bien été mise à jour !"}/>

                    <div className="informations-block">
                        <p>
                            Merci d'avoir mis à jour vos informations. Vous faites désormais partie intégrante de notre
                            communauté de notaires avec votre nouvelle adresse.
                        </p>
                        <p>
                            Vous continuerez à recevoir :
                        </p>
                        <ul>
                            <li>
                                Les actualités importantes sur la profession notariale.
                            </li>
                            <li>
                                Les informations exclusives et les bulletins réservés à nos membres.
                            </li>
                            <li>
                                Les invitations à nos événements et formations dédiés aux notaires.
                            </li>
                        </ul>
                        <p>
                            Si vous avez des questions ou besoin d'assistance supplémentaire, n'hésitez pas à nous
                            contacter à <a
                            href={`mailto:${AppConstants.emailContactDefaut}`}>{AppConstants.emailContactDefaut}</a>.
                        </p>
                        <p>
                            Nous vous remercions pour votre confiance et votre engagement au sein de notre réseau
                            professionnel.
                        </p>
                    </div>
                </>
            }

            {/*Afficher le formulaire pour modifier son adresse mail*/}
            {
                status === 'not-submitted' &&
                <>
                    <InformationHeaderComponent icon={"icon-informations"} title={"Informations"}/>

                    <div className="informations-block">
                        <p>
                            En tant que notaire, il est essentiel de rester informé(e) des dernières actualités
                            professionnelles, réglementaires et législatives qui impactent notre métier.
                        </p>
                        <p>
                            Pour continuer à recevoir nos communications officielles et accéder aux informations clés
                            réservées à notre communauté, veuillez mettre à jour votre adresse email ci-dessous. <br/>
                        </p>
                        <p>
                            Cela vous permettra de :
                        </p>
                        <ul>
                            <li>
                                <strong>Recevoir les mises à jour importantes</strong> sur les changements juridiques et
                                réglementaires.
                            </li>
                            <li>
                                <strong>Accéder à des contenus exclusifs</strong> dédiés aux notaires, tels que des
                                analyses,
                                invitations
                                et bulletins professionnels.
                            </li>
                            <li>
                                <strong>Assurer la sécurité et la confidentialité</strong> de vos informations
                                personnelles et
                                professionnelles.
                            </li>
                        </ul>
                        <p>
                            Merci de votre confiance et de faire partie de notre réseau de notaires.
                        </p>
                    </div>

                    <InformationHeaderComponent icon={"icon-fil-infos-add"} title={"Saisie"}
                                                modificators="-margin-bottom"/>

                    <div className="form-wrapper -edit">
                        <FormCreatorComponent
                            formColumns={formColumns}
                            formActions={formActions}
                            errorFieldsMessages={errorFieldsMessages}
                            errorMessages={errorMessages}/>
                    </div>
                </>
            }
        </LayoutComponent>
    )
}

export default FilInformationsMiseAJourInscriptionExternalScreen;
