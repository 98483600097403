import ApiService, {IApiCustomResponse, IExportApiResponse} from "./Api.service";
import { ICampagnesModificationsGetApiResponse } from "./CampagnesModifications.service";
import {
    ICampagneModificationOfficeVerificationFormData
} from "./CampagnesModificationsOffices.service";
import {ICampagneModificationCoordonnee} from "../Models/CampagneModificationCoordonnee.model";

export type ICampagneModificationCoordonneeFormData = {
    ancienne_valeur?: string,
    notaire_name?: string,
    nouvelle_valeur: string,
}

export type ICampagneModificationCoordonneeVerificationFormData = {
    code: string,
}

export type ICampagneModificationCoordonneeGetApiResponse = IApiCustomResponse & {
    datas?: {
        campagneModificationCoordonnee: ICampagneModificationCoordonnee
    }
}

export type ICampagnesModificationsCoordonneesGetApiResponse = IApiCustomResponse & {
    datas?: {
        campagnesModificationsCoordonnees: ICampagneModificationCoordonnee[]
    }
}

export class CampagneModificationCoordonneesService extends ApiService {
    private baseRoute = 'api/annuaire/campagnes-modifications-coordonnees';
    private baseRouteExternal = 'api/external/campagnes-modifications-coordonnees';

    /**
     * Permet la mise a jour d'une campagne de modification de coordonnée
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public update(id: number, data: ICampagneModificationCoordonneeFormData): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/update/${id}`, 'POST', data);
    }

    /**
     * Permet de passer une campagne de modification de coordonnée en statut/etat "a-supprimer-par-chambre"
     */
    public setStateToDelete(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/set-state-to-delete/${id}`, 'POST');
    }

    /**
     * Permet de confirmer le changement de coordonnée
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public verification(token: string): Promise<ICampagnesModificationsGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/confirmation/${token}`, 'POST');
    }

    /**
     * Permet de valider le changement de coordonnée (statut: validee-par-chambre)
     *
     * @returns {Promise<ICampagnesModificationsGetApiResponse>}
     */
    public validation(id: number): Promise<ICampagnesModificationsGetApiResponse> {
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/validation/${id}`, 'POST');
    }

    /**
     * Permet l'export des campagnes de modifications coordonnées liées à une campagne de modification (excel)
     * @param {string} cmid
     */
    public exportCampagnesModificationsCoordonneesByCampagneModification(cmid: string): Promise<IExportApiResponse>{
        //Url pour utilisateur connecté
        return this.doApiCall(`${this.baseRoute}/export-par-campagne-modification/${cmid}`, 'POST');
    }

    //////////////////////////////////////////

    public getCampagneModificationCoordonneeByToken(token: string): Promise<ICampagneModificationCoordonneeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}`, 'GET');
    }

    /**
     * Permet de récupérer les campagnes de modifications coordonnées liées à un token
     * dans tous les cas, c'est un tableau même s'il n'y a qu'un seul élément
     * Ex avec plusieurs campagnes : un notaire qui a plusieurs coordonnées (principal, convocation, etc.)
     *
     * Même s'il y a un seul élément, l'API retourne un tableau
     *
     * @param token
     */
    public getCampagnesModificationsCoordonneesByToken(token: string): Promise<ICampagnesModificationsCoordonneesGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}`, 'GET');
    }

    public sendCode(token: string): Promise<ICampagneModificationCoordonneeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}/send-code`, 'POST');
    }

    public checkCode(token: string, formDatas: ICampagneModificationOfficeVerificationFormData): Promise<ICampagneModificationCoordonneeGetApiResponse> {
        //Url sans utilisateur connecté
        return this.doApiCall(`${this.baseRouteExternal}/${token}/check-code`, 'POST', formDatas);
    }
}