import {ILettreType} from "src/Models/LettreType.model";
import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages, IFileDownloadApiResponse} from "src/Services/Api.service";
import {LettreTypesService, ILettreTypeGetApiResponse, ILettreTypesFormData,} from "src/Services/LettreTypes.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import {ISignatureGetAllApiResponse, SignaturesService} from "src/Services/Signatures.service";
import {ISignature} from "src/Models/Signature.model";
import SignaturesHelper from "src/Helpers/Signatures.helper";
import {IInspectionCourrierFormData} from "../../../../Services/Inspections.service";
import {FormationsService, IFormationSendUrssafCourrierFormData} from "../../../../Services/Formations.service";

type IFormationCourrierUrssafFormProps = {
    lettreType: ILettreType
}

export default function FormationCourrierUrssafFormComponent(props: IFormationCourrierUrssafFormProps){
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const lettreTypesService: LettreTypesService = new LettreTypesService();
    const [signatures, setSignatures] = useState<ISignature[]>(null);
    const [signaturesLoaded, setSignaturesLoaded] = useState<boolean>(false);


    useEffect(() => {
        const signaturesService: SignaturesService = new SignaturesService();
        signaturesService.getAll().then((response: ISignatureGetAllApiResponse) => {
            if( response && response.datas && response.datas.signatures){
                setSignatures(response.datas.signatures);
                setSignaturesLoaded(true);
            }
        });

    }, []);



    useEffect(()=> {
        const {lettreType} = props;

        const formElementsColumn1: FormElement[] = [
            {
                type: "mention",
                label: "Les modifications du courrier doivent être enregistrées avant que le courrier soit envoyé."
            },
            {
                type: "mention",
                label: "Les notaires qui recevront ce courrier seront ceux présents dans le groupe (Annuaire) : Notaires - Courrier URSSAF annuel (année courante).",
            },
            {
                type: 'hidden',
                fieldName: "courrierSignatureId",

                label: "Signature du courrier",
                placeholder: "Signature du courrier",
                required: true,
                modificators: "-on-white -hidden",
                options: SignaturesHelper.formatListForOptions(signatures),

                oldValue: lettreType.courrierSignatureId ? lettreType.courrierSignatureId.toString() : "",
            },
            {
                type: 'hidden',
                fieldName: "modele",

                label: "Contenu",
                placeholder: "Contenu",
                required: false,
                modificators: "-wysiwyg -on-white -hidden",

                oldValue: lettreType.modele ?? "",
            },
            {
                type: 'text',
                fieldName: "sujet",

                label: "Sujet",
                placeholder: "Sujet",
                required: false,
                modificators: " -on-white",

                oldValue: lettreType.sujet ?? "",
            },
            {
                type: 'wysiwyg',
                fieldName: "contenuMail",

                label: "Contenu du mail",
                placeholder: "Contenu du mail",
                required: false,
                modificators: "-wysiwyg -on-white",

                oldValue: lettreType.contenuMail ?? "",
            },
            {
                type: "mention",
                label: "Les modifications du courrier doivent être enregistrées avant que le courrier soit envoyé."
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];

        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Enregistrer",
                modificators: "-primary",
                icon: "icon-sauvegarde",
                hasLoading: false,
                onAction: (formData) => {
                    onSaveCourrier(formData)
                }
            },


            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, false, onActionEnded)}
            },
            {
                label: "Envoyer un test",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, true, onActionEnded)}
            }

        ];
        setFormActions(currentFormActions);
    }, [signatures, signaturesLoaded]);


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isTest
     * @param {() => void} onActionEnded
     */
    const onSend = (formData: FormComponentFormData, isTest?: boolean, onActionEnded?: ()=>void): void => {
        const formDataUpdated: IFormationSendUrssafCourrierFormData = {
            isTest: isTest || false
        }

        const formationsService: FormationsService = new FormationsService();
        formationsService.sendUrssafCourrier(formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });
        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                setErrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue lors de l'envoi du courrier.",
                    type: "danger"
                });
            }
        });
    }







    /**
     * Enregistrement du courrier en base de données
     * @param {FormComponentFormData} formData
     */
    const onSaveCourrier = (formData: FormComponentFormData): void => {
        const formDataUpdated: ILettreTypesFormData = {
            ...formData as ILettreTypesFormData
        }

        lettreTypesService.updateType(props.lettreType.id, formDataUpdated).then((response: ILettreTypeGetApiResponse) => {
            //On reset les erreurs
            setErrorsMessage(null);
            setErrorFieldsMessages(null);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le modèle de courrier a bien été mis à jour.",
                type: "success"
            });

        }, (error: IApiCustomResponse) => {
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                setErrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de la modification de la lettre.",
                    type: "danger"
                });
            }
        });
    }


    return (
        <div className={`g-content ${signaturesLoaded ? 'border-footer' : ''}`}>
            <div className={`form-wrapper -edit`}>
                {
                    signaturesLoaded &&
                    <FormCreatorComponent
                        formColumns={formColumns}
                        formActions={formActions}
                        errorFieldsMessages={errorFieldsMessages}
                        errorMessages={errorMessages} />
                }

            </div>
        </div>
    )

}
