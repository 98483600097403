import LayoutComponent from "src/Components/Layout/Layout.component";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import { CampagneModificationCoordonneesService } from "src/Services/CampagneModificationCoordonneesService";
import React, { useState, useEffect } from "react";
import LoadingComponent from "src/Components/Loading/Loading.component";
import AccordionSimpleComponent from "../../Components/Accordions/AccordionSimple.component";


type ICampagneModificationVerificationExternalScreenProps = {
    ctoken: string,
}

export default function CampagneModificationVerificationExternal(props: ICampagneModificationVerificationExternalScreenProps) {
    const pageHeaderTitle: string = "Mise à jour des adresses de messagerie : Confirmation de la l'adresse de messagerie";
    const [loaded, setLoaded] = useState<boolean>(false);
    const [verificationMessage, setverificationMessage] = useState<string>("");
    const campagnesModificationsCoordonneeService: CampagneModificationCoordonneesService = new CampagneModificationCoordonneesService();

    useEffect(() => {
        if(!loaded) {
            getCampagneModification();
        }
    }, [loaded]);

    const getCampagneModification = (): void => {
        if(loaded) {
            return;
        }

        campagnesModificationsCoordonneeService.verification(props.ctoken).then((response) => {
            setLoaded(true);
            if(response.messages != null && response.messages.length > 0) {
                setverificationMessage(response.messages[0]);
                return;
            }
            setverificationMessage("Votre adresse de messagerie a bien été confirmée.");
        }).catch((error) => {
            setLoaded(true);
            if(error.messages != null && error.messages.length > 0) {
                setverificationMessage(error.messages[0]);
                return;
            }
            //setverificationMessage("Une erreur est survenue lors de la confirmation de votre adresse de messagerie. Ou votre adresse de messagerie a déjà été confirmée.");
            setverificationMessage("La mise à jour de votre adresse de messagerie a bien été prise en compte. La personne en charge des modifications peut retourner sur la plateforme pour vérifier la modification.");
        });
    }


    return (
        <LayoutComponent showMenu={false} wrapperModificators={"-full-screen -bg-primary"}
                         contentModificators={`-bg-white -no-flex`}>
                <PageHeaderComponent text={pageHeaderTitle} icon="icon-fil-infos" logo={true} modificators={`-in-fullscreen -border-full-screen`}/>
                {
                    !loaded &&
                    <LoadingComponent/>
                }
                {
                    loaded &&
                    <AccordionSimpleComponent
                        title="Explications"
                        modificators="-no-padding-left-right"
                        icon={"icon-informations"}
                        contentShowedOnInit={true}>
                        <div className="informations-text -fw-medium">
                            {verificationMessage}
                        </div>
                    </AccordionSimpleComponent>
                }
        </LayoutComponent>
    )
}