import AccordionSimpleComponent from "src/Components/Accordions/AccordionSimple.component";
import React from "react";
import {useState} from "react";
import {ICampagneModificationAbonne} from "src/Models/CampagneModificationAbonne.model";
import CampagneModificationAbonneForm
    from "src/Components/Forms/CampagneModificationAbonne/CampagneModificationAbonneForm";
import CampagneModificationAbonnesLibreForm
    from "src/Components/Forms/CampagneModificationAbonne/CampagneModificationAbonneLibreForm";
import {IApiCustomResponse} from "src/Services/Api.service";

type ICampagneModificationAbonnesFormContainerProps = {
    campagneModificationOfficeId: number,
    campagnesModificationsAbonnes: ICampagneModificationAbonne[],
    successSubmitForm: (response: IApiCustomResponse) => void
    modificators?: string,
}

//"Container"/Groupement de formulaires de campagne de modification d'abonnés
export default function CampagneModificationAbonnesFormContainer(props: ICampagneModificationAbonnesFormContainerProps) {

    const [loaded, setLoaded] = useState<boolean>(true);

    return (
        <>
            {
                loaded &&

                <div className="campagne-form-wrapper">
                    <AccordionSimpleComponent
                        modificators={props.modificators != null ? props.modificators : '-no-padding-left'}
                        title="Abonnement fil infos"
                        icon="icon-fil-infos"
                        contentShowedOnInit={true}>
                        <>
                            <p className="informations-text -fw-medium">
                                Lors de la mise à jour des coordonnées (office, notaires, comptabilité et négociateurs), les emails saisis seront automatiquement abonnés au fil d’infos.<br />
                                Vous avez la possibilité de désabonner les adresses saisies.<br />
                                Vous pouvez également abonner d’autres collaborateurs en saisissant leur adresse email ci-après.<br />
                            </p>
                            {
                                props.campagnesModificationsAbonnes.map((campagneModificationAbonne, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="informations-block">
                                                <p className="label">État</p>
                                                <p className="value -flex">
                                            <span
                                                className={`state-bullet -before-element icon-bullet -${
                                                    campagneModificationAbonne.etatValidation === 'a-valider-par-chambre' ? 'a-faire'
                                                        : campagneModificationAbonne.etatValidation === 'validee-par-chambre' ? 'realisee' : ''}`}>&nbsp;</span>
                                                    {campagneModificationAbonne.etatValidationLibelle}
                                                </p>
                                            </div>
                                            <div className="campagne-form-coordinate" key={index}>
                                                <CampagneModificationAbonneForm
                                                    campagneModificationAbonne={campagneModificationAbonne}
                                                    onSuccessSubmit={props.successSubmitForm}/>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                            <CampagneModificationAbonnesLibreForm
                                onSuccessSubmit={props.successSubmitForm}
                                officeId={props.campagneModificationOfficeId}/>
                        </>
                    </AccordionSimpleComponent>
                </div>
            }
        </>
    )
}