import FormCreatorComponent, {FormActions, FormColumn, FormElement} from "../../Forms/FormCreator.component";
import React, {useEffect, useState} from "react";
import {ICampagneModificationOffice} from "../../../Models/CampagneModificationOffice.model";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages} from "../../../Services/Api.service";
import {useHistory} from "react-router";
import {
    CampagnesModificationsOfficesService,
    ICampagneModificationOfficeGetApiResponse, ICampagneModificationOfficeVerificationFormData
} from "../../../Services/CampagnesModificationsOffices.service";
import {AppConstants} from "../../../Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import LoadingComponent from "../../Loading/Loading.component";
import AccordionSimpleComponent from "../../Accordions/AccordionSimple.component";

type ICampagneModificationOfficeAccesComponentProps = {
    ctoken: string,
    onDefineTitle: (title: string) => void,
    onSuccessAccess: (campagneModificationOffice: ICampagneModificationOffice) => void,
}

// Process :
// 0.a. On récupère la campagne de modification de l'office à partir du token (s'il n'est pas valide on affiche une erreur)
// 1. Si la campagne de modification de l'office est trouvée, deux possibilités :
// 1.a. Si le code d'accès est déjà en session (et correct), on va directement au formulaire de modification des coordonnées de l'office => composant source
// 1.b. On envoie un code d'accès par mail (au clic sur un bouton) et on passe à l'étape 2
// 2. On affiche le formulaire avec la demande du code d'accès (à 6 chiffres)
// 3. Si le code est valide, on passe au formulaire de modification des coordonnées de l'office (campagne de modification des coordonnées) => composant source
export default function CampagneModificationOfficeAccesComponent(props: ICampagneModificationOfficeAccesComponentProps) {
    const sessionCodeSlug: string = "campagneModificationOfficeCode";

    const [loaded, setLoaded] = useState<boolean>(false);
    const [campagneModificationOffice, setCampagneModificationOffice] = useState<ICampagneModificationOffice>(null);
    const [codeSent, setCodeSent] = useState<boolean>(null);
    const [codeChecked, setCodeChecked] = useState<boolean>(false);

    const [isSending, setIsSending] = useState<boolean>(false);
    const [formColumns, setFormColumns] = useState<FormColumn[]>([]);
    const [formActions, setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);

    const history = useHistory();

    const campagnesModificationsOfficesService: CampagnesModificationsOfficesService = new CampagnesModificationsOfficesService();

    //Récupération des informations de la campagne de modification de l'office (au chargement de la page)
    useEffect(() => {
        if (loaded) {
            return;
        }

        if (!props.ctoken) {
            history.push(AppConstants.path403);
        } else {
            setErrorsMessage(null);

            //On récupère les informations de la campagne de modification de l'office
            campagnesModificationsOfficesService.getCampagneModificationOfficeByToken(props.ctoken).then((response: ICampagneModificationOfficeGetApiResponse) => {
                if (response && response.datas) {
                    setCampagneModificationOffice(response.datas.campagneModificationOffice);

                    if (props.onDefineTitle) {
                        //On définit le titre de la page
                        props.onDefineTitle("Mise à jour des adresses de messagerie : " + response.datas.campagneModificationOffice.office.nom);
                    }

                    if (!loaded) {
                        setLoaded(true);
                    }
                }
            }, (error: IApiCustomResponse) => {
                setErrorsMessage(error.messages);

                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: error.messages,
                    type: "danger"
                });

                if (!loaded) {
                    setLoaded(true);
                }
            });
        }
    }, [props.ctoken]);

    //Une fois que la campagne de modification de l'office a été récupéré,
    // => Vérifier si le code d'accès est déjà stocké en session (si oui, on vérifie ce code directement pour éviter de le redemander)
    useEffect(() => {
        if (sessionStorage.getItem(sessionCodeSlug) != null) {
            onCheckCode({code: sessionStorage.getItem(sessionCodeSlug)});
        }
    }, [sessionStorage]);

    //Une fois que la campagne de modification de l'office a été récupéré,
    // => on charge le formulaire pour demander le code (si le code n'est pas en session et celui en session n'est plus correct)
    useEffect(() => {
        if (campagneModificationOffice == null || codeSent == null) {
            return;
        }

        //Préparation des colonnes du formulaire
        const formElementsColumn1: FormElement[] = [
            {
                type: 'text',
                fieldName: "code",
                label: "Code de vérification",
                maxLength: 6,
                required: true,
                modificators: "-on-white",
                oldValue: "",
            },
        ];

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);

        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Accéder",
                modificators: "-primary",
                hasLoading: isSending,
                onAction: (formData) => {
                    onCheckCode(formData as ICampagneModificationOfficeVerificationFormData);
                }
            },
            {
                label: "Annuler",
                modificators: "-is-link",
                onAction: () => {
                    onCancelCheckCode();
                }
            }
        ];
        setFormActions(currentFormActions);
    }, [campagneModificationOffice, codeSent]);

    //Action sur le bouton d'envoi du code d'accès
    const onSendCode = () => {
        setErrorsMessage(null);
        setErrorFieldsMessages(null);

        //On déclenche l'envoi du code d'accès
        campagnesModificationsOfficesService.sendCode(props.ctoken).then((response: ICampagneModificationOfficeGetApiResponse) => {
            if (response) {
                setCodeSent(true);
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: response.messages,
                    type: "success"
                });
            }
        }, (error: IApiCustomResponse) => {
            setErrorsMessage(error.messages);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: error.messages,
                type: "danger"
            });
            setCodeSent(false);
        });
    };

    //Vérifier le code pour accéder au formulaire de modification des coordonnées de l'office
    const onCheckCode = (formData: ICampagneModificationOfficeVerificationFormData): void => {
        //Valider le code d'accès (en front)
        if (formData.code == null || formData.code.length !== 6 || isNaN(parseInt(formData.code))) {
            setErrorsMessage(["Le code d'accès doit être renseigné et composé de 6 chiffres."]);
            return;
        }

        setIsSending(true);
        setErrorFieldsMessages(null);
        setErrorsMessage(null);

        campagnesModificationsOfficesService.checkCode(props.ctoken, formData).then((response: ICampagneModificationOfficeGetApiResponse) => {
            setIsSending(false);

            if (response != null) {
                setCodeChecked(true);

                //Stocker le code d'accès en session
                sessionStorage.setItem(sessionCodeSlug, formData.code);

                /*
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: 'Vous allez accéder au formulaire de modification des coordonnées de l\'office.',
                    type: "success"
                });
                 */
                //En cas de succès, on envoie la campagne de modification de l'office au composant/ecran qui a appelé ce composant
                props.onSuccessAccess(response.datas.campagneModificationOffice);
            }
        }).catch((error: IApiCustomResponse) => {
            setIsSending(false);
            setErrorFieldsMessages(error.fieldsMessages);
            setErrorsMessage(error.messages);

            //Retirer le code d'accès de la session
            sessionStorage.removeItem(sessionCodeSlug);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: error.messages,
                type: "danger"
            });
        });
    };


    //Action sur le bouton d'annulation de la vérification du code
    const onCancelCheckCode = (): void => {
        if (isSending) {
            return;
        }

        setCodeSent(null);
        setErrorFieldsMessages(null);
        setErrorsMessage(null);
    };

    return (
        <div>
            {!loaded &&
                <LoadingComponent />
            }

            {/* Message si la campagne de modification de l'office n'est pas trouvé */}
            {(campagneModificationOffice == null && loaded) &&
                <p className="information-text">
                    {errorMessages ? errorMessages : "La campagne de modification de l'office n'a pas été trouvée."}
                </p>
            }

            {/* Message/Bouton pour obtenir le code d'accès */}
            {(campagneModificationOffice != null && codeSent == null) &&
                <AccordionSimpleComponent
                    title="Récupération du code"
                    icon={"icon-informations"}
                    modificators="-no-padding-left-right -no-padding-top"
                    contentShowedOnInit={true}>
                    <div className="informations-text -fw-medium">
                        <p>
                            Pour accéder au formulaire de mise à jour des adresses de messagerie, vous devez renseigner un code d'accès.<br/>
                            Lorsque vous allez cliquer sur le bouton ci-dessous, un code d'accès va vous être envoyé par email à l'adresse email comptable de votre office.<br/>
                            <button className="g-button -primary -margin-top" onClick={() => onSendCode()}>
                                Envoyer le code
                            </button>
                        </p>
                    </div>
                </AccordionSimpleComponent>
            }

            {/*Formulaire pour vérifier le code */}
            {(!codeChecked && campagneModificationOffice != null && codeSent != null) &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={!isSending ? formActions : null}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages}/>
            }
        </div>
    );
}
