import React, {useEffect, useState} from "react";
import InputFieldComponent, {IFormField, InputFieldProps} from "./Input.field.component";
import LabelComponent from "./Label.component";
import {IApiErrorMessage} from "../../Services/Api.service";
import NumberExtendedFieldComponent from "./NumberExtended.field.component";
import {GeoApiService, GeoApiServiceResponse, IVille} from "../../Services/GeoApi.service";
import SelectFieldComponent, {IOption} from "./Select.field.component";
import {CommunesServices} from "../../Services/Communes.service";
import {ICommune} from "../../Models/Commune.model";

type FromToProps = IFormField & {
    onChange: (value: string) => void,
    oldValue: string
}

const CodePostalVille = (props: FromToProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [oldValue, setOldValue] = useState<string>(null);
    const [errors, setErrors] = useState<IApiErrorMessage>(null);
    const [codePostal, setCodePostal] = useState<string>("");
    const [communes, setCommunes] = useState<ICommune[]>([]);
    const communesService: CommunesServices = new CommunesServices();
    const [showNoResult, setShowNoResult] = useState<boolean>(false);

    useEffect(() => {
        setErrors(props.errors)
    }, [props.errors]);

    useEffect(() => {
        if (props.oldValue) {
            const parts = props.oldValue.split(" ");
            if (parts.length >= 3) {
                setCodePostal(parts[0]);
                onCpUpdate(parts[0], false);
            }

            setOldValue(props.oldValue.toUpperCase());
        }

        setLoaded(true);
    }, [props.oldValue]);

    const onCpUpdate = (value: string, triggerOnPropChange: boolean = true) => {
        //Quand on spécifie le code postal, déclencher le onChange
        //(pour que le formData soit mis à jour même si on modifie que le code postal => permettra de déclencher les vérifications (API))
        //(quand il y a déjà une valeur (oldValue correct), ne pas déclencher)
        if (communes.length === 0 && triggerOnPropChange) {
            props.onChange(value);
        }
        if (value.length < 5) {
            setCommunes([]);
            setShowNoResult(false);
            return;
        }
        setCodePostal(value);
        communesService.getCommunesForCodePostal({codePostal: value}).then((result) => {
            if (result.datas.communes && result.datas.communes.length > 0) {
                setCommunes(result.datas.communes);
            }
        });
    };

    const onSelectChange = (value: string) => {
        props.onChange(value);
    };

    const getCpVilleStr = (cp: string, ville: string) => {
        return `${cp} - ${ville}`;
    };

    const getOptions = (communes: ICommune[]) => {
        let options = [];
        for (let i = 0; i < communes.length; i++) {
            const value = getCpVilleStr(codePostal, communes[i].nom);
            options.push({value, label: communes[i].nom});
        }

        let uniqueOptions: any[] = [];
        options.forEach((item) => {
            if (!uniqueOptions.includes(item)) {
                uniqueOptions.push(item);
            }
        });

        return uniqueOptions;
    };

    return (
        <>
            {
                loaded &&
                <div className={`form-field ${props.modificators || ""}`}>
                    <LabelComponent fieldName={"cp"} label={"Code Postal"} isRequired={props.required}/>

                    <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                        <NumberExtendedFieldComponent modificators={`-full ${props.modificators || ""}`}
                                                      oldValue={codePostal} fieldType={"text"} onChange={onCpUpdate}
                                                      maxLength={5}/>
                    </div>
                </div>
            }

            {
                communes.length > 0 &&
                <div className={`form-field ${props.modificators || ""}`}>
                    <LabelComponent fieldName={"ville"} label={"Ville"} isRequired={props.required}/>
                    <div className={`input-wrapper ${errors ? '-error' : ''}`}>
                        <SelectFieldComponent oldValue={oldValue} modificators={`-full ${props.modificators || ""}`}
                                              hideSearch={true} onChange={onSelectChange}
                                              options={getOptions(communes)}/>
                    </div>
                </div>
            }
            {showNoResult && <LabelComponent fieldName={""} label={"Aucun résultat"}/>}
        </>
    )
};

export default CodePostalVille;
