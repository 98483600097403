import LayoutComponent from "src/Components/Layout/Layout.component";
import React, {useEffect, useState} from "react";
import {AppConstants} from "src/Constants/AppConstants";
import {useHistory} from "react-router";
import PageHeaderComponent from "src/Components/Page/PageHeader.component";
import {IBreadcrumbLink} from "src/Components/Breadcrumbs/Breadcrumbs.component";
import TabsContainerComponent, {TabPanelComponent} from "src/Components/Tabs/TabsContainer.component";
import {ICampagneModificationOffice} from "../../../Models/CampagneModificationOffice.model";
import useCampagneModificationOffice from "../../../Hooks/UseCampagneModificationOffice";
import CampagneModificationCoordonneesFormContainer
    from "../../../Components/CampagneModification/CampagneModificationCoordonnees/CampagneModificationCoordoneesFormContainer";
import {IApiCustomResponse} from "../../../Services/Api.service";
import LoadingComponent from "../../../Components/Loading/Loading.component";
import {Link} from "react-router-dom";
import AccordionSimpleComponent from "../../../Components/Accordions/AccordionSimple.component";
import AsideCampagnesModificationsOfficesComponent
    from "../../../Components/Aside/AsideCampagnesModificationsOffices.component";
import {
    CampagnesModificationsOfficesService,
    ICampagneModificationOfficeGetApiResponse
} from "../../../Services/CampagnesModificationsOffices.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import CampagneModificationAbonnesFormContainer
    from "src/Components/CampagneModification/CampagneModificationAbonnes/CampagneModificationAbonnesFormContainer";
import CampagneModificationNegociateursFormContainer
    from "../../../Components/CampagneModification/CampagneModificationNegociateurs/CampagneModificationNegociateursFormContainer";


type CampagneModificationOfficeFicheScreenProps = {
    cmoid: string
}


export default function CampagneModificationOfficeFicheScreen(props: CampagneModificationOfficeFicheScreenProps) {
    const [campagneModificationOffice, setCampagneModificationOffice] = useState<ICampagneModificationOffice>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [breadcrumbLinks, setBreadcrumbLinks] = useState<IBreadcrumbLink[]>(null);
    const history = useHistory();
    const [getCampagneModificationOfficeById, updateCampagneModificationOfficeEtatFinal] = useCampagneModificationOffice();

    useEffect(() => {
        if (!campagneModificationOffice) return;

        const breadcrumbLinks = [
            {text: "Annuaire", link: AppConstants.pathAnnuaire},
            {text: "Campagnes modifications", link: AppConstants.pathOffices},
            {
                text: `Campagne modification : ${campagneModificationOffice.campagneModification.nom}`,
                link: `/annuaire/campagnes-modifications/${campagneModificationOffice.campagneModificationId}`
            },
            {text: campagneModificationOffice.office.nom},
        ];

        setBreadcrumbLinks(breadcrumbLinks);
    }, [campagneModificationOffice, loaded]);


    useEffect(() => {
        if (!props.cmoid) {
            history.push(AppConstants.pathOffices);
        }

        getCampagneModificationOffice();
    }, [props.cmoid]);


    /**
     * Récupération de la campagne de modification de l'office selon le cmoid
     */
    const getCampagneModificationOffice = (): void => {
        getCampagneModificationOfficeById(props.cmoid, (campagneModificationOffice: ICampagneModificationOffice) => {
            setCampagneModificationOffice(campagneModificationOffice);

            if (!loaded) {
                setLoaded(true);
            }
        });
    };

    /**
     * Callback au succès du submit du formulaire
     */
    const onSuccessSubmitForm = (response: IApiCustomResponse): void => {
        getCampagneModificationOffice();
    };

    /**
     * Callback de validation de l'état final (présent dans l'"Aside")
     */
    const onValidationEtatFinalCallback = (): void => {
        setLoaded(false);

        updateCampagneModificationOfficeEtatFinal(props.cmoid, (campagneModificationOffice: ICampagneModificationOffice) => {
            setCampagneModificationOffice(campagneModificationOffice);

            setLoaded(true);
        }, () => {
            setLoaded(true);
        });
    };

    /**
     * Callback de remplacement des coordonnées liées à l'office (office + notaires)
     * Présent dans l'Aside
     */
    const onReplaceCoordonneesCallback = (): void => {
        setLoaded(false);

        const campagnesModificationsOfficesService: CampagnesModificationsOfficesService = new CampagnesModificationsOfficesService();

        campagnesModificationsOfficesService.replaceCoordonnees(props.cmoid).then((response: ICampagneModificationOfficeGetApiResponse) => {
            if (response && response.datas.campagneModificationOffice) {
                setCampagneModificationOffice(response.datas.campagneModificationOffice);
            }
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: response.messages[0],
                type: "success"
            });
            setLoaded(true);
        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: error.messages[0],
                type: "danger"
            });
            setLoaded(true);
        });
    }

    /**
     * Callback de revenir en arrière sur les coordonnées liées à l'office (office + notaires)
     * Présent dans l'Aside
     */
    const onRollbackCoordonneesCallback = (): void => {
        setLoaded(false);

        const campagnesModificationsOfficesService: CampagnesModificationsOfficesService = new CampagnesModificationsOfficesService();

        campagnesModificationsOfficesService.rollbackCoordonnees(props.cmoid).then((response: ICampagneModificationOfficeGetApiResponse) => {
            if (response && response.datas.campagneModificationOffice) {
                setCampagneModificationOffice(response.datas.campagneModificationOffice);
            }
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: response.messages[0],
                type: "success"
            });
            setLoaded(true);
        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: error.messages[0],
                type: "danger"
            });
            setLoaded(true);
        });
    }


    return (
        <>
            {!loaded &&
                <LoadingComponent/>
            }

            {
                loaded &&
                <LayoutComponent showMenu={true}>
                    <PageHeaderComponent
                        breadcrumbLinks={breadcrumbLinks}
                        text={`Campagne de modification de l'office : ${campagneModificationOffice.office.nom} (${campagneModificationOffice.office.CRPCEN})`}
                        icon="icon-coordonnees-adresses"
                        onSearch={null}/>

                    <TabsContainerComponent>
                        <TabPanelComponent label="Fiche" icon="icon-fiche">
                            <div className="fiche-content -right-gutter border-footer">

                                <AccordionSimpleComponent
                                    title="Informations"
                                    icon={"icon-informations"}
                                    contentShowedOnInit={true}>
                                    <div className="informations-wrapper">
                                        <div className="columns">
                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">Etat</p>
                                                    <p className="value">
                                                        {campagneModificationOffice.etatLibelle}
                                                    </p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Email (principal)</p>
                                                    <p className="value">
                                                        {campagneModificationOffice.emailPrincipal}
                                                    </p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Url pour l'office (envoyé par mail)</p>
                                                    <p className="value">
                                                        <Link
                                                            to={AppConstants.pathCampagneModificationOfficeExternal.replace(':ctoken', campagneModificationOffice.token)}>
                                                            {AppConstants.serverUrl.substring(0, AppConstants.serverUrl.length - 1) + AppConstants.pathCampagneModificationOfficeExternal.replace(':ctoken', campagneModificationOffice.token)}
                                                        </Link>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">Nombre coordonnées</p>
                                                    <div className="value">
                                                        <div>
                                                            Nombre de coordonnées (total = office + notaires)&nbsp;:&nbsp;
                                                            {campagneModificationOffice.nbCampagnesModificationsCoordonnees}
                                                        </div>
                                                        <div>
                                                            Nombre de coordonnées (office) :&nbsp;
                                                            {campagneModificationOffice.nbCampagnesModificationsCoordonneesOffice}
                                                        </div>
                                                        <div>
                                                            Nombre de coordonnées (notaires) :&nbsp;
                                                            {campagneModificationOffice.nbCampagnesModificationsCoordonneesNotaires}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Nombre coordonnées par état</p>
                                                    <div className="value">
                                                        {Object.keys(campagneModificationOffice.nbCampagnesModificationsCoordonneesParEtat || {}).map((key, index) =>
                                                            <div key={index}>
                                                                {key} : {campagneModificationOffice.nbCampagnesModificationsCoordonneesParEtat[key]}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Nombre de négociateurs</p>
                                                    <div className="value">
                                                        {campagneModificationOffice.nbCampagnesModificationsNegociateurs}
                                                    </div>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Nombre négociateurs par état</p>
                                                    <div className="value">
                                                        {Object.keys(campagneModificationOffice.nbCampagnesModificationsNegociateursParEtat || {}).map((key, index) =>
                                                            <div key={index}>
                                                                {key} : {campagneModificationOffice.nbCampagnesModificationsNegociateursParEtat[key]}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column">
                                                <div className="informations-block">
                                                    <p className="label">Nombre abonnés</p>
                                                    <p className="value">
                                                        {campagneModificationOffice.nbCampagnesModificationsAbonnes}
                                                    </p>
                                                </div>
                                                <div className="informations-block">
                                                    <p className="label">Nombre abonnés par état</p>
                                                    <div className="value">
                                                        {Object.keys(campagneModificationOffice.nbCampagnesModificationsAbonnesParEtatValidation || {}).map((key, index) =>
                                                            <div key={index}>
                                                                {key} : {campagneModificationOffice.nbCampagnesModificationsAbonnesParEtatValidation[key]}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="informations-text -fw-medium">
                                            <p>
                                                Voici la liste des différents états :<br/>
                                            </p>
                                            <ul>
                                                <li>
                                                    <strong>Initialisée</strong> :
                                                    Les données de l'office ont été récupérées et "figées".
                                                </li>
                                                <li>
                                                    <strong>Envoyée à l'office (aucune modification)</strong> :
                                                    Un email a été envoyé à l'office pour qu'il accède à l'interface de
                                                    modification.
                                                </li>
                                                <li>
                                                    <strong>Traitement partiel des coordonnées (reste des À traiter)</strong> :
                                                    Au moins une coordonnée a été traitée et il reste des coordonnées à traiter.
                                                </li>
                                                <li>
                                                    <strong>Traitement partiel des coordonnées (reste des Confirmations)</strong> :
                                                    Toutes les coordonnées ont été traitées mais il manque des Confirmations.
                                                </li>
                                                <li>
                                                    <strong>À valider par la Chambre</strong> :
                                                    Les coordonnées ont été modifiées et doivent être validées par la Chambre.
                                                </li>
                                                <li>
                                                    <strong>Validée par la Chambre</strong> :
                                                    Les données ont été validées par la Chambre.
                                                </li>
                                                <li>
                                                    <strong>Coordonnées remplacées pour l'office</strong> :
                                                    Toutes les données de l'office ont été remplacées.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </AccordionSimpleComponent>

                                <CampagneModificationCoordonneesFormContainer
                                    modificators=""
                                    campagnesModificationsCoordonnees={campagneModificationOffice.campagnesModificationsCoordonnees}
                                    successSubmitForm={onSuccessSubmitForm}/>

                                <CampagneModificationNegociateursFormContainer
                                    modificators=""
                                    campagnesModificationsNegociateurs={campagneModificationOffice.campagnesModificationsNegociateurs}
                                    successSubmitForm={onSuccessSubmitForm} />

                                <CampagneModificationAbonnesFormContainer
                                    modificators="-padding-left"
                                    campagnesModificationsAbonnes={campagneModificationOffice.campagnesModificationsAbonnes}
                                    campagneModificationOfficeId={campagneModificationOffice.id}
                                    successSubmitForm={onSuccessSubmitForm}/>
                            </div>

                            <AsideCampagnesModificationsOfficesComponent
                                type="fiche"
                                campagneModificationOffice={campagneModificationOffice}
                                validationEtatFinalCallback={onValidationEtatFinalCallback}
                                replaceCoordonneesCallback={onReplaceCoordonneesCallback}
                                rollbackCoordonneesCallback={onRollbackCoordonneesCallback}
                            />
                        </TabPanelComponent>
                    </TabsContainerComponent>

                </LayoutComponent>
            }
        </>
    )
}
