import {IApiCustomResponse} from "src/Services/Api.service";
import {AppConstants} from "src/Constants/AppConstants";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "src/Shared/config";
import {useHistory} from "react-router";
import {
    CampagnesModificationsService,
    ICampagneModificationGetApiResponse
} from "../Services/CampagnesModifications.service";
import {ICampagneModification} from "../Models/CampagneModification.model";

export default function useCampagneModification(){
    const campagnesModificationsService: CampagnesModificationsService = new CampagnesModificationsService();
    const history = useHistory();

    /**
     * Récupération d'une campagne de modification à partir de son id
     *
     * @param {string} cmid
     * @param {(campagneModification: ICampagneModification) => void} callback
     * @param {() => void} callbackError
     */
    const getCampagneModificationById = (cmid: string, callback:(campagneModification: ICampagneModification) => void, callbackError?: () => void ) => {
        campagnesModificationsService.getCampagneModification(cmid).then((response: ICampagneModificationGetApiResponse) => {
            if( response && response.datas.campagneModification){
                if(callback){
                    callback(response.datas.campagneModification);
                }
            }
        },(error: IApiCustomResponse) => {
            if(callbackError){
                callbackError();
            }
            history.push(AppConstants.pathOffices);

            notificationSystem.addNotification({...defaultNotificationConfig, message: "Une erreur est survenue lors de la récupération de la campagne de modification",type: "danger"});
        });
    };

    return [getCampagneModificationById];
};

