import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {IFormationParticipant} from "../../../Models/FormationParticipant.model";
import DateHelper from "../../../Helpers/Date.helper";
import ConfirmationComponent from "../../Confirmation/Confirmation.component";
import SwitchComponent from "../../Switch/Switch.component";
import {FormationsService} from "../../../Services/Formations.service";
import {IApiCustomResponse} from "../../../Services/Api.service";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationConfig} from "../../../Shared/config";
import {IFormation} from "../../../Models/Formation.model";
import CheckboxFieldComponent from "../../Fields/Checkbox.field.component";
import {useHistory} from "react-router";
import {AppConstants} from "../../../Constants/AppConstants";
import TableHeaderButtonComponent from "../../Lists/TableHeaderButton.component";

const FormationParticipantsList = (props: {
    nbMax: number,
    formation: IFormation,
    countParticipants: number,
    participants: IFormationParticipant[],
    state: string,
    onSelectedParticipantsChange: (participantsIds: string[]) => void,
    onFormationChange: (formation: IFormation) => void,
}, ref:any) => {

    const formationsService: FormationsService = new FormationsService();
    const [showConfirmationAnnulation, setShowConfirmationAnnulation] = useState<boolean>(false);
    const [showConfirmationDelete, setShowConfirmationDelete] = useState<boolean>(false);
    const [showConfirmationValidation, setShowConfirmationValidation] = useState<boolean>(false);
    const [participants, setParticipants] = useState<IFormationParticipant[]>([]);
    const [participantSelected, setParticipantSelected] = useState<IFormationParticipant>(null);
    const [participantToDelete, setParticipantToDelete] = useState<IFormationParticipant>(null);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [orderType, setOrderType] = useState<string>(AppConstants.orderType.asc);
    const [orderBy, setOrderBy] = useState<string>("identite");
    const history = useHistory();



    useEffect(() => {
        updateParticipants(props.participants);
    }, [props.participants]);

    useEffect(() => {
        props.onSelectedParticipantsChange(selectedValues);
    }, [selectedValues]);



    useEffect( () => {
        let participantsOrdered = props.participants.sort((p1: IFormationParticipant, p2: IFormationParticipant) => {
            let valueToCompare: string = "";
            let valueToCompare2: string = "";

            switch(orderBy){
                case "email":
                    valueToCompare = getEmail(p1);
                    valueToCompare2 = getEmail(p2);
                    break;

                case "identite":
                    valueToCompare = getIdentite(p1);
                    valueToCompare2 = getIdentite(p2);
                    break;

                case "dateInscription":
                    valueToCompare = p1.date;
                    valueToCompare2 = p2.date;
                    break;

                case "dateEnvoiDocuments":
                    valueToCompare = p1.dateEnvoiDocuments;
                    valueToCompare2 = p2.dateEnvoiDocuments;
                    break;

                case "dateEnvoiQuestionnaires":
                    valueToCompare = p1.dateEnvoiQuestionnaire;
                    valueToCompare2 = p2.dateEnvoiQuestionnaire;
                    break;

                case "dateEnvoiConfirmation":
                    valueToCompare = p1.dateEnvoiConfirmation;
                    valueToCompare2 = p2.dateEnvoiConfirmation;
                    break;
            }

            if (valueToCompare > valueToCompare2) {
                return 1;
            }
            if (valueToCompare < valueToCompare2) {
                return -1;
            }

            return 0;
        });

        if( orderType == AppConstants.orderType.desc){
            participantsOrdered = participantsOrdered.reverse();
        }


        updateParticipants(participantsOrdered);

    }, [orderBy, orderType]);

    /**
     * On rend la fonction de l'enfant accessible au parent
     */
    useImperativeHandle(ref, () => ({
        resetSelectedParticipants: resetSelectedParticipants,
    }));


    /**
     * Permet le reset du nombre de participants
     */
    const resetSelectedParticipants = (): void => {
        setSelectedValues([]);
        setAllSelected(false);
    }



    /**
     * Utilisation de la coche globale
     */
    const onSelectAll = (): void => {
        const nextAllSelected = !allSelected;
        let newSelectedValues = [...selectedValues];
        participants.forEach((opt)=>{
            const id = opt.id.toString();
            if(
                (nextAllSelected && !isValueSelected(id)) || // Si tout doit être sélectionné et que la valeur n'est pas déjà sélectionnée
                (!nextAllSelected && isValueSelected(id))  // Si tout doit être désélectionné et que la valeur est déjà sélectionnée
            ){
                newSelectedValues = toggleArrayItem(newSelectedValues,id);
            }
        });
        setSelectedValues(newSelectedValues);
        setAllSelected(nextAllSelected);
    };

    /**
     * Prise en charge d'un changement au niveau d'une checkbox
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newSelectedValues = toggleArrayItem(selectedValues,e.currentTarget.value.toString());
        setSelectedValues(newSelectedValues);

        // Changement manuel, on met à jour la coche globale
        setAllSelected(newSelectedValues.length === participants.length);
    };

    /**
     * Supprime un élément dans le tableau s'il est déjà présent ou l' ajoute s'il ne l'est pas
     * @param {string[]} arr
     * @param {string} item
     * @returns {string[]}
     */
    const toggleArrayItem = (arr: string[], item: string): string[] => arr.includes(item) ? arr.filter(i => i !== item) : [ ...arr, item ];

    /**
     * Retourne un booleen indiquant si la valeur passée en paramètre est dans les valeurs sélectionnées
     * @param {string} value
     * @returns {boolean}
     */
    const isValueSelected = (value: string): boolean => {
        return selectedValues.includes(value);
    };


    /**
     * Met à jour les participants
     * @param {IFormationParticipant[]} participants
     */
    const updateParticipants = (participants: IFormationParticipant[]) => {
        setParticipants(participants.filter((p) => p.etat === props.state));
    };

    /**
     * Retourne le mail du participant
     * @param {IFormationParticipant} participant
     * @returns {string}
     */
    const getEmail = (participant: IFormationParticipant) => {
        return participant.emailDestinataire.trim();
    };

    /**
     * Retourne l'identité du participant
     * @param {IFormationParticipant} participant
     * @returns {string}
     */
    const getIdentite = (participant: IFormationParticipant) => {
        let name = "";

        if (participant.notaire && participant.notaire.coordonnees) {
            name = `${participant.notaire.nom} ${participant.notaire.prenom}`;
        }

        if (participant.personneExterieure && participant.personneExterieure.coordonnees) {
            name = `${participant.personneExterieure.nom} ${participant.personneExterieure.prenom}`;
        }

        if (participant.email) {
            name = `${participant.nom} ${participant.prenom}`;
        }

        return name;
    };

    /**
     * Prend en charge un changement d'état
     * @param {string} state
     */
    const onValidationStateChange = (state: string) => {
        formationsService.updateParticipant(participantSelected.id.toString(), {etat: state}).then((result) => {
            setParticipantSelected(null);
            setParticipantToDelete(null);
            setShowConfirmationAnnulation(false);
            setShowConfirmationValidation(false);
            setShowConfirmationDelete(false);
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "L'inscription a bien été modifiée",
                type: "success"
            });

            if (result.datas && result.datas.formation) {
                props.onFormationChange(result.datas.formation);
            }
        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la mise à jour du participant.",
                type: "danger"
            });
        });
    };

    /**
     * Annule la demande d'annulation
     */
    const onCancelAnnulation = () => {
        setParticipantSelected(null);
        setShowConfirmationAnnulation(false);
    };

    /**
     * Annule la demande de suppression
     */
    const onCancelDelete = () => {
        setParticipantToDelete(null);
        setShowConfirmationDelete(false);
    };

    /**
     * Annule la demande de validation
     */
    const onCancelValidation = () => {
        setParticipantSelected(null);
        setShowConfirmationValidation(false);
    };

    /**
     * Change la présence d'un participant
     * @param {IFormationParticipant} participant
     * @param {boolean} isPresent
     */
    const onChangePresence = (participant: IFormationParticipant, isPresent: boolean) => {
        formationsService.updateParticipant(participant.id.toString(), {presence: isPresent ? 'oui' : 'non'}).then(() => {setParticipantSelected(null);
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "La présence du participant a bien été modifiée",
                type: "success"
            });
        }, (error: IApiCustomResponse) => {
            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la mise à jour du participant.",
                type: "danger"
            });
        });
    };


    const onValidatationDelete = () => {
        if(!participantToDelete) return;

        formationsService.deleteParticipant(participantToDelete.id.toString()).then((result) => {
            setParticipantToDelete(null);
            setShowConfirmationDelete(false);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Le participant a bien été supprimé",
                type: "success"
            });

            if (result.datas && result.datas.formation) {
                props.onFormationChange(result.datas.formation);
            }
        }).catch((error: IApiCustomResponse) => {
            setParticipantToDelete(null);
            setShowConfirmationDelete(false);

            notificationSystem.addNotification({
                ...defaultNotificationConfig,
                message: "Une erreur est survenue lors de la suppression du participant.",
                type: "danger"
            });
        });
    }



    /**
     * Indique si le nombre max de personnes inscrites a été atteint
     *
     * @returns {boolean}
     */
    const nbParticipantsReached = (): boolean => {
        return props.countParticipants >= props.nbMax;
    };


    const headers: {slug: string, label: string, states: string[], canOrder?: boolean}[] = [
        {slug: "presence", label: "Présence", states: ["inscrit"]},
        {slug: "identite", label: "Identité", states: [], canOrder: true,},
        {slug: "email", label: "Email", states: [], canOrder: true,},
        {slug: "dateInscription", label: "Date d'inscription", states: [], canOrder: true },
        {slug: "dateEnvoiAttestation", label: "Date envoi attestation", states: ["inscrit"], canOrder: true },
        {slug: "dateEnvoiFacture", label: "Date envoi facture", states: ["inscrit"], canOrder: true },
        {slug: "dateEnvoiDocuments", label: "Date d'envoi documents", states: [], canOrder: true },
        {slug: "dateEnvoiQuestionnaires", label: "Date d'envoi questionnaire", states: [], canOrder: true },
        {slug: "dateEnvoiConfirmation", label: "Date d'envoi confirmation", states: [], canOrder: true },
        {slug: "covoiturage", label: "Covoiturage", states: []},
        {slug: "valider", label: "Action", states: ["enAttente", "annulation"]},
        {slug: "annulation", label: "Annulation", states: ["inscrit", "enAttente"]},
    ];


    /**
     * Changement des informations de tri
     *
     * @param {string} value
     */
    const setOrder = (value: string): void => {
        if( orderBy == value){
            setOrderType( orderType === AppConstants.orderType.asc ? AppConstants.orderType.desc : AppConstants.orderType.asc);
        }
        else{
            setOrderBy(value);
            setOrderType(AppConstants.orderType.asc);
        }
    }



    return <>
        <table className={`list-table`}>
            <thead className={"headers"}>
                <tr>
                    {
                        props.state === "inscrit" &&
                        <th className={"th  -center"}>
                            <CheckboxFieldComponent value={""} id={""} onChange={()=>onSelectAll()} isChecked={allSelected}/>
                        </th>
                    }
                    {
                        headers.map((item, iIndex) =>
                            <React.Fragment key={iIndex}>
                                {
                                    (item.states.length === 0 || item.states.includes(props.state)) &&
                                        <th className="th">

                                            {
                                                item.canOrder &&
                                                <TableHeaderButtonComponent
                                                    direction={orderType as string || AppConstants.orderType.asc}
                                                    value={item.slug} selectedValue={orderBy as string || ""}
                                                    label={item.label} click={(value: string) => setOrder(value)}/>
                                            }
                                            {
                                                !item.canOrder &&
                                                <span className="link">
                                                    <span className="text">{item.label}</span>
                                                </span>
                                            }
                                        </th>
                                }
                            </React.Fragment>
                        )
                    }
                </tr>
            </thead>

            <tbody>
                {
                    participants && participants.map((participant: IFormationParticipant, index: number) =>
                        <tr key={index} className="tr -no-hover">
                            {
                                props.state === "inscrit" &&
                                    <>
                                        <td className="td -center" width="100">
                                            <CheckboxFieldComponent value={participant.id.toString()} id={participant.id.toString()} label={""} onChange={onCheckboxChange} isChecked={isValueSelected(participant.id.toString())}/>
                                        </td>
                                        <td className="td -center" width="100">
                                            <SwitchComponent
                                                onClick={(isPresent: boolean) => onChangePresence(participant, isPresent)}
                                                isActive={participant.presence === "oui"}/>
                                        </td>
                                    </>
                            }

                            <td className="td">
                                {getIdentite(participant)}
                            </td>

                            <td className="td">
                                {getEmail(participant) || '-'}
                            </td>


                            <td className="td" width="140">
                                {DateHelper.getFormatedDate(participant.date)}
                            </td>

                            {
                                props.state === "inscrit" &&
                                    <>
                                        <td className="td" width="140">
                                            {participant.dateEnvoiAttestation ? DateHelper.getFormatedDate(participant.dateEnvoiAttestation) : ""}
                                        </td>
                                        <td className="td" width="140">
                                            {participant.dateEnvoiFacture ? DateHelper.getFormatedDate(participant.dateEnvoiFacture) : ""}
                                        </td>
                                    </>
                            }


                            <td className="td" width="180">
                                {participant.dateEnvoiDocuments ? DateHelper.getFormatedDate(participant.dateEnvoiDocuments) : '-'}
                            </td>
                            <td className="td" width="180">
                                {participant.dateEnvoiQuestionnaire ? DateHelper.getFormatedDate(participant.dateEnvoiQuestionnaire) : '-'}
                            </td>
                            <td className="td" width="180">
                                {participant.dateEnvoiConfirmation ? DateHelper.getFormatedDate(participant.dateEnvoiConfirmation) : '-'}
                            </td>


                            <td className="td" width="100">{participant.coVoiturage || '-'}</td>
                            {
                                (props.state === "enAttente" || (props.state === "annulation" && !nbParticipantsReached())) &&
                                    <td className="td -w130px">
                                        <button className={`g-button -check-button -with-icon ${nbParticipantsReached() ? '-disabled' : '' }`} onClick={() => {
                                            setParticipantSelected(participant);
                                            setShowConfirmationValidation(true)
                                        }}>
                                            <em className="picto icon-check "/>
                                            <span className="text">Inscrire</span>
                                        </button>

                                        {
                                            !participant.personneExterieure && !participant.notaire && participant.email &&
                                            <>
                                                <button className={`g-button -edit-button -with-icon `} onClick={() => {
                                                    history.push(AppConstants.pathFormations + '/'+ props.formation.id + '/participants/' + participant.id + '/edit-email');
                                                }}>
                                                    <em className="picto icon-edit"/>
                                                    <span className="text">Modifier</span>
                                                </button>

                                                <button className={`g-button -delete-button -with-icon`} onClick={() => {
                                                    setParticipantToDelete(participant);
                                                    setShowConfirmationDelete(true)
                                                }}>
                                                    <em className="picto icon-remove"/>
                                                    <span className="text">Supprimer</span>
                                                </button>
                                            </>
                                        }
                                        {
                                            (participant.personneExterieure || participant.notaire) &&
                                            <>
                                                <button className={`g-button -delete-button -with-icon`} onClick={() => {
                                                    setParticipantToDelete(participant);
                                                    setShowConfirmationDelete(true)
                                                }}>
                                                    <em className="picto icon-remove"/>
                                                    <span className="text">Supprimer</span>
                                                </button>
                                            </>

                                        }
                                    </td>
                            }
                            {
                                (props.state === "annulation" && nbParticipantsReached() ) &&
                                <td className="td -w130px">
                                    <button className={`g-button`} onClick={() => {
                                        setParticipantSelected(participant);
                                        setShowConfirmationValidation(true)
                                    }}>
                                        <em className=" -check-button icon-check"/>
                                        Inscrire
                                    </button>

                                    {
                                        !participant.personneExterieure && !participant.notaire && participant.email &&
                                        <>
                                            <button className={`g-button -edit-button -with-icon`} onClick={() => {
                                                history.push(AppConstants.pathFormations + '/'+ props.formation.id + '/participants/' + participant.id + '/edit-email');
                                            }}>
                                                <em className="picto icon-edit"/>
                                                <span className="text">Modifier</span>
                                            </button>

                                            <button className={`g-button -delete-button -with-icon`} onClick={() => {
                                                setParticipantToDelete(participant);
                                                setShowConfirmationDelete(true)
                                            }}>
                                                <em className="picto icon-remove"/>
                                                <span className="text">Supprimer</span>
                                            </button>
                                        </>

                                    }

                                    {
                                        (participant.personneExterieure || participant.notaire) &&
                                        <>
                                            <button className={`g-button -delete-button -with-icon`} onClick={() => {
                                                setParticipantToDelete(participant);
                                                setShowConfirmationDelete(true)
                                            }}>
                                                <em className="picto icon-remove"/>
                                                <span className="text">Supprimer</span>
                                            </button>
                                        </>

                                    }
                                </td>
                            }

                            {
                                props.state !== "annulation" &&
                                <td className="td -w85px">
                                    <button className="g-button -delete-button -with-icon" onClick={() => {
                                        setParticipantSelected(participant);
                                        setShowConfirmationAnnulation(true)
                                        }}
                                    >
                                        <em className="picto icon-remove"/>
                                        <span className="text">Annuler</span>
                                    </button>

                                    {
                                        !participant.personneExterieure && !participant.notaire && participant.email &&
                                        <button className={`g-button -edit-button -with-icon`} onClick={() => {
                                            history.push(AppConstants.pathFormations + '/'+ props.formation.id + '/participants/' + participant.id + '/edit-email');
                                        }}>
                                            <em className="picto icon-edit"/>
                                            <span className="text">Modifier</span>
                                        </button>
                                    }

                                </td>
                            }
                        </tr>
                    )
                }
            </tbody>
        </table>

        {showConfirmationAnnulation && <ConfirmationComponent onConfirm={()=>onValidationStateChange("annulation")} onCancel={onCancelAnnulation} text="Souhaitez-vous vraiment annuler cette inscription ?"/>}
        {showConfirmationDelete && <ConfirmationComponent onConfirm={()=>onValidatationDelete()} onCancel={onCancelDelete} text="Souhaitez-vous vraiment supprimer cette inscription ?"/>}
        {showConfirmationValidation && <ConfirmationComponent onConfirm={()=>onValidationStateChange(nbParticipantsReached() ? 'enAttente' : "inscrit")} onCancel={onCancelValidation} text="Souhaitez-vous vraiment valider cette inscription ?"/>}
    </>
};
export default forwardRef(FormationParticipantsList);
