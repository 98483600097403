import React, {useEffect, useState} from "react";
import FormCreatorComponent, {FormActions, FormColumn, FormComponentFormData, FormElement} from "src/Components/Forms/FormCreator.component";
import {IApiCustomResponse, IApiErrorMessage, IApiFieldsErrorMessages, IFileDownloadApiResponse} from "src/Services/Api.service";
import CommonHelper from "src/Helpers/Common.helper";
import {ICourrierType} from "src/Models/Courrier.model";
import {CourriersService, ICourriersTypesGetApiResponse} from "src/Services/Courriers.service";
import CourriersHelper from "src/Helpers/Courriers.helper";
import {Store as notificationSystem} from "react-notifications-component";
import {defaultNotificationClickToCloseConfig, defaultNotificationConfig} from "src/Shared/config";
import UseFileDownload from "src/Hooks/UseFileDownload";
import {
    IInspectionCourrierFormData,
    IInspectionsListForCourrierApiResponse,
    InspectionsService
} from "src/Services/Inspections.service";
import {IInspection} from "src/Models/Inspection.model";
import InspectionsHelper from "src/Helpers/Inspections.helper";
import LoaderComponent from "../../../Loading/Loader.component";

type IInspectionListCourrierFormProps = {
    onSuccessSubmit: (response: IApiCustomResponse) => void,
}

export default function InspectionListCourrierForm(props: IInspectionListCourrierFormProps){
    const [isLoaded,setIsLoaded] = useState<boolean>(false);
    const [formColumns,setFormColumns] = useState<FormColumn[]>([]);
    const [formActions,setFormActions] = useState<FormActions[]>([]);
    const [errorMessages, setErrorsMessage] = useState<IApiErrorMessage>(null);
    const [errorFieldsMessages, setErrorFieldsMessages] = useState<IApiFieldsErrorMessages>(null);
    const [courriersTypes, setCourriersTypes] = useState<ICourrierType[]>([]);
    const [wantedYears, setWantedYears] = useState<number[]>(null);
    const [activePreviousYear, setActivePreviousYear] = useState<IInspection[]>(null);
    const [activeCurrentYear, setActiveCurrentYear] = useState<IInspection[]>(null);
    const inspectionService: InspectionsService = new InspectionsService();
    const {handleFileDownload} = UseFileDownload();


    useEffect(() => {
        const courriersService: CourriersService = new CourriersService();
        courriersService.getCourriersTypesForModule('inspections').then((response: ICourriersTypesGetApiResponse) => {
            if(response && response.datas && response.datas.types){
                // On enléve le courrier de réclamations car seulement accessible dans une office
                const filteredCourriersTypes = response.datas.types.filter(type => type.slug !== 'inspections-etat-des-reclamations');
                setCourriersTypes(filteredCourriersTypes);
            }
        });

        inspectionService.getInspectionsListForCourriers().then((response:IInspectionsListForCourrierApiResponse) => {
            if(response && response.datas){
                setWantedYears( response.datas.wantedYears || []);
                setActivePreviousYear( response.datas.activePreviousYear ?? []);
                setActiveCurrentYear( response.datas.activeCurrentYear ?? []);
                setIsLoaded(true);
            }
        });
    }, [])


    useEffect(()=> {
        if(!courriersTypes || !courriersTypes.length || !wantedYears || !wantedYears.length ) return;

        const formElementsColumn1: FormElement[] = [
            {
                type: 'select',
                fieldName: "annee",

                label: "Année",
                placeholder: "Année",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                options: wantedYears ? CourriersHelper.formatYearsForOptions(wantedYears) : []
            },
    ];

        if(activePreviousYear && wantedYears.length > 1){
            formElementsColumn1.push(
                {
                    type: 'selectMultiple',
                    fieldName: "inspections",

                    label: "Office",
                    placeholder: "Office",
                    modificators: "-on-white",
                    required: true,
                    hideSearchOnMultiple: false,
                    oldValue: "",
                    optionsGroup: activePreviousYear ? InspectionsHelper.formatListForOptionsGroup(activePreviousYear) : [],
                    condition: {
                        fieldName: "annee",
                        value: wantedYears[1].toString()
                    }
                },
            );
        }

        formElementsColumn1.push(
            {
                type: 'selectMultiple',
                fieldName: "inspections",

                label: "Office",
                placeholder: "Office",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                optionsGroup: activeCurrentYear ? InspectionsHelper.formatListForOptionsGroup(activeCurrentYear) : [],
                condition: {
                    fieldName: "annee",
                    value: wantedYears[0].toString()
                }
            },
            {
                type: 'select',
                fieldName: "type",

                label: "Type de courrier",
                placeholder: "Type de courrier",
                modificators: "-on-white",
                required: false,
                hideSearchOnMultiple: true,
                oldValue: "",
                options: courriersTypes ? CourriersHelper.formatCourriersTypesForOptions(courriersTypes) : []
            },
        );


        for(let type of courriersTypes){
            formElementsColumn1.push(
                {
                    type: 'wysiwyg',
                    fieldName: "contenu",

                    label: "Contenu",
                    placeholder: "Contenu",
                    required: false,
                    modificators: "-wysiwyg -on-white",

                    oldValue: type.modele || "",
                    condition: {
                        fieldName: "type",
                        value: type.id.toString()
                    }
                }
            );
        }

        formElementsColumn1.push(
            {
                type: 'radio',
                fieldName: "afficherEntete",

                label: "Afficher l'entête",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            },
            {
                type: 'radio',
                fieldName: "sauvegarderCourrier",

                label: "Sauvegarder le courrier",
                required: true,
                modificators: "-on-white",
                oldValue: "non",
                options: CommonHelper.generateOuiNonRadiosOptions('non'),
            }
        );

        const currentFormColumns: FormColumn[] = [
            {
                modificators: "-full",
                elements: formElementsColumn1
            },
        ];
        setFormColumns(currentFormColumns);



        //Préparation des actions du formulaires
        let currentFormActions: FormActions[] = [
            {
                label: "Télécharger",
                modificators: "-primary",
                icon: "icon-download",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onDownload(formData, onActionEnded)}
            },
            {
                label: "Envoyer",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, false, onActionEnded)}
            },
            {
                label: "Envoyer un test",
                modificators: "-primary",
                icon: "icon-paper-plane",
                hasLoading: true,
                onAction: (formData, onActionEnded) => {onSend(formData, true, onActionEnded)}
            }
        ];
        setFormActions(currentFormActions);

    }, [courriersTypes, wantedYears, activePreviousYear, activeCurrentYear]);


    /**
     * Gestion du téléchargement du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {() => void} onActionEnded
     */
    const onDownload = (formData: FormComponentFormData, onActionEnded?: ()=>void): void => {
        inspectionService.downloadCourrierFromList(formData as IInspectionCourrierFormData).then((response: IFileDownloadApiResponse) => {
            if(onActionEnded) onActionEnded();
            if(response && response.datas && response.datas.file){
                handleFileDownload(response.datas.file,true);
            }
        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationConfig,
                    message: "Une erreur est survenue lors de l'enregistrement du courrier.",
                    type: "danger"
                });
            }
        });
    };


    /**
     * Gestion de l'envoi du courrier
     *
     * @param {FormComponentFormData} formData
     * @param {boolean} isDebug
     */
    const onSend = (formData: FormComponentFormData, isDebug?: boolean, onActionEnded?: ()=>void): void => {
        const formDataUpdated: IInspectionCourrierFormData = {
            ...formData as IInspectionCourrierFormData,
            isDebug: isDebug || false
        }

        inspectionService.sendCourrierFromLlist(formDataUpdated ).then((response: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( props.onSuccessSubmit){
                props.onSuccessSubmit(response);
            }

            notificationSystem.addNotification({
                ...defaultNotificationClickToCloseConfig,
                message: "Le courrier a bien été envoyé.",
                type: "success"
            });
        }, (error: IApiCustomResponse) => {
            if(onActionEnded) onActionEnded();
            if( error.messages ){
                setErrorsMessage(error.messages);
            }
            if( error.fieldsMessages ){
                setErrorFieldsMessages(error.fieldsMessages);
            }

            if( !error.messages && !error.messages) {
                notificationSystem.addNotification({
                    ...defaultNotificationClickToCloseConfig,
                    message: "Une erreur est survenue lors de l'envoi du courrier.",
                    type: "danger"
                });
            }
        });
    }


    return (
        <div className="form-wrapper -edit">
            {
                !isLoaded &&
                <LoaderComponent />
            }
            {
                isLoaded &&
                <FormCreatorComponent
                    formColumns={formColumns}
                    formActions={formActions}
                    errorFieldsMessages={errorFieldsMessages}
                    errorMessages={errorMessages} />
            }

        </div>
    )

}
