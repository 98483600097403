import {IOption} from "src/Components/Fields/Select.field.component";
import {ICreationSteps} from "./AppConstants";

export type ICampagneModificationCoordonneesConstants = {
    choixAffichageDomaineEmailOptions: IOption[],
}
export const CampagneModificationCoordonneesConstants: ICampagneModificationCoordonneesConstants = {
    //En cas de changement, il faut aussi modifier le fichier server/config/campagnesModificationsCoordonnees.php
    choixAffichageDomaineEmailOptions: [
        {value: 'sous-domaine-personnalise', label: 'Affichage du sous-domaine personnalisé (@denomination-office.notaires.fr)'},
        {value: 'reecriture-format-notaires-fr', label: 'Réécriture sous le format @notaires.fr'},
    ],
};
